import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StepperDialogComponent } from 'src/app/components/stepper-dialog/stepper-dialog.component';
import { Item } from 'src/app/models/SelectItems';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-distributor-list',
  templateUrl: './distributor-list.component.html',
  styleUrls: ['./distributor-list.component.css']
})
export class DistributorListComponent {
  @ViewChild(StepperDialogComponent) stepperDialogComponent!: StepperDialogComponent;
  graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];
  canView:boolean = false;
  canEdit:boolean = false;
  canDelete:boolean = false;
  canCreate:boolean = false;
  viewUrl:string="";
  editUrl:string="";
  createUrl:string="";
  deleteUrl:string="";
  addBeneficiary() {
    this.stepperDialogComponent.show = true;
  }

  constructor(private adminService: AdminService, private router:Router,private menuService:MenuService,private generalService: GeneralService) {
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {      
      this.getPermissions(parseInt(obj['id'])??0);
    }
     this.getDistributorDetails();
  }

async getDistributorDetails() {
    const res = await this.adminService.DistributorDetails1();    
    if (res != null && res.result) {
      this.graphdata = res.result.data;
      let index=3;
      let sort:string='asc';
      console.log(this.graphdata.sort( (a, b) => {
        if(sort == 'desc')
        {
          if (a[index].toString().toLowerCase() > b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() > a[index].toString().toLowerCase()) {
              return 1;
          }          
        }
        else
        {
          if (a[index].toString().toLowerCase() < b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() < a[index].toString().toLowerCase()) {
              return 1;
          }  
        }
        return index;
      }));
      this.graphHeader = res.result.header;
      this.visibleColumnData =  res.result.header.map((m:any,index:number)=>{return {id:index.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
      this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id.toString()});
    }
  }

  onActionClick(data:any)
  {
    if(data)
    {
      if(data.action=="View")
      {
        this.router.navigateByUrl(this.viewUrl,{ state: { id:data.id }});
      }
      else if(data.action=="Edit")
      {
        this.router.navigateByUrl(this.editUrl,{ state: { id:data.id }});
      } 
    }
  }

  onCreateBtnClick(data:any)
  {
    this.router.navigate([this.createUrl]);
  }

  async getPermissions(menuId:number)
  {
    let obj = {uniqueUserId:this.generalService.getUniqueuserid(),menuId:menuId};
    let res = await this.menuService.GetMenuPermissionsByUserId(obj);
    if(res && res.isSuccess)
    {
      if(res.result.userMenus.length > 0)
      {
        res.result.userMenus.forEach((element:any) => {
          if(element.permissionName.toLowerCase() =="create")
          {
            this.canCreate = true;
            this.createUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="edit")
          {
            this.canEdit = true;
            this.editUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="view")
          {
            this.canView = true;
            this.viewUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="delete")
          {
            this.canDelete = true;
            this.deleteUrl = element.menuCommand;
          }
        });
      }      
    }
  }
  
}
