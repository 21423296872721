import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './routes/user/login/login.component';
import { AuthGuard } from './authinterceptor/auth_guard';
import { DashboardComponent } from './routes/dashboard/dashboard/dashboard.component';
import { DistributorComponent } from './distributor/distributor.component';
import { ResellerComponent } from './reseller-info/reseller-info.component';
import { AgentComponent } from './agent/agent.component';
import { SettingsComponent } from './settings/settings.component';
import { DritributorNewComponent } from './dritributor-new/dritributor-new.component';
import { ForgotpasswordComponent } from './routes/user/forgotpassword/forgotpassword.component';
import { OtpVerificationComponent } from './routes/user/otp-verification/otp-verification.component';
import { ResetPasswordComponent } from './routes/user/reset-password/reset-password.component';
import { DistributorListComponent } from './routes/distributor/distributor-list/distributor-list.component';
import { DistributorCreateComponent } from './routes/distributor/distributor-create/distributor-create.component';
import { DistributorViewComponent } from './routes/distributor/distributor-view/distributor-view.component';
import { DistributorEditComponent } from './routes/distributor/distributor-edit/distributor-edit.component';
import { ResellerListComponent } from './routes/reseller/reseller-list/reseller-list.component';
import { AgentEditComponent } from './routes/agent/agent-edit/agent-edit.component';
import { AgentViewComponent } from './routes/agent/agent-view/agent-view.component';
import { AgentCreateComponent } from './routes/agent/agent-create/agent-create.component';
import { AgentListComponent } from './routes/agent/agent-list/agent-list.component';
import { ResellerEditComponent } from './routes/reseller/reseller-edit/reseller-edit.component';
import { ResellerViewComponent } from './routes/reseller/reseller-view/reseller-view.component';
import { ResellerCreateComponent } from './routes/reseller/reseller-create/reseller-create.component';
import { LogsComponent } from './routes/logs/logs/logs.component';
import { CustomerListComponent } from './routes/customer/customer-list/customer-list.component';
import { CustomerViewComponent } from './routes/customer/customer-view/customer-view.component';
import { MenuListComponent } from './routes/menu/menu-list/menu-list.component';
import { MenuCreateComponent } from './routes/menu/menu-create/menu-create.component';
import { MenuEditComponent } from './routes/menu/menu-edit/menu-edit.component';
import { RolesListComponent } from './routes/roles/roles-list/roles-list.component';
import { RolesCreateComponent } from './routes/roles/roles-create/roles-create.component';
import { RolesViewComponent } from './routes/roles/roles-view/roles-view.component';
import { RolesEditComponent } from './routes/roles/roles-edit/roles-edit.component';
import { UsersListComponent } from './routes/users/users-list/users-list.component';
import { UsersCreateComponent } from './routes/users/users-create/users-create.component';
import { UsersViewComponent } from './routes/users/users-view/users-view.component';
import { UsersEditComponent } from './routes/users/users-edit/users-edit.component';
import { LeadsListComponent } from './leads/leads-list/leads-list.component';
import { AdminDashboardComponent } from './routes/dashboard/admin-dashboard/admin-dashboard.component';
import { ResellerDashboardComponent } from './routes/dashboard/reseller-dashboard/reseller-dashboard.component';
import { SalesListComponent } from './routes/sales/sales-list/sales-list.component';
import { PolicyDetailsComponent } from './routes/policies/policy-details/policy-details.component';
import { CompanyAdminDashboardComponent } from './routes/dashboard/company-admin-dashboard/company-admin-dashboard.component';
import { AgentCommissionCreateComponent } from './routes/commission/agent-commission-create/agent-commission-create.component';
import { AgentCommissionsComponent } from './routes/commission/agent-commissions/agent-commissions.component';
import { AgentCommissionEditComponent } from './routes/commission/agent-commission-edit/agent-commission-edit.component';
import { InsuranceProvidersComponent } from './routes/insurance-providers/insurance-providers.component';
import { InsuranceProviderViewComponent } from './routes/insurance-provider-view/insurance-provider-view.component';
import { InsuranceProviderEditComponent } from './routes/insurance-provider-edit/insurance-provider-edit.component';
import { DistributorProfileComponent } from './routes/distributor/distributor-profile/distributor-profile.component';
import { ResellerProfileComponent } from './routes/reseller/reseller-profile/reseller-profile.component';
import { PolicyLogListingComponent } from './routes/policyLogs/policy-log-listing/policy-log-listing.component';
import { SmsLogsComponent } from './routes/logs/sms-logs/sms-logs.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotpasswordComponent },
  { path: 'verify-otp', component: OtpVerificationComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'distributors', component: DistributorListComponent, canActivate: [AuthGuard] },
  { path: 'distributor-create', component: DistributorCreateComponent, canActivate: [AuthGuard] },
  { path: 'distributor-view', component: DistributorViewComponent , canActivate: [AuthGuard]},
  { path: 'distributor-edit', component: DistributorEditComponent , canActivate: [AuthGuard]},
  { path: 'distributor-profile', component: DistributorProfileComponent , canActivate: [AuthGuard]},
  { path: 'resellerinfo', component: ResellerComponent, canActivate: [AuthGuard] },
  { path: 'agentInfo', component: AgentComponent, canActivate: [AuthGuard] },
  { path: 'resellers', component: ResellerListComponent, canActivate: [AuthGuard] },
  { path: 'reseller-create', component: ResellerCreateComponent, canActivate: [AuthGuard] },
  { path: 'reseller-view', component: ResellerViewComponent , canActivate: [AuthGuard]},
  { path: 'reseller-edit', component: ResellerEditComponent , canActivate: [AuthGuard]},
  { path: 'reseller-profile', component: ResellerProfileComponent , canActivate: [AuthGuard]},
  { path: 'agents', component: AgentListComponent, canActivate: [AuthGuard] },
  { path: 'agent-create', component: AgentCreateComponent, canActivate: [AuthGuard] },
  { path: 'agent-view', component: AgentViewComponent , canActivate: [AuthGuard]},
  { path: 'agent-edit', component: AgentEditComponent , canActivate: [AuthGuard]},
  { path: 'logs', component: LogsComponent , canActivate: [AuthGuard]},
  { path: 'customers', component: CustomerListComponent, canActivate: [AuthGuard] },
  { path: 'customer-view', component: CustomerViewComponent, canActivate: [AuthGuard] },
  { path: 'settings/:DistributorId', component: SettingsComponent },
  { path: 'roles', component: RolesListComponent, canActivate: [AuthGuard] },
  { path: 'role-create', component: RolesCreateComponent, canActivate: [AuthGuard] },
  { path: 'role-view', component: RolesViewComponent , canActivate: [AuthGuard]},
  { path: 'role-edit', component: RolesEditComponent , canActivate: [AuthGuard]},
  { path: 'users', component: UsersListComponent, canActivate: [AuthGuard] },
  { path: 'user-create', component: UsersCreateComponent, canActivate: [AuthGuard] },
  { path: 'user-view', component: UsersViewComponent , canActivate: [AuthGuard]},
  { path: 'user-edit', component: UsersEditComponent , canActivate: [AuthGuard]},
  { path: 'leads-list', component: LeadsListComponent , canActivate: [AuthGuard]},
  { path: 'admin-dashboard', component: AdminDashboardComponent , canActivate: [AuthGuard]},
  { path: 'reseller-dashboard', component: ResellerDashboardComponent , canActivate: [AuthGuard]},
  { path: 'sales-list', component: SalesListComponent , canActivate: [AuthGuard]},
  { path: 'policy-details', component: PolicyDetailsComponent , canActivate: [AuthGuard]},
  { path: 'company-dashboard', component: CompanyAdminDashboardComponent , canActivate: [AuthGuard]},
  { path: 'agent-commissions', component: AgentCommissionsComponent , canActivate: [AuthGuard]},
  { path: 'agent-commission-create', component: AgentCommissionCreateComponent , canActivate: [AuthGuard]},
  { path: 'agent-commission-edit', component: AgentCommissionEditComponent , canActivate: [AuthGuard]},
  { path: 'insurance-providers', component: InsuranceProvidersComponent , canActivate: [AuthGuard]},
  { path: 'insurance-provider-view', component: InsuranceProviderViewComponent , canActivate: [AuthGuard]},
  { path: 'insurance-provider-edit', component: InsuranceProviderEditComponent , canActivate: [AuthGuard]},
  { path: 'policy-logs', component: PolicyLogListingComponent , canActivate: [AuthGuard]},
  { path: 'sms-logs', component: SmsLogsComponent , canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
