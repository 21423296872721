import { Component, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js'; 
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import Annotation from 'chartjs-plugin-annotation';
import { BaseChartDirective } from 'ng2-charts';
import { Item } from 'src/app/models/item';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      
      datalabels: {
        formatter: (value: any, ctx: any) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
        },
      },
    },
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [['Commission', 'Earned'], ['Commission', 'Pending']],
    datasets: [
      {
        data: [10250, 5250],
        backgroundColor: ['#54B66F', '#FFAD2A'], 
        borderColor: ['#54B66F', '#FFAD2A'],
      },
    ],
  };
  pieChartLabels: string[] = ['Red', 'Green', 'Blue']; // Example labels
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [DatalabelsPlugin];
  

  activeTab: number = 1;
  activeTabs:number = 5;
  activeTabs1:number = 1;
  changeTab(tab: number) {
    this.activeTab = tab;
  }
  changeTabs(tab: number) {
    this.activeTabs = tab;
  }
  changeTabsone(tab: number) {
    this.activeTabs1 = tab;
  }
  // line charts
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        // display: true,
        grid: {
          display: false, // Hide the x-axis grid lines
        },
                
        ticks: {
          stepSize: 50, // Set the spacing between grid lines
        },
      },
      y: {
        
        min: 0,
        
        ticks: {
          stepSize: 30, // Set the spacing between grid lines
        },
        title: {
          display: true,
          text: '₹ (thousands)',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];
  public barChartData: ChartData<'bar'> = {
    labels: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    datasets: [
      { 
        data: [80, 85, 100, 95, 86, 105, 90, 110, 93], 
        label: 'Previous Year(2022)', 
        backgroundColor: ['#10A0C1'], 
        borderColor: ['#10A0C1'],
      },
      { 
        data: [35, 45, 40, 25, 50, 55, 56, 58, 47], 
        label: 'Current Year(2023)',
        backgroundColor: ['#FFAD2A'],
        borderColor: ['#FFAD2A']
      },
        
    ],
  };
  // Line Charts
  private newLabel? = 'New label';

  constructor() {
    Chart.register(Annotation);
  }

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [20, 42, 40, 60, 41, 82, 62, 85],
        label: 'Series A',
        backgroundColor: '#9515181A',
        borderColor: '#10A0C1',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#10A0C1',
        borderWidth: 1.5,
        fill: 'origin',
      }
    ],
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.38,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {
        grid: {
          display: false, // Hide the x-axis grid lines
        },
        ticks: {
          stepSize: 30, // Set the spacing between grid lines
        },
      },
      y: {
        position: 'left',
        grid: {
          display: false, // Hide the x-axis grid lines
        },
        ticks: {
          stepSize: 20, // Set the spacing between grid lines
        },
      },
      y1: {
        display: false,
        position: 'right',
        grid: {
          display: false,
          color: 'rgba(255,0,0,0.3)',
        },
        ticks: {
          color: 'red',
        },
      },
    },

    plugins: {
      legend: { display: false },
      annotation: {
        annotations: [
          {
            type: 'line',
            scaleID: 'x',
            // value: 'March',
            // borderColor: 'orange',
            // borderWidth: 2,
            label: {
              display: true,
              position: 'center',
              color: 'orange',
              content: 'LineAnno',
              font: {
                weight: 'bold',
              },
            },
          },
        ],
      },
    },
  };

  public lineChartType: ChartType = 'line';

  public barChartPolicyData: ChartData<'bar'> = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    datasets: [
      { 
        data: [35, 41, 37, 28, 45, 48, 52, 53, 41], 
        label: 'Previous Year(2022)', 
        backgroundColor: ['#FEB019'], 
        borderColor: ['#FEB019'],
      },
        
    ],
  };
  public barChartPolicyOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        
        // display: true,
        grid: {
          display: false, // Hide the x-axis grid lines
        },
               
        ticks: {
          stepSize: 20, // Set the spacing between grid lines
          
        },
      },
      y: {
        
        min: 0,
        
        ticks: {
          stepSize: 10, // Set the spacing between grid lines
        },
        title: {
          display: true,
          text: '₹ (thousands)',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        display: false, // Hide the data labels (bar values)
      },
    },
  };
}
