import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../shared/AppConfig';
import { ApiResponse, ApiResponseNew } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async AuthenticateUser(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}login/GetAuthentication`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async ResetPassword(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}login/ResetPassword`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async CheckPhoneExists(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/CheckPhoneExistsInternal`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }

  async SendOtp(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/SendOtp`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }

  async ValidateOTP(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/ValidateOTPInternal`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }
}
