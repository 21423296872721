import { Component, Input, OnInit } from '@angular/core';
import { SecurityLogsModel } from 'src/app/models/CommonModel';
import { Item } from 'src/app/models/item';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-login-logs',
  templateUrl: './login-logs.component.html',
  styleUrls: ['./login-logs.component.css']
})
export class LoginLogsComponent implements OnInit{
  // @Input() userId:number=0;
  // @Input() uniqueUserId:string='';
  graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];
  isLoginDetailsPopup:boolean=false;
  securityLogsData: SecurityLogsModel | undefined;
  constructor(private adminService: AdminService){
    //this.getLoginLogs();
  }
  ngOnInit() {}
  async getLoginLogs(userId:number,uniqueUserId:string) {
    debugger;
    const res = await this.adminService.GetSecurityLogs(userId,uniqueUserId);//this.generalService.getUniqueuserid());    
    if (res != null && res.result) {
      this.graphdata = res.result.data;
      let index=3;
      let sort:string='asc';
      console.log(this.graphdata.sort( (a, b) => {
        if(sort == 'desc')
        {
          if (a[index].toString().toLowerCase() > b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() > a[index].toString().toLowerCase()) {
              return 1;
          }          
        }
        else
        {
          if (a[index].toString().toLowerCase() < b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() < a[index].toString().toLowerCase()) {
              return 1;
          }  
        }
        return index;
      }));
      this.graphHeader = res.result.header;
      this.visibleColumnData =  res.result.header.map((m:any,index:number)=>{return {id:index.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
      this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id.toString()});
    }
  }

  async onActionClick(data:any)
  {
    if(data)
    {
      if(data.action=="View")
      {
        this.isLoginDetailsPopup = true;
        const res = await this.adminService.GetSecurityLogById(data.id);   
        if (res != null && res.result) { 
          debugger;
          this.securityLogsData = res.result;
          let dd = this.securityLogsData;
          console.log(this.securityLogsData)
        }
      }
    }
  }

  closeLoginDetailsPopup(){
    this.isLoginDetailsPopup = false;
  }
}
