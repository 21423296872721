<app-mainscreen>
    <div class="box_container">
        <div class="box_header flex-column">
            <label class="box_title_18 border_bottom_grey5 w-100 mb-0 pb-2">Create User</label>
            <div class="row w-100">
                <div class="col-12">
                  <ul class="d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center list-item-container">
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                        <img src="/assets/svgIcon/25_.svg" class="logo" />
                        Step 1 : User Details
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                        <img src="/assets/svgIcon/50_.svg" class="logo" />
                        Step 2 : Permission Details
                    </li>  
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                        <img src="/assets/svgIcon/100_.svg" class="logo" />
                        Step 3 : Summary
                    </li>            
                  </ul>
                </div>
              </div> 
        </div>       
    </div>
    <div class="box_container mt-3"> 
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <form id="roleForm" class="form" [formGroup]="userForm" novalidate="novalidate">
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        User Details
                    </span>                
                    <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2)">
                    </app-custom-button>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>                
                        <div class="row">
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((userFormControl['firstName'].touched  || submittedRoleForm) && !userFormControl['firstName'].valid)}">
                                <input type="text" id="firstName" name="firstName" placeholder=" " class="form-control input_" formControlName="firstName" (keypress)="alphabetWithSpace($event)"
                                [ngClass]="{'border border-danger': ((userFormControl['firstName'].touched  || submittedRoleForm) && userFormControl['firstName'].errors?.['required'])}">
                                <label for="firstName" class="label_">First Name</label>
                                <Span class="text-danger error_span" *ngIf="((userFormControl['firstName'].touched  || submittedRoleForm) && userFormControl['firstName'].errors?.['required'])">
                                    First name is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((userFormControl['lastName'].touched  || submittedRoleForm) && !userFormControl['lastName'].valid)}">
                                <input type="text" id="lastName" name="lastName" placeholder=" " class="form-control input_" formControlName="lastName" (keypress)="alphabetWithSpace($event)"
                                [ngClass]="{'border border-danger': ((userFormControl['lastName'].touched  || submittedRoleForm) && userFormControl['lastName'].errors?.['required'])}">
                                <label for="lastName" class="label_">Last Name</label>
                                <Span class="text-danger error_span" *ngIf="((userFormControl['lastName'].touched  || submittedRoleForm) && userFormControl['lastName'].errors?.['required'])">
                                    Last name is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((userFormControl['phoneNumber'].touched  || submittedRoleForm) && !userFormControl['phoneNumber'].valid)}">
                                <input type="text" id="phoneNumber" name="phoneNumber" placeholder=" " class="form-control input_" formControlName="phoneNumber" (keypress)="numericOnly($event)"
                                [ngClass]="{'border border-danger': ((userFormControl['phoneNumber'].touched  || submittedRoleForm) && userFormControl['phoneNumber'].errors?.['required'])}">
                                <label for="phoneNumber" class="label_">Phone Number</label>
                                <Span class="text-danger error_span" *ngIf="((userFormControl['phoneNumber'].touched  || submittedRoleForm) && userFormControl['phoneNumber'].errors?.['required'])">
                                    Phone number is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((userFormControl['userEmail'].touched  || submittedRoleForm) && !userFormControl['userEmail'].valid)}">
                                <input type="text" id="userEmail" name="userEmail" placeholder=" " class="form-control input_" formControlName="userEmail"
                                [ngClass]="{'border border-danger': ((userFormControl['userEmail'].touched  || submittedRoleForm) && userFormControl['userEmail'].errors?.['required'])}">
                                <label for="userEmail" class="label_">Email</label>
                                <Span class="text-danger error_span" *ngIf="((userFormControl['userEmail'].touched  || submittedRoleForm) && userFormControl['userEmail'].errors?.['required'])">
                                    Email is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((userFormControl['dob'].touched  || submittedRoleForm) && !userFormControl['dob'].valid)}">
                                <input type="date" id="dob" name="dob" placeholder=" " class="form-control input_" formControlName="dob"
                                [ngClass]="{'border border-danger': ((userFormControl['dob'].touched  || submittedRoleForm) && userFormControl['dob'].errors?.['required'])}">
                                <label for="dob" class="label_">Date of Birth</label>
                                <Span class="text-danger error_span" *ngIf="((userFormControl['dob'].touched  || submittedRoleForm) && userFormControl['dob'].errors?.['required'])">
                                    Date of birth is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((userFormControl['gender'].touched  || submittedRoleForm) && !userFormControl['gender'].valid)}">
                                <!-- <input type="text" id="gender" name="gender" placeholder=" " class="form-control input_" formControlName="gender"
                                [ngClass]="{'border border-danger': ((userFormControl['gender'].touched  || submittedRoleForm) && userFormControl['gender'].errors?.['required'])}">
                                <label for="gender" class="label_">Gender</label> -->
                                
                                <select id="gender" name="gender" placeholder=" " class="form-control input_" formControlName="gender"
                                [ngClass]="{'border border-danger': ((userFormControl['gender'].touched  || submittedRoleForm) && !userFormControl['gender'].valid)}">
                                <option *ngFor="let gen of GenderList" [value]="gen.value">
                                    {{gen.text}}
                                </option>
                                </select>  
                                <label for="gender" class="label_">Gender</label>                              
                                <Span class="text-danger error_span" *ngIf="((userFormControl['gender'].touched  || submittedRoleForm) && userFormControl['gender'].errors?.['required'])">
                                    Gender is required.
                                </Span>
                            </div>
                        </div>
                </div>        
            </form>
        </div>
        <div id="permissions" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">            
                <div class="box_header">
                    <span class="box_title_18">
                        Permission Details
                    </span>
                    <div class="d-flex">
                        <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(1,true)"></app-custom-button> 
                        <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(3)"></app-custom-button>
                    </div>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>  
                    <div class="px-4">
                        <select id="userType" name="userType" placeholder=" " [(ngModel)]="roleId" class="form-control input_ w-25" (change)="onRoleChange()">
                            <option *ngFor="let role of rolesList" [value]="role.Id">{{role.RoleName}}</option>
                        </select>
                        <label for="userType" class="label_">Role</label>  
                    </div> 
                    <app-tree-table [selectedIds]="selectedIds" [nodes]="nodes" (onChanged)="onCheckChanged($event)"></app-tree-table>
                </div>  
        </div>
        <div id="sumary" class="tab-pane w-100 pb-3" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
            <div class="box_header pb-0">
                <span class="box_title_18">
                    Summary
                </span>
                <app-custom-button text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="saveNext(1)">                    
                </app-custom-button>
            </div>
            <div class="p-lr-10">
                <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label> 
                <div class="box_container mt-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">1. User Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-3 label_13_grey3">
                                    First Name
                                </div>
                                <div class="col-md-9 label_13_grey2">
                                    {{userFormControl['firstName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-3 label_13_grey3">
                                    Last Name
                                </div>
                                <div class="col-md-9 label_13_grey2">
                                    {{userFormControl['lastName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-3 label_13_grey3">
                                    Phone Number
                                </div>
                                <div class="col-md-9 label_13_grey2">
                                    {{userFormControl['phoneNumber'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-3 label_13_grey3">
                                    Email
                                </div>
                                <div class="col-md-9 label_13_grey2">
                                    {{userFormControl['userEmail'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-3 label_13_grey3">
                                   DOB 
                                </div>
                                <div class="col-md-9 label_13_grey2">
                                    {{userFormControl['dob'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-3 label_13_grey3">
                                    gender
                                </div>
                                <div class="col-md-9 label_13_grey2">
                                    {{userFormControl['gender'].value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
                <div class="box_container mt-4">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">2. Permissions Details</label>   
                    </div>
                    <div class="row w-100">
                        <div  class="col-12 mt-2">
                            <div class="px-4">
                                <select id="role" name="role" placeholder=" " class="form-control input_ w-25 tp" [disabled]="true">
                                    <option [value]="userFormControl['userRole'].value">{{userFormControl['userRole'].value}}</option>
                                </select>
                                <label for="role" class="label_">Role</label>
                            </div>  
                        </div> 
                        <div class="col-6">
                            <app-tree-table [selectedIds]="selectedIds" [nodes]="nodes" [disableAll]="true"></app-tree-table>
                        </div>
                    </div>
                </div> 
                <app-custom-button className="mt-3" text="Confirm" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveData()"></app-custom-button>           
            </div> 
        </div>
    </div>
    </app-mainscreen>