<div id="myCarousel" class="carousel slide mt-n5" data-bs-ride="carousel">
    <!-- Indicators/dots -->
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
        <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
        <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
        <button type="button" data-bs-target="#demo" data-bs-slide-to="3"></button>
    </div>

    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="../../../../assets/images/dashboard.png"  alt="...">
            <h4 class="text-center">Dashboard Business Summary</h4>
            <p class="font-body text-center">In our dashboard, find a comprehensive business overview for a quick insight into our company's performance and key metrics.</p>
          </div>
      <div class="carousel-item">
        <img src="../../../../assets/images/sales_graph.png"  alt="...">
        <h4 class="text-center">Sales Graph Report</h4>
            <p class="font-body text-center">Explore our sales report with visual graphs for a clear, data-driven perspective on our sales performance and trends.</p>
      </div>
      <div class="carousel-item">
        <img src="../../../../assets/images/reports.png"  alt="...">
        <h4 class="text-center">Clear Report</h4>
            <p class="font-body text-center">Welcome to our user-friendly report, which is designed to make it simple to understand the most important details and observations.</p>
      </div>
      <div class="carousel-item">
        <img src="../../../../assets/images/settings.png"  alt="...">
        <h4 class="text-center">Settings & User Roles</h4>
            <p class="font-body text-center">Powerful settings let you set things up just right, while clear user roles keep things safe and organized within an organization.</p>
      </div>
    </div>
    <!-- <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> -->
  </div>
  