import { createReducer, on } from '@ngrx/store';
import { initialState } from './sidebar.state';
import { selectMenu } from './sidebar.actions';

const _sidebarReducer = createReducer(
  initialState,
  on(selectMenu, (state, action) => ({
    ...state,
    selectedMenu: action.selectedMenu,
  }))
);

export function sidebarReducer(state: any, action: any) {
  return _sidebarReducer(state, action);
}
