<div class="d-flex justify-content-between align-items-center w-100">
    <!-- heading area -->
    <div class="d-flex flex-column">
        <h1 style="color: var(--grey2); font-weight: 600; margin: 0px;">{{title}}</h1>
        <p style="color: var(--grey3); font-weight: 400; margin: 0px;">{{titleDiscription}}</p>
        <div style="display: flex; align-items: center; justify-content: start; opacity: 0.7; margin-top: 5px;" *ngIf="breadcrumbs.length > 0">
            <div *ngFor="let i of breadcrumbs" style="display: flex; align-items: center; justify-content: center;">
                <h5 class="m-0 p-0 breadcrumb_title">{{i}}</h5>
                <span class="material-symbols-outlined text-grey4 mx-1" style="font-size: 15px;"
                    *ngIf="i != breadcrumbs[breadcrumbs.length - 1]">
                    keyboard_arrow_right
                </span>
            </div>
        </div>
    </div>

    <!-- action area -->
    <div class="d-flex flex-row bg-white" style="padding: 10px; border-radius: 7px; gap: 15px;">
        <ng-content></ng-content>
    </div>
</div>