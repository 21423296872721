import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-bar-input-field',
  templateUrl: './search-bar-input-field.component.html',
  styleUrls: ['./search-bar-input-field.component.css']
})
export class SearchBarInputFieldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
