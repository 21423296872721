import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TreeNode } from 'src/app/models/MenuData';
import { UserService } from 'src/app/services/user.service';
import swal from 'sweetalert2';
import { alphaNumericOnly,alphabetOnly,alphabetWithSpaces,numericOnly } from 'src/app/functions/validation-functions';
import { MenuService } from 'src/app/services/menu.service';
@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.css']
})
export class UsersCreateComponent {
  selectedIds:number[]=[];
  nodes: TreeNode[] =[];
  activeTab:number=1;
  userForm: FormGroup;
  submittedRoleForm:boolean=false;
  submittedPermissionForm:boolean=false;
  permissions: TreeNode[] = [];
  rolesList:any=[];
  GenderList:any[]=[{text:"Select Gender",value:""},{text:"Female",value:"Female"},{text:"Male",value:"Male"},{text:"Other",value:"Other"}];
  roleId:number=0;
  constructor(private fb: FormBuilder,private userService: UserService,private router:Router,private menuService : MenuService ) {
      this.userForm = this.fb.group({
        "userId": [0],
        "uniqueUserId": [''],
        "firstName": ['', [Validators.required]],
        "lastName": ['', [Validators.required]],
        "phoneNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
        "countryCode": ['+91', [Validators.required]],
        "userEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        //"isLocked": [false, [Validators.required]],
        "isActive": [true],
        "userStatus": [true],
        "dob": ['', [Validators.required]],
        "gender": ['', [Validators.required]],
        "userType": [0],
        "userMenus":[],
        "userRole":["Admin"]
      }); 
      this.bindRolesDDL();     
    }

    async bindRolesDDL(){
      //this.rolesList = this.menuService.GetRolesDDL();
      let res = await this.menuService.GetRolesDDL();
        if (res != null && res.isSuccess) {
          this.rolesList = JSON.parse(res.result.obj);
          if(this.rolesList && this.rolesList.length > 0){
            this.roleId = this.rolesList[0].Id;
            this.getMenuPermissionData();
          }          
        }
    }

    async getMenuPermissionData()
    {
      const res = await this.menuService.GetMenuPermissionByRoleId(this.roleId);
        if (res != null && res.result) {    
          let userMenus =  res.result.userMenus;           
          this.userForm.get("userMenus")?.setValue(userMenus);
          this.permissions = JSON.parse(JSON.stringify(userMenus));
          for(var i=0; i<this.permissions.length; i++) { // add childs to every item
            this.permissions[i].children = [];
          }
          let tempSe = JSON.parse(JSON.stringify(this.permissions));
          this.selectedIds =tempSe.filter((f:any)=>{return f.isSelected == true}).map((m:any)=>{return m.menuId});
          this.buildTree(this.permissions,undefined);
          this.nodes = this.permissions;
        } 
    } 

  get userFormControl() {
    return this.userForm.controls;
  }

  changeTab(tab:number)
  {
    this.activeTab = tab;
  }

  async saveNext(tab:number,isPrevious:boolean = false)
  {    
    if(tab == 2 && isPrevious == false)
    {
      this.submittedRoleForm = true;
        if(this.userForm.valid)
        {
          this.activeTab = tab;
        }      
    }
    else
    {
      this.activeTab = tab;
    }
  }

  ngOnInit()
  { 
    
  }
  buildTree(tree:TreeNode[], item:any) {
    if (item) { // if item then have parent
        for (var i=0; i<tree.length; i++) { // parses the entire tree in order to find the parent
            tree[i].collapsed = !tree[i].isSelected;
            if (String(tree[i].menuId) === String(item.menuAuthorityId)) { // bingo!
                item.collapsed = !item.isSelected;
                tree[i].children.push(item); // add the child to his parent
                break;
            }
            else this.buildTree(tree[i].children, item); // if item doesn't match but tree have childs then parses childs again to find item parent
        }
    }
    else { // if no item then is a root item, multiple root items are supported
        var idx = 0;
        while (idx < tree.length)
            if (tree[idx].menuAuthorityId != 0) 
            this.buildTree(tree, tree.splice(idx, 1)[0]) // if have parent then remove it from the array to relocate it to the right place
            else idx++; // if doesn't have parent then is root and move it to the next object
        }
    }

    alphabetOnly(event:any): boolean {
      return alphabetOnly(event);
    }

    alphabetWithSpace(event:any): boolean {
      return alphabetWithSpaces(event);
    }
  
    alphaNumericOnly(event:any): boolean {
      return alphaNumericOnly(event);
    }

    numericOnly(event:any): boolean {
      return numericOnly(event);
    }

    async saveData(){
      if(this.userForm.valid)
      {        
        this.userForm.get("userType")?.setValue(parseInt(this.roleId.toString()));
        this.userFormControl['userMenus'].setValue(this.userFormControl['userMenus'].value.map((m:any)=>{return {...m, isSelected: this.selectedIds.includes(m.menuId) ? true : false}}));
        let res = await this.userService.SaveUpdateUserWithPermission(this.userForm.value);
        if (res != null) {
          swal.fire({
            position: 'center',
            icon:  res.isSuccess ?'success' : 'error',
            title: res.result,
            showConfirmButton: false,
            timer: 3000,
          });
        }
        if(res?.isSuccess)
        {
          this.router.navigate(['/users']);
        }
      }
    }

    onCheckChanged(selectedIDs: any) {
      this.selectedIds = selectedIDs;
    }

    onRoleChange(){
      debugger;
      let roleName = this.rolesList.filter((f:any)=>{return f.Id == this.roleId}).map((m:any)=>{return m.RoleName})[0];
      this.userForm.get("userRole")?.setValue(roleName);
      this.getMenuPermissionData()
    }
}
