import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentDetails } from 'src/app/models/Distributor';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {
  uploadedFile: File | undefined;
  @Input() docObj: DocumentDetails = {};
  @Input() id = '';
  @Input() className!:string;
  @Input() showErrorRequired = false;
  @Input() showErrorValid = false;
  @Input() acceptType!:string;
  @Output() selectedFile = new EventEmitter();
  @Output() deletedFile = new EventEmitter();
  @Input() LableclassName:string ="";
  onDragOver(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.addFileDropStyles();
  }

  onDragLeave(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.removeFileDropStyles();
  }

  onDrop(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.removeFileDropStyles();
    const files = (event as DragEvent).dataTransfer?.files;
    if (files && files.length > 0) {
      this.uploadedFile = files[0]; // Handle only the first file
      this.sendSelectedFile();
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.uploadedFile = input.files[0]; // Handle only the first file
      this.sendSelectedFile();
    }
  }

  sendSelectedFile()
  {
    debugger;
    let reader = new FileReader();
      reader.onload = async (event: any) => {
        let baseUpload64FileData = reader.result!.toString();
        //this.documentForm.get('documentBase64')?.setValue(baseUpload64FileData);
        let fileType = await this.SetFileType(baseUpload64FileData);
        this.docObj.documentBase64=baseUpload64FileData;
        this.docObj.documentTypeName=fileType;
        this.docObj.documentName=this.uploadedFile?.name;
        this.docObj.documentDownloadName=this.uploadedFile?.name;
        this.selectedFile.emit(this.docObj);
      }
      if(this.uploadedFile != undefined && this.uploadedFile != null)
      {
        reader.readAsDataURL(this.uploadedFile);
      }
      
  }

  private addFileDropStyles(): void {
    const fileDrop = document.getElementById(this.id);
    fileDrop?.classList.add('drag-over');
  }
  
  private removeFileDropStyles(): void {
    const fileDrop = document.getElementById(this.id);
    fileDrop?.classList.remove('drag-over');
  }

  async SetFileType(baseUpload64FileData:string) {
    let format=''; 
    if (baseUpload64FileData.includes(`application/pdf`)) {
      format = 'pdf';
    }
    else if (baseUpload64FileData.includes(`image`)) {
      format = 'image';
    }
    else if (baseUpload64FileData.includes(`video`)) {
      format = 'video';
    }
    return format;
  }

  removeDocument(typeId:any)
  {
    this.uploadedFile = undefined;
    this.docObj.documentBase64='';
    this.docObj.documentTypeName='';
    this.docObj.documentName='';
    this.deletedFile.emit(typeId);
  }
}
