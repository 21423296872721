import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../shared/AppConfig';
import { ApiResponse } from '../models/ApiResponse';
import { USerNotification } from '../models/SelectItems';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async DistributorInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}distributor/DistributorInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async AddEditDistributorInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}distributor/AddEditDistributorInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async GetDistributorById(data: any, unoqueUserId = "") {
    return this.http.get<ApiResponse>(`${environment.APIUrl}distributor/GetDistributorById?distributorId=`+data + `&uniqueUserId=` +unoqueUserId , {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async ResellerInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}reseller/ResellerInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetResellerById(data: any, unoqueUserId = "") {
    return this.http.get<ApiResponse>(`${environment.APIUrl}reseller/GetResellerById?resellerId=`+data + `&uniqueUserId=` +unoqueUserId , {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async AgentInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}agent/AgentInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetAgentById(data: any) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetAgentById?agentId=`+data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }
  
  async ResellerDropDown() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}reseller/ResellerDropDown`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async ResellerDropDownByDistributorId(data:any) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}reseller/ResellerDropDownByDistributorId?distributorId=`+data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async DistributorDropDown() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}distributor/DistributorDropDown`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async ResellerDetails(data:any) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}reseller/ResellerListing?distributorId=`+ data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async DistributorDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}distributor/DistributorDetails`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async DistributorDetails1() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}distributor/DistributorDetails1`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async AgentDetails(resellerId:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}agent/AgentListing?resellerId=${resellerId}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async CustomerDetails(agentId:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetAgentCustomerListDynamic?agentId=${agentId}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetCustomerDetailsById(customerId:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetCustomerDetailsById?customerId=${customerId}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async PolicyDetailsByUserId(data:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/PolicyDetailsByUserIdDynamic?userId=${data}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

 async LeadsListing() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetLeadsList`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetCustomerPolicies() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetCustomerPolicies`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async PolicyDetailsByPolicyId(id:number,userId:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/PolicyDetailsByPolicyId?policyId=${id}&userId=${userId}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetAminDashboardData(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/GetAminDashboardData`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetAminDashboardDistributorData(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/GetAminDashboardDistributorData`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async SaveVehicleCommissionMapping(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/SaveVehicleCommissionMapping`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }  

  async GetInputCommissionListing(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/InputCommissionListing`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }  

  async GetInsuranceListing() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/InsuranceListing`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetInsuranceDetails(data:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetInsuranceDetails?insuranceId=` + data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async InsuranceProductStatusUpdate(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/InsuranceProductStatusUpdate`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async GetUserNotificationsByUserId(data:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetUserNotificationsByUserId?userId=` + data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async UpdateUserNotifications(data: USerNotification) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/UpdateUserNotifications`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
   
  }

  async GetSecurityLogs(userId:number,uniqueUserId:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetSecurityLogs?userId=` + userId + `&uniqueUserId=`+ uniqueUserId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetSecurityLogById(id:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetSecurityLogById?id=` + id, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async PolicyCompIncompListing(year:number,month:string,searchFor:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/PolicyCompIncompListing?year=`+ year+`&month=`+month+`&searchFor=`+searchFor, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async PolicyLogListing(data:any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/PolicyLogListing`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async PolicyCompIncompDetailsById(policyId:number,isFailed:boolean) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/PolicyCompIncompDetailsById?policyId=`+ policyId+`&isFailed=`+isFailed, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async PolicyLogsById(id:number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/PolicyLogsById?id=`+ id, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }
  

  async PolicyJourneyLogsByQuoteId(quoteId:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/PolicyJourneyLogsByQuoteId?quoteId=`+ quoteId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetAadharDetailsByUserId(userId:number, isDocumentRequired:boolean) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetAadharDetailsByUserId?userId=${userId}&isDocumentRequired=${isDocumentRequired}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetPanDetailsByUserId(userId:number, isDocumentRequired:boolean) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetPanDetailsByUserId?userId=${userId}&isDocumentRequired=${isDocumentRequired}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetProfileImgDetailsByUserId(userId:number, isDocumentRequired:boolean) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetProfileImgDetailsByUserId?userId=${userId}&isDocumentRequired=${isDocumentRequired}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetQualficationDetailsByUserId(userId:number, isDocumentRequired:boolean) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetQualficationDetailsByUserId?userId=${userId}&isDocumentRequired=${isDocumentRequired}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async GetBankDetailsByUserId(userId:number, isDocumentRequired:boolean) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetBankDetailsByUserId?userId=${userId}&isDocumentRequired=${isDocumentRequired}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async SMSLogListing(data:any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/SMSLogListing`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }

  async DownloadPolicy(p:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/DownloadPolicy?p=${p}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });   
  }
}