<app-mainscreen>
    <div class="view_box_container rounded-bottom1-0">
        <div class="">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="view_div">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <ul>
                                        <li>
                                            <img src="../../../../assets/images/profile_images/avatar_{{agentFormControl['agentGender'].value?.toLowerCase() == 'female' ? 'f' : 'm'}}.svg" />
                                        </li>
                                        <li>
                                            <p><b>{{agentFormControl['agentFirstName'].value}} {{agentFormControl['agentLastName'].value}}</b></p>
                                            <p>Agent ID : {{agentFormControl['agentId'].value}} </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <label><img src="../../../../assets/images/document.png" /> &nbsp; Contract Status : </label> <span class="status">{{agentFormControl['agentStatus'].value == 1 ? 'Active' : 'Inactive'}}</span> <br>
                                    <label><img src="../../../../assets/images/watch_later.png" /> &nbsp;Last Login : </label> <span>{{agentFormControl['lastLogin'].value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="view_buttons">
                        <!-- <button class="edit_button"><img src="../../../../assets/images/message.png" />&nbsp; Lorem </button> -->
                        <app-custom-button className="mt-3" text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="backBtClick()">Edit</app-custom-button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab_div bottom-shadow">
        <ul class="align-items-center p-3 list-item-container-view ">
            <li class="list-item" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                Company Details
            </li> 
            <li class="list-item" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                Documents
            </li> 
            <!-- <li class="list-item" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                Other Details
            </li>          -->
            <li class="list-item" [ngClass]="{'active': activeTab == 4}" (click)="changeTab(4)">
                Login Logs
            </li> 
          </ul>
    </div>
    <div class="tab_content">
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Agent Information</h4></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> cake </span> &nbsp; Date of Birth</p><span class="label_13_grey2">{{agentFormControl['agentDOB'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> transgender </span> &nbsp; Gender</p><span class="label_13_grey2">{{agentFormControl['agentGender'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> call </span> &nbsp; Contact Number</p><span class="label_13_grey2">{{agentFormControl['agentMobile'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> mail </span> &nbsp; Email ID</p><span class="label_13_grey2">{{agentFormControl['agentEmail'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Company Details</h4></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pr-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls">Company Name</p><span class="label_13_grey2">{{companyDetailsFormControl['companyName'].value}}</span></li>                                
                                    <li><p class="label_13_grey3 label_controls">GSTIN</p><span class="label_13_grey2">{{companyDetailsFormControl['companyGSTIN'].value}}</span></li>                                
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> call </span> &nbsp; Contact Number</p><span class="label_13_grey2">{{companyDetailsFormControl['companyMobileNo'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Street Address</p><span class="label_13_grey2">{{companyDetailsFormControl['companyStreetAddress'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">State</p><span class="label_13_grey2">{{companyDetailsFormControl['companyState'].value}}</span></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pl-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls">License Number</p><span class="label_13_grey2">{{companyDetailsFormControl['companyLicenseNo'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Company PAN Card Number</p><span class="label_13_grey2">{{companyDetailsFormControl['companyPancard'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> mail </span> &nbsp; Email</p><span class="label_13_grey2">{{companyDetailsFormControl['companyEmail'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">PIN code</p><span class="label_13_grey2">{{companyDetailsFormControl['companyPincode'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">City</p><span class="label_13_grey2">{{companyDetailsFormControl['companyCity'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Agent Information</h4></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> cake </span> &nbsp; Date of Birth</p><span class="label_13_grey2">{{agentFormControl['agentDOB'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> transgender </span> &nbsp; Gender</p><span class="label_13_grey2">{{agentFormControl['agentGender'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> call </span> &nbsp; Contact Number</p><span class="label_13_grey2">{{agentFormControl['agentMobile'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> mail </span> &nbsp; Email ID</p><span class="label_13_grey2">{{agentFormControl['agentEmail'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <ul>
                            <li><h4>Documents</h4></li>
                            <li *ngFor="let docObj of documentsUploadForm" class="d-flex align-items-center">
                                <img class="img_preview mr-3" src="{{docObj.documentBase64}}" title="{{docObj.documentName}}">
                                <span class="label_13_grey3">{{docObj.documentName}}</span>
                                <a *ngIf="docObj.documentBase64 != null && docObj.documentBase64 != undefined && docObj.documentBase64 != ''" class="cursor-pointer d-flex text-decoration-none" target="_blank" [href]="docObj.documentBase64" download="{{docObj.documentDownloadName}}">
                                    <span class="material-symbols-outlined ml-2 icon cursor-pointer text-grey3" title="download file">
                                        download
                                      </span>
                                </a>
                            </li>                        
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <!-- <ul>
                            <li><h4>Agent Information</h4></li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Date of Birth</span><br>05/05/1993</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Gender</span><br>transgender</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Contact Number</span><br>9705224263</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Email ID</span><br>xyz@gmail.com</li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <!-- <ul>
                            <li><h4>Other Information</h4></li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Date of Birth</span><br>05/05/1993</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Gender</span><br>transgender</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Contact Number</span><br>9705224263</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Email ID</span><br>xyz@gmail.com</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 4,'d-none' : activeTab !=4}">
            <div class="row">
                <div class="col-12">
                    <app-login-logs></app-login-logs>
                </div>
            </div>
        </div>
    </div>
    </app-mainscreen>