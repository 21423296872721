<div class="content-box">
  <div class="container-fluid text-center">
    <div class="card card-user shadow">
      <div>
        <h3>
          <b class="float-start text-primary mt-4 ms-4">Distributor Settings</b>
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group mb-5">
              <label class="required form-label">Url</label>
              <input type="text" class="form-control" [(ngModel)]="url" placeholder="" name="name" required maxlength="40" />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group mb-5">
              <label class="required form-label">Theme</label>
              <input type="color" class="form-control" [(ngModel)]="theme" placeholder="" name="name" required maxlength="40" />
            </div>
          </div>
          <div class="float-end text-center">
            <button class="btn btn-primary" (click)="save()">Save</button> &nbsp;
          </div>
          <div class="row">
            <div class="col-12">
              <label class="card-label fw-bold fs-5 mb-3 me-5">Insurance Products :</label>
            </div>
            <div id="rulesaccordion">
              <div class="accordion" id="accordionRoles">

              

                <div class="accordion-item 1mb-4" *ngFor="let item of insurancelist; let i = index" [attr.data-index]="i">
                  <h2 class="accordion-header" [attr.id]="'heading' + i">
                    <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                      {{item.insuranceName}}
                    </button>
                  </h2>
                  <div [attr.id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionRoles">
                    <div class="accordion-body">

                      <div class="row" *ngFor="let product of item.insuranceProducts">
                        <div class="pb-2" [ngClass]="{'kt-font-bold' :true}">Products</div>
                        <input class="form-check-input h-20px w-20px" id={{product.insuranceProductId}} type="checkbox" [checked]="product.is_active" (change)="productChange(product.insuranceProductId,$event)" [disabled]="product.disable">
                        <label class="form-check-label" for={{product.insuranceProductId}}>{{product.productName}}</label>
                        <div class="col-md-3 mb-5" *ngFor="let gateway of product.productGateways">
                          <div class="pb-2" [ngClass]="{'kt-font-bold' :true}">Gateway</div>
                          <div class="form-check form-check-custom me-10">
                            <input class="form-check-input h-20px w-20px" id={{gateway.gatewayId}} type="checkbox" [checked]="gateway.is_active" (change)="gatewayChange(gateway.gatewayId,$event)" [disabled]="gateway.disable">
                            <label class="form-check-label" for={{gateway.gatewayId}}>{{gateway.gatewayName}}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row" >
                        <div class="col-12">
                          <label class="card-label fw-bold fs-5 mb-3 me-5">Commision :</label>
                        </div>
                      <div class="card-body">
                        <form [formGroup]="distributorForm" class="needs-validation" novalidate="" autocomplete="off">
                        <div class="row">
                          <div class="col-md-4 pr-1">
                            <div class="form-group">
                              <label class="float-start">Commision Type : </label>
                              <select class="form-select" #distributor
                                      aria-label="Default select example" #commision (change)='commisionTypeChange(commision.value)' name="distributor" formControlName="commissionType">
                                <option value="" selected>Select commisionType</option>
                                <option [value]="1">  Percentage </option>
                                <option [value]="2">  Flat Fee </option>
                                <option [value]="3">  Tiers</option>
                              </select>
                            </div>
                          </div>
                          <!-- <div class="col-md-4 pr-1" *ngIf="!isCommisionFlag">
                            <div class="form-group">
                              <label class="float-start">From : </label>
                              <input type="text" DigitsOnly name="From" placeholder="From" class="form-control" formControlName="commissionValue">
                            </div>
                          </div>
                          <div class="col-md-4 pr-1" *ngIf="!isCommisionFlag">
                            <div class="form-group">
                              <label class="float-start">To : </label>
                              <input type="text" DigitsOnly name="To" placeholder="To" class="form-control" formControlName="commissionValue">
                            </div>
                          </div> -->

                          <div class="col-md-4 pr-1" *ngIf="isCommisionFlag">
                            <div class="form-group">
                              <label class="float-start">commision Value : </label>
                              <input type="text" DigitsOnly name="commissionValue" placeholder="commission Value " class="form-control" formControlName="commissionValue">
                            </div>
                          </div>
            
                          <div class="col-md-4 pr-1">
                            <label class="float-start">commissionFrequency : </label>
                              <div class="form-group mb-5">
                                  <div class="form-check form-check-custom me-10">
                                    <input class="form-check-input h-20px w-20px" name="commissionFrequency"  type="radio" value="1" formControlName="commissionFrequency" id="commissionFrequency1" />
                                    <label class="form-check-label">
                                      Onetime
                                    </label>
                                  </div>
                                  <div class="form-check form-check-custom me-10">
                                    <input class="form-check-input h-20px w-20px" name="commissionFrequency"  type="radio" value="2" formControlName="commissionFrequency" id="commissionFrequency2" />
                                    <label class="form-check-label" >
                                      Per Policy
                                    </label>
                                  </div>
                                  <div class="form-check form-check-custom me-10">
                                    <input class="form-check-input h-20px w-20px" name="commissionFrequency"  type="radio" value="3" formControlName="commissionFrequency" id="commissionFrequency3" />
                                    <label class="form-check-label" >
                                      Monthly
                                    </label>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div class="row">
                          <div formArrayName="commissionFee">
                            <div *ngFor="let fee of commissionFee.controls; let i=index">
                              <div [formGroupName]="i">
                                <div class="row">
                                  <div class="col-md-4 pr-1" *ngIf="!isCommisionFlag">
                                    <div class="form-group">
                                      <label class="float-start">From : </label>
                                      <input type="text" DigitsOnly name="From" placeholder="From" class="form-control" formControlName="from">
                                    </div>
                                  </div>
                                  <div class="col-md-4 pr-1" *ngIf="!isCommisionFlag">
                                    <div class="form-group">
                                      <label class="float-start">To : </label>
                                      <input type="text" DigitsOnly name="To" placeholder="To" class="form-control" formControlName="to">
                                    </div>
                                  </div>
                                  <div class="col-md-4 pr-1">
                                    <div class="form-group">
                                      <label class="float-start">Fee Name : </label>
                                      <input type="text" name="feeName" placeholder="fee Name " class="form-control" formControlName="feeName">
                                    </div>
                                  </div>
                                  <div class="col-md-4 pr-1">
                                    <div class="form-group">
                                      <label class="float-start">Max Value: </label>
                                      <input type="text" name="maxValue" placeholder="max Value " class="form-control" formControlName="maxValue">
                                    </div>
                                  </div>
            
                                  <button class="btn btn-primary" (click)="addCommisionFee()">Add</button>
                                  <button class="btn btn-light" (click)="removeCommisionFee(i)">Remove</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
