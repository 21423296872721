import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { GeneralService } from './services/general.service';
import { LocationService } from './services/location.service';
import { HttpClient  } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  ShowMenu: boolean = false;
  title = 'AdminPortal';
  vehicleNo: any;
  constructor(private router: Router,private generalService: GeneralService, private locationService: LocationService,private http:HttpClient) {
    this.locationService.getLocation().subscribe(
      (position) => {
        this.generalService.setUserLatitude(String(position.coords['latitude']));
        this.generalService.setUserLongitude(String(position.coords['longitude']));
      },
      (error: any) => {
        console.log(error); 
      }
    );
   }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes("/login") || event.url == "/" || event.url.includes("/signup") || event.url.includes("/forgotpassword") || event.url.includes("/resetpassword")) {
          this.ShowMenu = false;
        }
        else if (this.generalService.getAccessToken() != '') {
          this.ShowMenu = true;
        }
      }
    });
  }
}
