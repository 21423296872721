<app-mainscreen>
    <div class="box_container">
        <div class="box_header flex-column">
            <label class="box_title_18 border_bottom_grey5 w-100 mb-0 pb-2">Create Distributor</label>
            <div class="row w-100">
                <div class="col-12">
                  <ul class="d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center list-item-container">
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                        <img src="/assets/svgIcon/25_.svg" class="logo" />
                        Step 1 : Role Details
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                        <img src="/assets/svgIcon/50_.svg" class="logo" />
                        Step 2 : Permission Details
                    </li>  
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                        <img src="/assets/svgIcon/100_.svg" class="logo" />
                        Step 3 : Summary
                    </li>            
                  </ul>
                </div>
              </div> 
        </div>       
    </div>
    <div class="box_container mt-3"> 
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <form id="roleForm" class="form" [formGroup]="roleForm" novalidate="novalidate">
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        Role Details
                    </span>                
                    <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2)">
                    </app-custom-button>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>                
                        <div class="row">
                            <div class="col-12 position-relative" [ngClass]="{'mb-2':((roleFormControl['roleName'].touched  || submittedRoleForm) && !roleFormControl['roleName'].valid)}">
                                <input type="text" id="roleName" name="roleName" placeholder=" " class="form-control input_" formControlName="roleName" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((roleFormControl['roleName'].touched  || submittedRoleForm) && roleFormControl['roleName'].errors?.['required'])}">
                                <label for="roleName" class="label_">Role Name</label>
                                <Span class="text-danger error_span" *ngIf="((roleFormControl['roleName'].touched  || submittedRoleForm) && roleFormControl['roleName'].errors?.['required'])">
                                    Role name is required.
                                </Span>
                            </div>
                            <div class="col-12 position-relative" [ngClass]="{'mb-2':((roleFormControl['roleDescription'].touched  || submittedRoleForm) && !roleFormControl['roleDescription'].valid)}">
                                <textarea rows="5" id="roleDescription" name="roleDescription" placeholder=" " class="form-control input_" formControlName="roleDescription" (keypress)="alphaNumericOnly($event)"
                                [ngClass]="{'border border-danger': ((roleFormControl['roleDescription'].touched  || submittedRoleForm) && roleFormControl['roleDescription'].errors?.['required'])}"></textarea>
                                <label for="roleDescription" class="label_ ta5">Role Description</label>
                                <Span class="text-danger error_span" *ngIf="((roleFormControl['roleDescription'].touched  || submittedRoleForm) && roleFormControl['roleDescription'].errors?.['required'])">
                                    Role Description is required.
                                </Span>
                            </div>
                        </div>
                </div>        
            </form>
        </div>
        <div id="permissions" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">            
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        Permission Details
                    </span>
                    <div class="d-flex">
                        <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(1,true)"></app-custom-button> 
                        <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(3)"></app-custom-button>
                    </div>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>  
                    <app-tree-table [selectedIds]="selectedIds" [nodes]="nodes" (onChanged)="onCheckChanged($event)"></app-tree-table>
                </div>  
        </div>
        <div id="sumary" class="tab-pane w-100 pb-3" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
            <div class="box_header pb-0">
                <span class="box_title_18">
                    Summary
                </span>
                <app-custom-button text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="saveNext(1)">                    
                </app-custom-button>
            </div>
            <div class="p-lr-10">
                <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label> 
                <div class="box_container mt-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">1. Representative Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-3 label_13_grey3">
                                    Role Name
                                </div>
                                <div class="col-md-9 label_13_grey2">
                                    {{roleFormControl['roleName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-3 label_13_grey3">
                                    Role Description
                                </div>
                                <div class="col-md-9 label_13_grey2">
                                    {{roleFormControl['roleDescription'].value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
                <div class="box_container mt-4">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">2. Permissions Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-6">
                            <app-tree-table [selectedIds]="selectedIds" [nodes]="nodes" [disableAll]="true"></app-tree-table>
                        </div>
                    </div>
                </div> 
                <app-custom-button className="mt-3" text="Confirm" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveData()"></app-custom-button>           
            </div> 
        </div>
    </div>
    </app-mainscreen>