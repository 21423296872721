<app-mainscreen>
    <app-simple-page-heading [breadcrumbs]="['Dashboard > Sales']" title="Sales" ></app-simple-page-heading>
    <div class="box_container mt-4 pt-2 rounded-bottom1-0 position-relative justify-content-start overflow-initial">
      <div class="box-header mb-4 w-100 pl-2">
          <label class="m-0 box_title_22 pl-1"> Sales </label>
      </div>
      <div class="row w-100 position-relative">
        <div class="col-4 position-relative">
          <select name="" placeholder=" " class="form-control input_" [(ngModel)]="agentId">
            <option value="9999999">Select Agent</option>
            <option *ngFor="let item of agentList" [value]="item.id">{{item.name}}</option>
          </select>
          <label for="" class="label_">Select agent for export</label>
        </div>
        <div class="col-auto tabs_content">
          <app-custom-button text="Export Policies Expiring in next 30 Days" [width]="'max-content'" className="mr-2" [height]="'34px'" leadingIconName="" (onClick)="exportToExcel1()" [disabled]="agentId == 9999999"></app-custom-button>
        </div>    
      </div>
    </div>
    <div class="position-relative">
      <app-table-view
      [visibleColumnData] = "visibleColumnData"
      [visibleColumnsId] = "visibleColumnsId"
      [graphdata]="graphdata"
      [graphHeader]="graphHeader"
      title=""
      tableBackground="var(--grey3)"
      buttonTitle=""
    [showIconButtonEnd]="true"
      [showPaginator]="true"  
      (onActionClick)="onActionClick($event)"
      (onCreateBtnClick)="onCreateBtnClick($event)"
      [canCreate]="false"
      [canView]="canView"
      [canDelete]="false"
      [canEdit]="false"
      [canDowanload]="true"
      className="rounded-top1-0"
    ></app-table-view>
    </div>
  <app-stepper-dialog></app-stepper-dialog>
  </app-mainscreen>