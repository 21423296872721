<app-mainscreen>
    <app-simple-page-heading [breadcrumbs]="['Dashboard > Customer']" title="Customer" ></app-simple-page-heading>
  <div style="height: 30px;"></div>
    <app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title="Customers"
    tableBackground="var(--grey3)"
    [showIconButtonEnd]="true"
    [showPaginator]="true"  
    (onActionClick)="onActionClick($event)"
    [canCreate]="canCreate"
    [canView]="canView"
    [canDelete]="canDelete"
    [canEdit]="canEdit"
  ></app-table-view>
  <app-stepper-dialog></app-stepper-dialog>
  <!-- <div style="height:30px"></div>
  <app-distributor-create></app-distributor-create> -->
  <!-- <app-distributor-view></app-distributor-view> -->
  <!-- <app-distributor-edit></app-distributor-edit> -->
  </app-mainscreen>