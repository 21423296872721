import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from '../services/general.service';
@Injectable()

export class AuthGuard implements CanActivate {
  constructor(private router: Router,private generalService: GeneralService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const Islogin = window.location.pathname.indexOf('login') == -1;
    if (Islogin) {
      if (this.generalService.getAccessToken() == '') {
        this.router.navigate(['/login']);
        return false;
      }
      //if (sessionStorage.getItem("usermenulist") != undefined) {
      //  const flMenu = JSON.parse(sessionStorage.getItem("usermenulist") || '')
      //  const ftCnt = flMenu.filter((f: { menuCommand: string | null | undefined; }) => f.menuCommand != null
      //    && f.menuCommand != undefined
      //    && f.menuCommand != ''
      //    && state.url.indexOf(f.menuCommand) != -1);
      //  if (ftCnt.length == 0) {
      //    this.router.navigate(['/unauthorize']);
      //    return false;
      //  }
      //}
    }
    if (this.generalService.getAccessToken() != '') {
      return true;
    }
    this.router.navigate(['/login']);
    return true;
  }
}
