import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DocumentDetails } from 'src/app/models/Distributor';
import { AdminService } from 'src/app/services/admin.service';
import * as moment from 'moment';
@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.css']
})
export class PolicyDetailsComponent {
  activeTab:number=1;
  vehicleDetailsForm: FormGroup;
  nomineeDetailsForm: FormGroup;
  policyValueForm: FormGroup;
  proposerDetailsForm: FormGroup;
  userDetailsForm:FormGroup;
  documentsUploadForm:DocumentDetails[]=[];
  constructor(private fb: FormBuilder, private adminService: AdminService,private router:Router){
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {
      let id = parseInt(obj['id']);
      let userId = parseInt(obj['userId']??'0')
      this.getFormData(id,userId);
    }

    this.nomineeDetailsForm = this.fb.group({
      "nomineeName": [''],
      "nomineeAge": [''],
      "relationshipWithNominee": [''],
      "gender": ['']
    });

    this.policyValueForm = this.fb.group({
      "policyNumber": [''],
      "policyStatus": [''],
      "policyStatusStr": [''],      
      "premium": [''],
      "kycStatus": [''],
      "startDate": [''],
      "endDate": [''],
      "policyTerm": [''],
      "insuranceCompany": [''],
      "insuranceCompanyLogo": ['']
    });

    this.proposerDetailsForm = this.fb.group({
      "title": [''],
      "firstName": [''],
      "lastName": [''],
      "email": [''],
      "phoneNumber": [''],
      "dateOfBirth": [''],
      "panNumber": [''],
      "gender": [''],
      "flatNo": [''],
      "streetAddress": [''],
      "city": [''],
      "state": [''],
      "pincode": ['']
    });

    this.vehicleDetailsForm = this.fb.group({
      "city": [''],
      "rto": [''],
      "make": [''],
      "model": [''],
      "variant": [''],
      "registrationYear": [''],
      "registrationNumber": [''],
      "yearOfManufacture": [''],
      "chasisNumber": [''],
      "engineNumber": [''],
      "registrationDate": [''],
      "firstName": [''],
      "lastName": [''],
      "isPreviousPolicyHolder": [''],
      "previousPolicyType": [''],
      "claimsMadeInPreviousPolicy": [''],
      "ncbprevious": [''],
      "typeOfCover": [''],
      "previousPolicyExpiryDate": [''],
      "vehicleIDV": [''],
      "previousPolicyNumber": [''],
      "previousPolicyCompany": [''],
      "fuelType": [''],
      "registeredInTheNameOf": [''],
      "engineCapacityAmount": [''],
      "isValidDrivingLicenseAvailable": [''],
      "isFinanced": [''],
      "financierName": [''],
      "isTPPD": [''],
    });

    this.userDetailsForm = this.fb.group({
      "firstName": [''],
      "phoneNumber": [''],
      "email": ['']
    });
  }
  get vehicleDetailsFormControl() {
    return this.vehicleDetailsForm.controls;
  }
  get proposerDetailsFormControl() {
    return this.proposerDetailsForm.controls;
  }
  get policyValueFormControl() {
    return this.policyValueForm.controls;
  }
  get nomineeDetailsFormControl() {
    return this.nomineeDetailsForm.controls;
  }
  get userDetailsFormControl() {
    return this.userDetailsForm.controls;
  }
  ngOnInit(): void {
    
  }

 async  getFormData(id:number,userId:number)
  {
    const res = await this.adminService.PolicyDetailsByPolicyId(id,userId);  
      if (res != null && res.result) {
        this.policyValueForm.patchValue(res.result.policyValue);
        debugger;       
        this.nomineeDetailsForm.patchValue(res.result.nomineeDetails);
        this.proposerDetailsForm.patchValue(res.result.proposerDetails);
        this.vehicleDetailsForm.patchValue(res.result.vehicleDetails);
        this.userDetailsForm.patchValue(res.result.userDetails);
        let policyStatusStr = this.policyValueFormControl['policyStatus'].value == "" ? "NA" : (this.policyValueFormControl['policyStatus'].value == 0 ? 'In Complete' : 
        (this.policyValueFormControl['policyStatus'].value == 1 && moment(new Date()) <= moment(this.policyValueFormControl['endDate'].value) ? 'Active': 'Expired'));
        this.policyValueForm.get('policyStatusStr')?.setValue(policyStatusStr);
      }    
      else
      {
        // swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Something went wrong',
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
      } 
  }
  changeTab(tab:number)
  {
    this.activeTab = tab;
  }

  // backBtClick(){
  //   this.router.navigateByUrl('/reseller-edit',{ state: { id:this.id }});
  // }

  // async onClick() {  
  //   if(this.policyDetails.insuranceCompany == 2 || this.policyDetails.insuranceCompany == 14)  
  //   await this.showPdf();
  // else if(this.policyDetails.insuranceCompany == 1)
  //  await  this.showGoDigitPdf();
  // }
  // async showPdf() {

  //   this.spinner.show();
  //   const response = await this.policyService.GeneratePolicyPDF(this.policyDetails.policyNumber,this.policyDetails.insuranceCompany);
  //   if (response?.isSuccess) {
      
  //     const linkSource =response.result;
  //     const downloadLink = document.createElement('a');
  //     const fileName = 'policy.pdf';
  
  //     downloadLink.href = 'data:application/pdf;base64,' +linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();

  //   }
  //   this.spinner.hide();
  // }

  // async showGoDigitPdf() {

  //   this.spinner.show();
  //   let request = { ApplicationId: this.policyDetails.applicationId, InsuranceCompany: this.policyDetails.insuranceCompany.toString() }
  //   const response = await this.policyService.GetPolicyReceipt(request);
  //   if (response?.isSuccess) {
  //     const linkSource =response.result;
  //     const downloadLink = document.createElement('a');
  //     const fileName = 'policy.pdf';
  
  //     downloadLink.href = 'data:application/pdf;base64,' +linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();

  //   }
  //   this.spinner.hide();
  // }

}
