import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }
  // Get 
  getUserType()
  {
    return sessionStorage.getItem('userType')??localStorage.getItem('userType')??'';
  }

  getUniqueuserid()
  {
    return sessionStorage.getItem('uniqueuserid')??localStorage.getItem('uniqueuserid')??'';
  }

  getAccessToken()
  {
    return sessionStorage.getItem('access_token')??localStorage.getItem('access_token')??'';
  }

  getUserDisplayName()
  {
    return sessionStorage.getItem('user_display_name')??localStorage.getItem('user_display_name')??'';
  }

  getUserLatitude()
  {
    return sessionStorage.getItem('latitude')??localStorage.getItem('latitude')??'';
  }

  getUserLongitude()
  {
    return sessionStorage.getItem('longitude')??localStorage.getItem('longitude')??'';
  }

  // Set
  setUserType(data:any)
  {
    sessionStorage.setItem('userType', data);
    localStorage.setItem('userType',data);
  }

  setUniqueuserid(data:string)
  {
    sessionStorage.setItem('uniqueuserid', data);
    localStorage.setItem('uniqueuserid',data);
  }

  setAccessToken(data:string)
  {
    sessionStorage.setItem('access_token',data);
    localStorage.setItem('access_token',data);
  }

  setUserDisplayName(data:string)
  {
    sessionStorage.setItem('user_display_name',data);
    localStorage.setItem('user_display_name',data);
  }

  setUserLatitude(data:string)
  {
    sessionStorage.setItem('latitude',data);
    localStorage.setItem('latitude',data);
  }

  setUserLongitude(data:string)
  {
    sessionStorage.setItem('longitude',data);
    localStorage.setItem('longitude',data);
  }

  // Clear 
  removeUserType()
  {
    sessionStorage.removeItem('userType');
    localStorage.removeItem('userType');
  }

  removeUniqueuserid()
  {
    sessionStorage.removeItem('uniqueuserid');
    localStorage.removeItem('uniqueuserid');
  }

  removeAccessToken()
  {
    sessionStorage.removeItem('access_token');
    localStorage.removeItem('access_token');
  }

  removeUserDisplayName()
  {
    sessionStorage.removeItem('user_display_name');
    localStorage.removeItem('user_display_name');
    this.removeDataFromLocalStorage('gender');
    this.removeDataFromLocalStorage('displayid');    
  }

  removeUserLatitude()
  {
    sessionStorage.removeItem('latitude');
    localStorage.removeItem('latitude');
  }

  removeUserLongitude()
  {
    sessionStorage.removeItem('longitude');
    localStorage.removeItem('longitude');
  }

  removeAllSession()
  {
    this.removeUserType();
    this.removeAccessToken();
    this.removeUniqueuserid();
    this.removeUserDisplayName();
    
  }

  isValidGSTNo(str:string):boolean {
    // Regex to check valid
    // GST CODE
    let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
 
    // GST CODE
    // is empty return false
    if (str == null) {
        return false;
    }
 
    // Return true if the GST_CODE
    // matched the ReGex
    if (regex.test(str) == true) {
        return true;
    }
    else {
        return false;
    }
}

setDataToLocalStorage(key:string,data:string)
{
  sessionStorage.setItem(key,data);
  localStorage.setItem(key,data);
}

getDataFromLocalStorage(key:string)
{
  return sessionStorage.getItem(key)??localStorage.getItem(key)??'';
}

removeDataFromLocalStorage(key:string)
{
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
}

}
