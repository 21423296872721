import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StepperDialogComponent } from 'src/app/components/stepper-dialog/stepper-dialog.component';
import { Item } from 'src/app/models/SelectItems';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { MenuService } from 'src/app/services/menu.service';
import * as moment from 'moment';
import * as XLSX from "xlsx";
@Component({
  selector: 'app-sms-logs',
  templateUrl: './sms-logs.component.html',
  styleUrls: ['./sms-logs.component.css']
})
export class SmsLogsComponent {
 graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];
  today = new Date();
  selectedStartDate:any = moment(this.today).add(-1, 'days').format('YYYY-MM-DD');
  selectedEndDate:any = moment(this.today).format('YYYY-MM-DD');
  selectedDateRange:string="Past 2 Days";
  phoneNumber:string="";
  constructor(private adminService: AdminService, private router:Router,private menuService:MenuService,private generalService: GeneralService) {
    this.getSMSLogs();  
  }

async getSMSLogs() {
    let obj ={
      startDate :this.selectedStartDate,
      endDate:this.selectedEndDate,
      phoneNuber:this.phoneNumber
    }
    const res = await this.adminService.SMSLogListing(obj);    
    if (res != null && res.result) {
      this.graphdata = res.result.data;
      let index=7;
      let sort:string='asc';
      console.log(this.graphdata.sort( (a, b) => {
        if(sort == 'desc')
        {
          if (a[index]?.toString().toLowerCase() > b[index]?.toString().toLowerCase()) {
            return -1;
          }
          if (b[index]?.toString().toLowerCase() > a[index]?.toString().toLowerCase()) {
              return 1;
          }          
        }
        else
        {
          if (a[index]?.toString().toLowerCase() < b[index]?.toString().toLowerCase()) {
            return -1;
          }
          if (b[index]?.toString().toLowerCase() < a[index]?.toString().toLowerCase()) {
              return 1;
          }  
        }
        return index;
      }));
      this.graphHeader = res.result.header;
      this.visibleColumnData =  res.result.header.map((m:any,index:number)=>{return {id:index?.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
      this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id?.toString()});
    }
  }

  setDateRange(dateRangeText:string){
    this.selectedDateRange = "Past "+ dateRangeText;
    switch(dateRangeText){
      case "2 Days" : 
      this.selectedStartDate = moment(this.today).add(-2, 'days').format();
      this.selectedEndDate = this.today; 
      break;
      case "7 Days" : 
      this.selectedStartDate = moment(this.today).add(-7, 'days').format();
      this.selectedEndDate = this.today;      
      break;
      case "1 Months" : 
      this.selectedStartDate = moment(this.today).add(-1, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "3 Months" : 
      this.selectedStartDate = moment(this.today).add(-3, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "6 Months" : 
      this.selectedStartDate = moment(this.today).add(-6, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "9 Months" : 
      this.selectedStartDate = moment(this.today).add(-9, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "Year" : 
      this.selectedStartDate = moment(this.today).add(-1, 'years').format();
      this.selectedEndDate = this.today;
      this.selectedDateRange = "Last " + dateRangeText;
      break;
    }
    //this.bindDashboardData();
  // moment(new Date()).add(10, 'days').format('DD/MM/YYYY')
  }

  onStartDateChange(event:any){
    this.selectedStartDate = event.value;
  }

  onEndDateChange(event:any){
    this.selectedEndDate = event.value;
  }
  
  async applyFilter(){
    this.getSMSLogs();
  }

  resetFilter(){
    this.selectedStartDate = moment(this.today).add(-1, 'days').format('YYYY-MM-DD');
    this.selectedEndDate = moment(this.today).format('YYYY-MM-DD');
    this.selectedDateRange = "Past 2 Days";

    this.getSMSLogs();
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }
}
