export class ApiResponse {
  public statusCode: any;
  public isSuccess: boolean = false;
  public message: string = '';
  public result: any;
}

export class ApiResponseNew {
  public code: any;
  public subcode: boolean = false;
  public message: string = '';
  public data: any;
}
