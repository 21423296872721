<div class="d-flex flex-column w-100 bg-white align-items-center">
  <img src="/assets/images/logo.svg" class="logo w-50 my-2" />
 
<!-- 
  <div
    style="
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 45px;
    "
    class="px-3"
  >
    <h4 class="text-grey1 m-0 p-0" style="font-weight: bold">Menu</h4>
    <button mat-icon-button>
      <span class="material-symbols-outlined" style="font-size: 16px">
        info
      </span>
    </button>
  </div> -->

  <div class="menu-container">
    <div class="top-menu">
      <ng-container *ngFor="let menuItem of menuList">
        <app-simple-menu-button
          [title]="menuItem.menuTitle"
          [icon_name]="menuItem.menuIcon"
          [url]="menuItem.menuCommand"
          [menuId]="menuItem.menuId">
        </app-simple-menu-button>
      </ng-container>
    </div>

    <div class="bottom-menu border-top" *ngIf="menuList.length > 0">
      <app-simple-menu-button   *ngIf="profileUrl != ''"
        [title]="'Settings'"
        [icon_name]="'settings'"
        [url]="profileUrl">
      </app-simple-menu-button>
      <app-menu-button-single
        [title]="'Logout'"
        [icon_name]="'logout'"
        [color]="'red'">
      </app-menu-button-single>
    </div>
  <!-- <app-simple-menu-button
  [title]="'Dashboard'"
  [icon_name]="'finance'"
  [url]="'/'"
>
</app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Sales'"
      [icon_name]="'finance'"
      [url]="''"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Agents'"
      [icon_name]="'currency_rupee'"
      [url]="'/agents'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Resellers'"
      [icon_name]="'contacts'"
      [url]="'/resellers'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Distributors'"
      [icon_name]="'contacts'"
      [url]="'/distributors'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Leads'"
      [icon_name]="'contacts'"
      [url]="'/beneficiary'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Customers'"
      [icon_name]="'contacts'"
      [url]="'/customers'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Commissions'"
      [icon_name]="'contacts'"
      [url]="'/beneficiary'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Business'"
      [icon_name]="'contacts'"
      [url]="'/beneficiary'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Logs'"
      [icon_name]="'contacts'"
      [url]="'/logs'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Payment Managements'"
      [icon_name]="'contacts'"
      [url]="'/beneficiary'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Insurance Products'"
      [icon_name]="'contacts'"
      [url]="'/beneficiary'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Quotes'"
      [icon_name]="'contacts'"
      [url]="'/beneficiary'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button
      [title]="'Insurance Providers'"
      [icon_name]="'contacts'"
      [url]="'/beneficiary'"
    >
    </app-simple-menu-button>
    <app-simple-menu-button [title]="'Settings'" [icon_name]="'settings'">
    </app-simple-menu-button>
    <div class="mx-4 d-flex mb-3 mt-3" style="align-items: center">
      <span class="material-symbols-outlined m-0 mr-2"> light_mode </span>
      <app-toggle-switch></app-toggle-switch> -->
    <!--   <span class="material-symbols-outlined ml-2"> dark_mode </span>
    </div>
     -->

  </div>
</div>
