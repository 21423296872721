import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AdminService } from 'src/app/services/admin.service';
import {Clipboard} from '@angular/cdk/clipboard';
import swal from 'sweetalert2';
@Component({
  selector: 'app-policy-log-view',
  templateUrl: './policy-log-view.component.html',
  styleUrls: ['./policy-log-view.component.css']
})
export class PolicyLogViewComponent {
  list: any = [];
  obj:any;
  activePanel:number = 1;
  policyLogJourneyList:PolicyLogJourneyModel[]=[];
  showJourney:boolean = false;
  constructor(public modalRef: BsModalRef, private adminService:AdminService, public clipboard: Clipboard){
    //alert(JSON.stringify(this.list));
  }
  closeModal() {
    this.modalRef.hide();
  }

  ngOnInit() {
    this.getPolicyData(this.list[0].id,this.list[0].isFailed == 0);
  }

  async getPolicyData(id:number,isFailed:boolean){
    const res = await this.adminService.PolicyLogsById(id);   
    if (res != null && res.result) {
      this.obj= res.result;
      // this.obj.quoteRequestStr = JSON.stringify(JSON.parse(this.obj.quoteRequestStr),null,2);
      // this.obj.quoteResponseStr = JSON.stringify(JSON.parse(this.obj.quoteResponseStr),null,2);
      // this.obj.policyValueStr = JSON.stringify(JSON.parse(this.obj.policyValueStr),null,2);
      // this.obj.proposerDetailsStr = JSON.stringify(JSON.parse(this.obj.proposerDetailsStr),null,2);
      // this.obj.vehicleDetailsStr = JSON.stringify(JSON.parse(this.obj.vehicleDetailsStr),null,2);
      // this.obj.nomineeDetailsStr = JSON.stringify(JSON.parse(this.obj.nomineeDetailsStr),null,2);  
      this.obj.quoteRequestStr = JSON.stringify(JSON.parse(this.obj.quoteRequestStr),null,4);
      this.obj.quoteResponseStr = JSON.stringify(JSON.parse(this.obj.quoteResponseStr),null,4);
      this.obj.policyValueStr = JSON.stringify(JSON.parse(this.obj.policyValueStr),null,4);
      this.obj.proposerDetailsStr = JSON.stringify(JSON.parse(this.obj.proposerDetailsStr),null,4);
      this.obj.vehicleDetailsStr = JSON.stringify(JSON.parse(this.obj.vehicleDetailsStr),null,4);
      this.obj.nomineeDetailsStr = JSON.stringify(JSON.parse(this.obj.nomineeDetailsStr),null,4);       
    }
  }

  syntaxHighlight(json:any) {
    return json;
    // if(json != null){
    //   json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    //   return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match:any) {
    //       var cls = 'number';
    //       if (/^"/.test(match)) {
    //           if (/:$/.test(match)) {
    //               cls = 'key';
    //           } else {
    //               cls = 'string';
    //           }
    //       } else if (/true|false/.test(match)) {
    //           cls = 'boolean';
    //       } else if (/null/.test(match)) {
    //           cls = 'null';
    //       }
    //       return '<span class="' + cls + '">' + match + '</span>';
    //   });
    // }
}

copyContent(contetn:string){
  switch(contetn){
    case 'REQ': this.clipboard.copy(this.obj.quoteRequestStr); break;
    case 'RES': this.clipboard.copy(this.obj.quoteResponseStr); break;
    case 'POL': this.clipboard.copy(this.obj.policyValueStr); break;
    case 'PRP': this.clipboard.copy(this.obj.proposerDetailsStr); break;
    case 'NOM': this.clipboard.copy(this.obj.nomineeDetailsStr); break;
    case 'VEH': this.clipboard.copy(this.obj.vehicleDetailsStr); break;
  } 
  this.showContentCopyMsg(); 
}

copyDownlaodAllContent(obj1:any,isCopy:boolean = true){
  //let downloadLink1 = document.createElement('a');
  let data = document.createElement('div');
  data.innerHTML += 'API Name         : ' + ((obj1?.apI_Name == null || obj1?.apI_Name == 'null' || obj1?.apI_Name ==undefined || obj1?.apI_Name =='undefined') ? '' : obj1?.apI_Name) + '\n';
  data.innerHTML += 'Insrance Company : ' + this.obj.insuranceCompany + '\n';
  data.innerHTML += 'Insrance Product : ' + this.obj.insuranceProduct + '\n';
  data.innerHTML += 'Reg. Number      : ' + this.obj.registrationNumber + '\n';
  data.innerHTML += 'Status           : ' + obj1.status + '\n';
  data.innerHTML += 'Created At       : ' + obj1.createdAt + '\n';
  data.innerHTML += 'Make             : ' + this.obj.make + '\n';
  data.innerHTML += 'Model            : ' + this.obj.model + '\n';
  data.innerHTML += 'Variant          : ' + this.obj.variant + '\n';
  data.innerHTML += 'Plan Name        : ' + this.obj.planName + '\n';
  data.innerHTML += 'Policy           : ' + this.obj.policy + '\n';
  data.innerHTML += 'Quote Id         : ' + this.obj.quoteId + '\n\n';
  data.innerHTML += 'API URL : ' + obj1.apI_URL + '\n\n';
  data.innerHTML += 'Request Body :\n' + obj1.quoteRequestStr + '\n\n';
  data.innerHTML += 'Response Body :\n' + obj1.quoteResponseStr + '\n\n';
  if(isCopy){
    this.clipboard.copy(data.innerHTML);
    this.showContentCopyMsg();
  }
  else
  {
    this.saveFile("api_logs.txt", "data:attachment/text", data.innerHTML);
  }
}

saveFile (name:any, type:any, data:any) {

  var a = document.createElement('a');
  var url = window.URL.createObjectURL(new Blob([data], {type: type}));
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

showContentCopyMsg(){
  swal.fire({
    position: 'top-end',
    icon: 'success',
    title: 'Content coppied to clipboard',
    showConfirmButton: false,
    timer: 2000,
  });
}

toggleCollapse(panel:number){  
  this.activePanel = this.activePanel == panel ? 0 : panel;
}

async PolicyJourneyLogsByQuoteId(quoteId?:string){
  if(quoteId != null && quoteId != undefined && quoteId != "" && quoteId != "123"){
    this.showJourney = true;
    const res = await this.adminService.PolicyJourneyLogsByQuoteId(quoteId);   
      if (res != null && res.result) {
        this.policyLogJourneyList = res.result.map((m:PolicyLogJourneyModel)=>{return {
          ...m, 
          apI_Request : this.getFormatedJSON(m.apI_Request),
          apI_Response : this.getFormatedJSON(m.apI_Response),
          quoteRequestStr: this.getFormatedJSON(m.apI_Request),
          quoteResponseStr : this.getFormatedJSON(m.apI_Response)}
        });      
      }
  }
}

hideJourney(){
  this.showJourney = false;
}

getFormatedJSON(json?:string){
  let result = json;
  if(json != null &&  json != undefined &&  json != ''){
    try{
      result = JSON.stringify(JSON.parse(json??''),null,4);
    }
    catch(e){
      result = json;
    }     
  }
  else{
    result = '';
  }  
  return result;
}

}

export class PolicyLogJourneyModel
{
  apI_Name?: string;
  apI_URL?: string;
  apI_Request?: string;
  apI_Response?: string;
  insurer?: string;
  product?: string;
  status?: string;
  createdAt?: string;
  order?: number;
}
