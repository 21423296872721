import { Component } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent {
  ngOnInit(): void {
    $(document).ready(() => {
      $('#myCarousel').carousel();
    });
  }
}
