<app-mainscreen>
    <app-simple-page-heading [breadcrumbs]="['Dashboard > Policy Logs']" title="Policy Logs" ></app-simple-page-heading>
  <div style="height: 15px;">    
  </div>
  <div class="box_container mt-4 pt-2 rounded-bottom1-0 position-relative justify-content-start overflow-initial">
    <div class="box-header mb-4 w-100 pl-2">
        <label class="m-0 box_title_22 pl-1"> Policy Logs </label>
    </div>
    <div class="row w-100 position-relative">
              <div class="col-md-4 position-relative">
                <app-multi-dropdown  class="w-100 input_"
                    [items]="insuranceCopanyList" 
                    [showSearch]="true" 
                    [showAll]="false" 
                    [showStatus]="false"
                    [showError]="false" 
                    placeholder="Insurance Company(s)" 
                    className=""
                    fontColor="var(--grey3)"
                    fontSize="15px"
                    id="InsuranceCompany"
                    (itemChange)="onInsuranceCopanySelect($event)">
                </app-multi-dropdown>
                <label class="label_">Insurance Company</label>
              </div>
              <div class="col-md-3 position-relative">
                <app-multi-dropdown  class="w-100 input_"
                    [items]="productList" 
                    [showSearch]="true" 
                    [showAll]="false" 
                    [showStatus]="false"
                    [showError]="false" 
                    placeholder="Insurance Product(s)" 
                    className=""
                    fontColor="var(--grey3)"
                    fontSize="15px"
                    id="InsuranceProduct"
                    (itemChange)="onInsuranceProductSelect($event)">
                </app-multi-dropdown>
                <label class="label_">Insurance Product</label>
              </div>
            <div class="col-md-5 tabs_content">
              <div class="filter_div p-0 pull-left">
                <ul class="d-flex align-items-center justify-content-between">
                    <li>
                        <img src="../../../../assets/images/calender.png" />
                    </li>
                    <li class="px-1">
                        <div class="dropdown">
                            <button class="dropbtn filter_btn">{{selectedDateRange}}</button>
                            <div class="dropdown-content">
                            <a [routerLink]="" (click)="setDateRange('2 Days')">Past 2 Days</a>
                            <a [routerLink]="" (click)="setDateRange('7 Days')">Past 7 Days</a>
                            <a [routerLink]="" (click)="setDateRange('1 Months')">Past 1 Months</a>
                            <a [routerLink]="" (click)="setDateRange('3 Months')">Past 3 Months</a>
                            <a [routerLink]="" (click)="setDateRange('6 Months')">Past 6 Months</a>
                            <a [routerLink]="" (click)="setDateRange('9 Months')">Past 9 Months</a>
                            <a [routerLink]="" (click)="setDateRange('Year')">Last Year</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <mat-form-field class="example-form-field w-auto" appearance="fill">
                            <mat-date-range-input [rangePicker]="rangePicker"  >
                                <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" appDateFormat (dateChange)="onStartDateChange($event)">
                                <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" appDateFormat (dateChange)="onEndDateChange($event)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker>
                                <mat-date-range-picker-actions>
                                    <!-- <button mat-button matDateRangePickerCancel (click)="resetDateRange()">Cancel</button> -->
                                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                            <mat-date-range-picker #rangePicker></mat-date-range-picker>
                        </mat-form-field>
                    </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 position-relative">
              <app-multi-dropdown  class="w-100 input_"
                  [items]="apiNameList" 
                  [showSearch]="true" 
                  [showAll]="false" 
                  [showStatus]="false"
                  [showError]="false" 
                  placeholder="API Name(s)" 
                  className=""
                  fontColor="var(--grey3)"
                  fontSize="15px"
                  id="APINames"
                  (itemChange)="onApiNameSelect($event)">
              </app-multi-dropdown>
              <label class="label_">Api Name</label>
            </div>
            <div class="col-md-3 position-relative">
              <app-multi-dropdown  class="w-100 input_"
                  [items]="statusList" 
                  [showSearch]="true" 
                  [showAll]="false" 
                  [showStatus]="false"
                  [showError]="false" 
                  placeholder="Status(s)" 
                  className=""
                  fontColor="var(--grey3)"
                  fontSize="15px"
                  id="Status"
                  (itemChange)="onStatusSelect($event)">
              </app-multi-dropdown>
              <label class="label_">Status</label>
            </div>
            <div class="col-md-3">
              <app-custom-button text="Filter" [width]="'auto'" [height]="'34px'" leadingIconName="" (onClick)="filterPolicyLogs()">                    
              </app-custom-button>
              <app-custom-button className="btn_outline ml-2" text="Reset Filter" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="resetFilter()"></app-custom-button> 
          </div>
    </div>
  </div>
  <div class="position-relative">
    <app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title=""
    tableBackground="var(--grey3)"
    buttonTitle=""
    [showIconButtonEnd]="true"
    [showPaginator]="true"  
    (onActionClick)="onActionClick($event)"
    [canCreate]="false"
    [canView]="canView"
    [canDelete]="false"
    [canEdit]="false"
    className="rounded-top1-0"
    ></app-table-view>
  </div>
    
  <app-stepper-dialog></app-stepper-dialog>
  </app-mainscreen>