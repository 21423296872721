import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Item } from 'src/app/models/SelectItems';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { MenuService } from 'src/app/services/menu.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PolicyLogViewComponent } from '../policy-log-view/policy-log-view.component';
import { LookUpService } from 'src/app/services/lookup.service';
import * as moment from 'moment';
@Component({
  selector: 'app-policy-log-listing',
  templateUrl: './policy-log-listing.component.html',
  styleUrls: ['./policy-log-listing.component.css']
})
export class PolicyLogListingComponent {
  graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];
  canView:boolean = false;
  canEdit:boolean = false;
  canDelete:boolean = false;
  canCreate:boolean = false;
  viewUrl:string="";
  editUrl:string="";
  createUrl:string="";
  deleteUrl:string="";
  distributorList:Item[]=[];
  userType:string='3';
  distributorId:number = 0;
  modalRef?: BsModalRef ;
  insuranceCopanyList:Item[]=[];
  insuranceCopanyListTemp:Item[]=[];
  productList:Item[]=[];
  statusList:Item[]=[];
  apiNameList:Item[]=[];
  selectedInsuranceCopanyIds:string="";
  selectedProductIds:string="";
  selectedStatus:string ="Failed";
  selectedAPIName:string="GetQuote";
  today = new Date();
  selectedStartDate:any = moment(this.today).add(-1, 'days').format('YYYY-MM-DD');
  selectedEndDate:any = moment(this.today).format('YYYY-MM-DD');
  selectedDateRange:string="Past 2 Days";
  constructor(private adminService: AdminService, private router:Router,private menuService:MenuService,private generalService: GeneralService,
    private modalService: BsModalService,private _lookupService :LookUpService) {
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {      
      this.getPermissions(parseInt(obj['id'])??0);
    }
    
    this.userType = generalService.getUserType();
    this.getPolicyCompIncomDetails();

    this.loadInsurancePartners();
    this.loadProductList();
    this.loadApiNameList();
    this.loadStatusList();
  }

async getPolicyCompIncomDetails() {
  let obj ={
    "from": moment(this.selectedStartDate).format('YYYY-MM-DD'),
    "to": moment(this.selectedEndDate).format('YYYY-MM-DD'),
    "apI_Name": this.selectedAPIName,
    "status": this.selectedStatus,
    "userId": 0,
    "productIds": this.selectedProductIds,
    "insuranceCopanyIds": this.selectedInsuranceCopanyIds,
    "systemCompanyId": 1
  };
  debugger;
    const res = await this.adminService.PolicyLogListing(obj);    
    if (res != null && res.result) {
      this.graphdata = res.result.data;
      let index=13;
      let sort:string='desc';
      console.log(this.graphdata.sort( (a, b) => {
        if(sort == 'desc')
        {
          if (a[index].toString().toLowerCase() > b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() > a[index].toString().toLowerCase()) {
              return 1;
          }          
        }
        else
        {
          if (a[index].toString().toLowerCase() < b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() < a[index].toString().toLowerCase()) {
              return 1;
          }  
        }
        return index;
      }));
      this.graphHeader = res.result.header;
      this.visibleColumnData =  res.result.header.map((m:any,index:number)=>{return {id:index.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
      this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id.toString()});
    }
  }

  onActionClick(data:any)
  {
    if(data)
    {
      if(data.action=="View")
      {
        //this.router.navigateByUrl(this.viewUrl,{ state: { id:data.id }});
        //alert(data.id + ' -- '+ data.id1);


        // const initialState: any = {
        //   list: [
        //     {
        //       "quote": quote,
        //       "vehicleData": this.vehicleData,
        //       "IsMockup": IsMockup,
        //       "proposerDetails" : this.proposerDetails
        //     }
        //   ]
        // };
        const initialState: any = {
          list: [
            {
              "id": data.id,
              "isFailed": data.id1
            }
          ]
        };
        let config = {
          animated: false,
          initialState : initialState
        };
        // localStorage.setItem('vehicleData', this.vehicleData);
        // localStorage.setItem('IsMockup', false);
        debugger;
        this.modalRef = this.modalService.show(PolicyLogViewComponent,config);
        // this.modalRef.content.action.subscribe(async (data: any) => {
        //   debugger;
        //   if (data) {
           
        //   }
        // });
      }
    }
  }

  async getPermissions(menuId:number)
  {
    let obj = {uniqueUserId:this.generalService.getUniqueuserid(),menuId:menuId};
    let res = await this.menuService.GetMenuPermissionsByUserId(obj);
    if(res && res.isSuccess)
    {
      if(res.result.userMenus.length > 0)
      {
        res.result.userMenus.forEach((element:any) => {
          if(element.permissionName.toLowerCase() =="view")
          {
            this.canView = true;
            this.viewUrl = element.menuCommand;
          }
        });
      }      
    }
  }

  async loadInsurancePartners()
  {
    if(this.insuranceCopanyListTemp.length == 0){
      let response = await this._lookupService.GetInsurancePartnersList();
      if (response?.isSuccess) {
        this.insuranceCopanyList = response.result.map((m:any)=> {return {name:m.insuranceName,id:m.insuranceId,checked:false}});
        this.insuranceCopanyListTemp = response.result.map((m:any)=> {return {name:m.insuranceName,id:m.insuranceId,checked:false}});
      }
    }
    else
    {
      this.insuranceCopanyList = JSON.parse(JSON.stringify(this.insuranceCopanyListTemp));
    }
  }

  onInsuranceCopanySelect(data:Item[]){
    this.selectedInsuranceCopanyIds = data.map((m:Item)=>{return m.id}).toString();
  }

  async loadProductList(){
    this.productList = [{name:"2 Wheeler", id:"2", checked:false},{name:"4 Wheeler", id:"1", checked:false},{name:"Commercial Vehicle", id:"3", checked:false},{name:"Health", id:"4", checked:false}];
  }

  onInsuranceProductSelect(data:Item[]){
    this.selectedProductIds = data.map((m:Item)=>{return m.id}).toString();
  }

  async loadApiNameList(){
    this.apiNameList =[{name:"Get Quote", id:"GetQuote", checked:true},{name:"Create Quote", id:"CreateQuote", checked:false},{name:"Kyc", id:"GetKyc", checked:false},{name:"Get Payment Link", id:"GetPaymentLink", checked:false},{name:"Payment Response", id:"PaymentResponse", checked:false},{name:"Policy Pdf", id:"GetPolicyReceiptPdf", checked:false}];
  }

  onApiNameSelect(data:Item[]){
    this.selectedAPIName = data.map((m:Item)=>{return m.id}).toString();
  }

  async loadStatusList(){
    this.statusList =[{name:"Excpetion", id:"Excpetion", checked:false}, {name:"Failed", id:"Failed", checked:true}, {name:"Success", id:"Success", checked:false}];
  }

  onStatusSelect(data:Item[]){
    this.selectedStatus = data.map((m:Item)=>{return m.id}).toString();
  }

  setDateRange(dateRangeText:string){
    this.selectedDateRange = "Past "+ dateRangeText;
    switch(dateRangeText){
      case "2 Days" : 
      this.selectedStartDate = moment(this.today).add(-2, 'days').format();
      this.selectedEndDate = this.today; 
      break;
      case "7 Days" : 
      this.selectedStartDate = moment(this.today).add(-7, 'days').format();
      this.selectedEndDate = this.today;      
      break;
      case "1 Months" : 
      this.selectedStartDate = moment(this.today).add(-1, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "3 Months" : 
      this.selectedStartDate = moment(this.today).add(-3, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "6 Months" : 
      this.selectedStartDate = moment(this.today).add(-6, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "9 Months" : 
      this.selectedStartDate = moment(this.today).add(-9, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "Year" : 
      this.selectedStartDate = moment(this.today).add(-1, 'years').format();
      this.selectedEndDate = this.today;
      this.selectedDateRange = "Last " + dateRangeText;
      break;
    }
    //this.bindDashboardData();
  // moment(new Date()).add(10, 'days').format('DD/MM/YYYY')
  }

  onStartDateChange(event:any){
    this.selectedStartDate = event.value;
  }

  onEndDateChange(event:any){
    this.selectedEndDate = event.value;
  }
  
  async filterPolicyLogs(){
    this.getPolicyCompIncomDetails();
  }

  resetFilter(){
    this.selectedInsuranceCopanyIds = "";
    this.selectedProductIds = "";
    this.selectedStatus = "Failed";
    this.selectedAPIName = "GetQuote";
    this.selectedStartDate = moment(this.today).add(-1, 'days').format('YYYY-MM-DD');
    this.selectedEndDate = moment(this.today).format('YYYY-MM-DD');
    this.selectedDateRange = "Past 2 Days";

    this.loadInsurancePartners();
    this.loadProductList();
    this.loadApiNameList();
    this.loadStatusList();
    this.getPolicyCompIncomDetails();
  }
}
