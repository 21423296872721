<app-mainscreen>
    <div class="view_box_container">
        <div class="">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="view_div">
                        <div class="row align-items-center justify-content-start">
                            <div class="w-auto">
                                <div class="view_block">
                                    <ul>
                                        <li>
                                            <img class="img-fluid w-auto" title="{{insuranceFormControl['insuranceName'].value}}" alt="{{insuranceFormControl['insuranceName'].value}}" src="/assets/images/insurance_companies/{{insuranceFormControl['insuranceId'].value}}.png">
                                            <p class="mb-0">{{insuranceFormControl['insuranceName'].value}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="w-auto">
                                <div class="view_block">
                                    <label class="label_13_grey3 d-inline-flex align-items-center"><span class="material-symbols-outlined font-18 "> corporate_fare </span>&nbsp; Provider Status : </label> <span class="status"> {{insuranceFormControl['insuranceStatus'].value == true ? 'Active' : 'Inactive'}}</span> <br>
                                    <label class="label_13_grey3 d-inline-flex align-items-center"><span class="material-symbols-outlined font-18"> calendar_today </span> &nbsp; Integration Date : </label> <span class="label_13_grey2">{{insuranceFormControl['createdDate'].value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                </div>
            </div>
        </div>
    </div>
    <div class="box_container mt-3"> 
        <div class="row w-100">
            <div class="col-md-4 border-right">
                <form id="kt_account_profile_details_form" class="form" [formGroup]="insuranceForm" novalidate="novalidate">
                    <div class="box_header pb-0">
                        <span class="box_title_18">
                            Provider Details
                        </span>           
                    </div>
                    <div class="p-lr-10">
                        <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>                
                        <div class="row">
                            <div class="col-12 position-relative" [ngClass]="{'mb-2':((insuranceFormControl['contactNumber'].touched  || submittedInsForm) && !insuranceFormControl['contactNumber'].valid)}">
                                <input type="text" id="contactNumber" name="contactNumber" placeholder=" " class="form-control input_ tp" formControlName="contactNumber" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((insuranceFormControl['contactNumber'].touched  || submittedInsForm) && insuranceFormControl['contactNumber'].errors?.['required'])}"  [readOnly]="true">
                                <label for="contactNumber" class="label_">Contact Number</label>
                                <Span class="text-danger error_span" *ngIf="((insuranceFormControl['contactNumber'].touched  || submittedInsForm) && insuranceFormControl['contactNumber'].errors?.['required'])">
                                    Contact number is required.
                                </Span>
                            </div>
                            <div class="col-12 position-relative" [ngClass]="{'mb-2':((insuranceFormControl['email'].touched  || submittedInsForm) && !insuranceFormControl['email'].valid)}">
                                <input type="text" id="email" name="email" placeholder=" " class="form-control input_ tp" formControlName="email" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((insuranceFormControl['email'].touched  || submittedInsForm) && insuranceFormControl['email'].errors?.['required'])}"  [readOnly]="true">
                                <label for="email" class="label_">Email Id</label>
                                <Span class="text-danger error_span" *ngIf="((insuranceFormControl['email'].touched  || submittedInsForm) && insuranceFormControl['email'].errors?.['required'])">
                                    Email id is required.
                                </Span>
                            </div>
                            <div class="col-12 position-relative" [ngClass]="{'mb-2':((insuranceFormControl['whatsAppNumber'].touched  || submittedInsForm) && !insuranceFormControl['whatsAppNumber'].valid)}">
                                <input type="text" id="whatsAppNumber" name="whatsAppNumber" placeholder=" " class="form-control input_ tp" formControlName="whatsAppNumber" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((insuranceFormControl['whatsAppNumber'].touched  || submittedInsForm) && insuranceFormControl['whatsAppNumber'].errors?.['required'])}"  [readOnly]="true">
                                <label for="whatsAppNumber" class="label_">whatsApp Number</label>
                                <Span class="text-danger error_span" *ngIf="((insuranceFormControl['whatsAppNumber'].touched  || submittedInsForm) && insuranceFormControl['whatsAppNumber'].errors?.['required'])">
                                    whatsApp number is required.
                                </Span>
                            </div>
                            <div class="col-12 position-relative" [ngClass]="{'mb-2':((insuranceFormControl['address'].touched  || submittedInsForm) && !insuranceFormControl['address'].valid)}">
                                <textarea id="address"  rows="5" name="address" placeholder=" " class="form-control input_ tp" formControlName="address" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((insuranceFormControl['address'].touched  || submittedInsForm) && insuranceFormControl['address'].errors?.['required'])}" [readOnly]="true"></textarea>
                                <label for="address" class="label_ ta5">Address</label>
                                <Span class="text-danger error_span" *ngIf="((insuranceFormControl['address'].touched  || submittedInsForm) && insuranceFormControl['address'].errors?.['required'])">
                                    Address number is required.
                                </Span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-8">
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        Provider Status
                    </span>           
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label> 
                    <div class="row">
                        <ng-container *ngFor="let product of insuranceStatus">
                            <div class="col-3 mb-2" >
                                <div class="card mh-102 bg-image" style="background-image:url('/assets/images/insurance_product/{{product.productId}}.png')">
                                    <app-toggle-switch [isDisabled]="true" [switchon]="product.status??false" class="toggle-switch"></app-toggle-switch>
                                    <div class="span mt-auto pl-3 pb-2">{{product.productName}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>  
                </div>  
            </div>
        </div>
    </div>
    </app-mainscreen>