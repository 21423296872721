<div class="overlay">
    <div class="modalPopup popup2 col-12">
      <div class="modal-header bg-transparent tooltip_">
        <div class="modal-title d-flex align-items-end">
            <h4 class="mb-0 h5">Details </h4>
            <span class="cursor-pointer text-primary font-13 ml-2" (click)="PolicyJourneyLogsByQuoteId(obj?.quoteId)" *ngIf="obj != null && obj != undefined && obj.quoteId != null && obj.quoteId != undefined && obj.quoteId != '' && obj.quoteId != '123' && !showJourney">Show Policy Journey</span>
            <span class="cursor-pointer text-primary font-13 ml-2" (click)="hideJourney()" *ngIf="showJourney">Hide Policy Journey</span>
            <span (click)="copyDownlaodAllContent(obj,true)" class="cursor-pointer d-flex text-grey3" *ngIf="!showJourney"><span class="material-symbols-outlined font-18 cursor-pointer ml-1 pt-1" title="Copy">content_copy</span> Logs</span> 
            <span (click)="copyDownlaodAllContent(obj,false)" class="cursor-pointer d-flex text-grey3" *ngIf="!showJourney"><span class="material-symbols-outlined font-18 cursor-pointer ml-1 pt-1" title="Download"> download </span> Logs</span>           
          </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true"></span>
       </button>
      </div>
      <div class="modal-body pt-0">
        <div class="row border-bottom py-2">
            <div class="col-md-2 label_13_grey3">
                Insrance Company
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.insuranceCompany}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Insrance Product
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.insuranceProduct}}
            </div>            
            <div class="col-md-2 label_13_grey3">
                Fullname
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.fullname}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Reg. Number
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.registrationNumber}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Status
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.status}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Created At
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.createdAt}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Make
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.make}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Model
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.model}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Variant
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.variant}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Plan Name
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.planName}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Policy
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.policy}}
            </div>
            <div class="col-md-2 label_13_grey3">
                Quote Id
            </div>
            <div class="col-md-4 label_13_grey2">
                {{obj.quoteId}}
            </div>
        </div>
        <ng-container *ngIf="policyLogJourneyList.length > 0 && showJourney">
            <div class="summary-view mt-3">
                <div class="col-12 px-0 shadow_ rounded1 card">
                    <ng-container *ngFor="let item of policyLogJourneyList">
                        <div class="collapsible-panel rounded-top1">
                            <div class="panel-header rounded-top1" (click)="toggleCollapse(item.order??0)">
                                <span class="d-flex">
                                    <span class="d-flex align-items-center {{item.status =='Success' ? 'text-success' : 'text-danger'}}">{{item.apI_Name}} ({{item.status}}) : {{item.createdAt}}</span>                                     
                                    <span (click)="copyDownlaodAllContent(obj,true)" class="cursor-pointer d-flex text-grey3"><span class="material-symbols-outlined font-18 cursor-pointer ml-1 pt-1" title="Copy">content_copy</span> Logs</span> 
                                    <span (click)="copyDownlaodAllContent(obj,false)" class="cursor-pointer d-flex text-grey3"><span class="material-symbols-outlined font-18 cursor-pointer ml-1 pt-1" title="Download"> download </span> Logs</span>
                                </span>
                            <span class="collapse-icon"> <img src="../../assets/svgIcon/{{ item.order??0 == this.activePanel ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                            </div>
                            <div class="panel-content collapsed" [ngClass]="{ 'expanded': item.order == this.activePanel}">
                                <div class="row">
                                    <div class="col-12 py-1 border-bottom d-flex align-items-center">
                                        <span class="label_13_grey3">API Url: </span>
                                        <span class="label_13_grey2"> {{item.apI_URL}} </span>
                                        <span class="material-symbols-outlined font-18 cursor-pointer ml-1" *ngIf="item.apI_URL" title="Copy" (click)="this.clipboard.copy(this.item.apI_URL);showContentCopyMsg();">content_copy</span>
                                    </div>
                                    <div class="col-6 border-right">
                                        <span class="d-flex border-bottom py-2 justify-content-between align-items-center">
                                            <span class="fw-bold">Request </span>
                                            <span class="material-symbols-outlined font-18 cursor-pointer" title="Copy" (click)="this.clipboard.copy(this.item.apI_Request??'');showContentCopyMsg();">content_copy</span>
                                        </span>
                                        <pre class="min-h-40vh">{{item.apI_Request}}</pre> 
                                    </div>
                                    <div class="col-6">
                                        <span class="d-flex border-bottom py-2 justify-content-between align-items-center">
                                            <span class="fw-bold">Response </span>
                                            <span class="material-symbols-outlined font-18 cursor-pointer" title="Copy"  (click)="this.clipboard.copy(this.item.apI_Response??'');showContentCopyMsg();">content_copy</span>
                                        </span>
                                        <pre class="min-h-40vh">{{item.apI_Response}}</pre> 
                                    </div>                                    
                                </div>
                            </div>
                        </div> 
                    </ng-container> 
                </div>
            </div>
        </ng-container>
        <div class="row border-top" [ngClass]="{'mt-2' : policyLogJourneyList.length > 0 && showJourney, 'd-none' : showJourney}">
            <div class="col-12 py-1 border-bottom d-flex align-items-center">
                <span class="label_13_grey3">API Url: </span>
                <span class="label_13_grey2"> {{obj.apI_URL}} </span>
                <span class="material-symbols-outlined font-18 cursor-pointer ml-1" *ngIf="obj.apI_URL" title="Copy" (click)="this.clipboard.copy(obj.apI_URL);showContentCopyMsg();">content_copy</span>
            </div>
            <div class="col-md-6 border-right pt-2">
                <span class="d-flex border-bottom pb-2 justify-content-between align-items-center">
                    <span class="fw-bold">Request </span>
                    <span class="material-symbols-outlined font-18 cursor-pointer" title="Copy" (click)="copyContent('REQ')">content_copy</span>
                </span>
                <pre class="min-h-60vh">{{obj.quoteRequestStr}}</pre> 
            </div>
            <div class="col-md-6 border-right pt-2">
                <span class="d-flex border-bottom pb-2 justify-content-between align-items-center">
                    <span class="fw-bold">Response </span>
                    <span class="material-symbols-outlined font-18 cursor-pointer" title="Copy" (click)="copyContent('RES')">content_copy</span>
                </span>
                <pre class="min-h-60vh">{{obj.quoteResponseStr}}</pre> 
            </div>
            <!-- <div class="col-md-4">
                <span class="d-flex border-bottom pb-2 justify-content-between align-items-center">
                    <span class="fw-bold">Policy Details </span>
                    <span class="material-symbols-outlined font-18 cursor-pointer" title="Copy" (click)="copyContent('POL')">content_copy</span>
                </span>
                <pre class="min-h-60vh">{{obj.policyValueStr}}</pre> 
            </div> -->
            <!-- <div class="col-md-2 border-right">
                <span class="d-block border-bottom pb-2 fw-bold">Proposer Details</span>
                <pre class="min-h-60vh">{{obj.proposerDetailsStr}}</pre> 
            </div>
            <div class="col-md-2 border-right">
                <span class="d-block border-bottom pb-2 fw-bold">Vehicle Details</span>
                <pre class="min-h-60vh">{{obj.vehicleDetailsStr}}</pre> 
            </div>
            <div class="col-md-2 border-right">
                <span class="d-block border-bottom pb-2 fw-bold">Nominee Details</span>
                <pre class="min-h-60vh" >{{obj.nomineeDetailsStr}}</pre> 
            </div> -->
        </div>
      </div>
    </div>
</div>