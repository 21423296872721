export class LoginModel{
    public  uniqueUserId :any;
    public  firstName :any;
    public  lastName  :any;
    public  phoneNumber :any;
    public  userEmail :any;
    public  userName :any;
    public  userHash :any;
    public  isLocked :any;
    public  isActive :any;
    public  userStatus :any;
    public  message :any;
}

export enum PasswordType {
  pwd = "password",
  txt = "text",
}
