import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'src/app/models/MenuData';

@Component({
  selector: 'app-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.css']
})
export class TreeTableComponent {
  
  selectedNodes: TreeNode[] = [];
  _nodes: TreeNode[]=[];
  @Input() disableAll:boolean=false;
  @Input() selectedIds: number[] = [];
  @Input('nodes')
  set nodes(nodes: TreeNode[]) {
      this._nodes = nodes;
  }
  @Output() onChanged: EventEmitter<any> = new EventEmitter<any>();
  onChange(event:any,currentNode:TreeNode) {
    //sub.checked = event.target.checked;
    let id = parseInt(event.target.value);
    //debugger;
    let isChecked =event.target.checked;
    if(isChecked)
    {
      if(this.selectedIds.indexOf(id) == -1)
      {
        this.selectedIds.push(id); 
      }           
    }
    else if(this.selectedIds.indexOf(id) != -1 )
    {
      let tepIds:number[]=[];
      currentNode.children
      .forEach(
          function iter(a) {
            tepIds.push(a.menuId)
            Array.isArray(a.children) && a.children.forEach(iter);
          }
      );
        tepIds.push(id);
        this.selectedIds = this.selectedIds.filter((f:number)=> {return !tepIds.includes(f)});
    }
    
    let ids = this.selectedIds;
    this._nodes
    .forEach(
        function iter(a) {
          a.collapsed = (ids.length > 0 && ids.includes(a.menuId) ? false : true);
          a.isSelected = (ids.length > 0 && ids.includes(a.menuId) ? true : false);
          Array.isArray(a.children) && a.children.forEach(iter);
        }
    );
    // console.log(ids);
    // console.log(this._nodes);
    this.onChanged.emit(ids);
    }

  constructor(private elRef: ElementRef) {}
  ngOnInit(): void {    
  }

  getSelectedIds()
  {
    return this.selectedIds;
  }
}