<app-mainscreen>    
    <div class="tab_div">
        <ul class="align-items-center p-3 list-item-container-view ">
            <li class="list-item" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                Role Details
            </li> 
            <li class="list-item" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                Logs
            </li>        
          </ul>
    </div>
    <div class="tab_content">
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <div class="row">
                <div class="col-md-6">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Roles Information</h4></li>
                                    <li><p class="label_13_grey3 label_controls">Name</p><span class="label_13_grey2">{{roleFormControl['roleName'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Description</p><span class="label_13_grey2">{{roleFormControl['roleDescription'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Permission Details</h4></li>
                                </ul>
                            </div>
                            <div class="col-md-12 pt-3">
                                <app-tree-table [selectedIds]="selectedIds" [nodes]="nodes" [disableAll]="true"></app-tree-table>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div id="logs" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="tab_box_container">
                                
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                            <div class="tab_box_container">
                                <!-- <ul>
                                    <li><h4>Other Information</h4></li>
                                    <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Date of Birth</span><br>05/05/1993</li>
                                    <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Gender</span><br>Male</li>
                                    <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Contact Number</span><br>9705224263</li>
                                    <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Email ID</span><br>xyz@gmail.com</li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </app-mainscreen>