import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DistributorDetails, DocumentDetails } from 'src/app/models/Distributor';
import { Item } from 'src/app/models/SelectItems';
import { AutoCompleteItem } from 'src/app/models/item';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { LookUpService } from 'src/app/services/lookup.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-agent-commission-create',
  templateUrl: './agent-commission-create.component.html',
  styleUrls: ['./agent-commission-create.component.css']
})
export class AgentCommissionCreateComponent {
  activeTab:number=1;
  insurancePartnersList:any=[];
  productsList:any=[{text:"Scooter/Moped",value:0,url:""},{text:"Motor Cycle",value:2,url:""},{text:"Pvt Car",value:1,url:""},{text:"Commercial Vehicle",value:3,url:""},{text:"Health Individual",value:4,url:""},{text:"Health Family",value:5,url:""},{text:"Travel",value:6,url:""},{text:"Home",value:7,url:""},{text:"Office",value:8,url:""},{text:"Shop",value:9,url:""}];
  submittedInsurerForm:boolean=false;
  insurerId:number=0;
  submittedProductForm:boolean=false;
  productId:number=0;
  rtoStateList:Item[]=[];
  rtoCityList:Item[]=[];
  CommVehicleCategoryList:Item[]=[{name:"PCV",id:"PCV"},{name:"GCV",id:"GCV"},{name:"Miscellaneous",id:"MISC"}];
  CommVehiclePCVehicleList:Item[]=[{name:"Auto Rikshaw",id:"AUTO_RIKSHAW"},{name:"Bus",id:"Bus"},{name:"E-Rikshaw",id:"E_RIKSHAW"},{name:"Taxi/Cab",id:"TAXI"}];
  CommVehicleGCVehicleList:Item[]=[{name:"Auto",id:"AUTO"},{name:"Bulker",id:"BULKER"},{name:"Chicken Carrier",id:"CHICKEN_CARRIER"},{name:"Courier Delivery Van",id:"Delivery Van"},{name:"Dumper",id:"Dumper"},{name:"Garbage Carrier",id:"GARBAGE VAN"},{name:"Goods Auto",id:"GOODS_AUTO"},{name:"Pickup Van",id:"Pick Up Van"},{name:"Refrigerated Van",id:"Refrigerated Van"},{name:"Tanker",id:"Tanker"},{name:"Tipper",id:"Tipper"},{name:"Tractor",id:"Tractors"},{name:"Trailor",id:"Trailer"},{name:"Truck",id:"Truck"}];
  CommVehicleMISCVehicleList:Item[]=[{name:"Agriculture Equipment",id:"AGRICULTURE EQUIPMENT"},{name:"Agriculture Tractor",id:"Tractors"},{name:"Ambulance",id:"AMBULANCE"},{name:"Construction Equipment",id:"Construction Equipment"},{name:"Fire Brigade",id:"FIRE FIGHTER"},{name:"Garbage Van",id:"GARBAGE VAN"}];

  CommVehiclePassCapList:Item[]=[{name:"4 seater- 6 seater",id:"4-6"},{name:"7 seater - 10 seater",id:"7-10"},{name:"11 seater - 18 seater",id:"11-18"},{name:"19 seater-24 seater",id:"19-24"},{name:"More than 24 seater",id:"25"}];
  CommVehicleWeightList:Item[]=[{name:"0 Ton - 2 Ton",id:"0-2"},{name:"2 Ton - 2.5 Ton",id:"2-2.5"},{name:"2.5 Ton - 3.5 Ton",id:"2.5-3.5"},{name:"3.5 Ton - 12 Ton",id:"3.5-12"},{name:"12 Ton - 20 Ton",id:"12-20"},{name:"20 Ton - 40 Ton",id:"20-40"},{name:"More than 40 Ton",id:"41"}];
  
  selectedRTOStates:string="";
  selectedRTOCities:Item[]=[];
  selectedMakes:string="";
  selectedModels:Item[]=[];
  selectedVariant:Item[]=[];
  selectedFuel:string="";
  selectedCC:string="";
  selectedAge:string="0";
  
  selectedMonth:number=0;
  selectedVehicleType:string="";
  selectedPolicyType:string="";
  selectedCommVehicleCategory:string="PCV";
  selectedCommVehiclePassCap:string="4-6";
  selectedCommVehicleWeight:string="0-2";
  selectedCommVehiclePCVehicle:string ="AUTO_RIKSHAW";
  selectedCommVehicleGCVehicle:string ="AUTO";
  selectedCommVehicleMISCVehicle:string ="AGRICULTURE_EQUIPMENT";
  todayDate = new Date();
  currentYear = this.todayDate.getFullYear();
  selectedYear:number=this.currentYear;
  policyTypeList :Item[] =[];
  vehicleTypeList :Item[] =[];
  makeList :Item[] =[];
  modelList:Item[]=[];
  VariantList:Item[]=[];
  fuelTypeList:Item[]=[];
  ccList:Item[]=[];
  ageList:Item[]=[];
  yearList:Item[]=[];
  monthNameArray:string[]=["January","February","March","April","May","June","July","August","September","October","November","December"];
  monthList:Item[]=[{id:"1",name:"January"}, {id:"1",name:"February"}, {id:"1",name:"March"}, {id:"1",name:"April"}, {id:"1",name:"May"},
  {id:"1",name:"June"}, {id:"1",name:"July"}, {id:"1",name:"August"}, {id:"1",name:"September"}, {id:"1",name:"October"}, {id:"1",name:"November"}, {id:"1",name:"December"}];
  requestObjet: CommissionMappingModel[]=[];

  selectedInsurerName:string="";
  selectedProductName:string="";
  selectedStateCityRTO:string="";
  selectedMakeModel:string="";
  selectedMakeModelVariant:string="";

  GenderList:any[]=[{text:"Select Gender",value:""},{text:"Female",value:"Female"},{text:"Male",value:"Male"},{text:"Other",value:"Other"}]
  stateList :AutoCompleteItem[] =[];
  districtList:AutoCompleteItem[]=[];
  pincodeList:AutoCompleteItem[]=[];
  cityList:AutoCompleteItem[]=[];
  commissionType:string="PER";
  commissionValue:number=0;

  AgentLevel1CommissionType?:string = "PER";
	AgentLevel1CommissionValue?:string = "0";
  AgentLevel2CommissionType?:string = "PER";
	AgentLevel2CommissionValue?:string  = "0";
  AgentLevel3CommissionType?:string = "PER";
	AgentLevel3CommissionValue?:string  = "0";
	DistributorLevelCommissionType?:string = "PER";
	DistributorLevelCommissionValue?:string  = "0";

  panDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:1,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }
  gstinDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:7,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }
  
  constructor(private _lookupService: LookUpService,private adminService: AdminService,private router:Router,private generalService: GeneralService) {
    
    // this.documentsUploadForm = this.fb.group({

    // })
    //this.getStatesByCountry('India');
    this.loadInsurancePartners();
    this.loadRTOStates();
    for(var i = 0; i <= 20; i++){
      let index= i.toString()
      this.ageList.push({id:index,name:index});
      
    }

    for(let j=0;j<2;j++){
      let year = (this.currentYear + j).toString();
      this.yearList.push({id:year,name:year});
    }

    this.onSelectProduct(0,"Scooter");
    this.onYearSelect();
  }

  async loadInsurancePartners()
  {
    let response = await this._lookupService.GetInsurancePartnersList();
      if (response?.isSuccess) {
        this.insurancePartnersList = response.result.map((m:any)=> {return {text:m.insuranceName,value:m.insuranceId,url:m.insuranceName}});
      }
  }

  async loadRTOStates()
  {
    this.rtoCityList=[];
    let response = await this._lookupService.GetRTOStatesList();
      if (response?.isSuccess) {
        this.rtoStateList = response.result.map((m:any)=> {return {name:m.toUpperCase(), id:m, checked:false}});
      }
  }

  async loadRTOCitiesByState(state:string)
  {
    let request = {Name: state};
    let response = await this._lookupService.GetRTOCitiesByStateList(request);
      if (response?.isSuccess) {
        this.rtoCityList = response.result.map((m:any)=> {return {name:m.text.toUpperCase(), id: (m.text2+ '_'+ m.value),name1:m.text1,name2:m.text2,checked:false}});
      }
  }

  async loadMakeList()
  {
    let request = {Type : (this.productId == 0 || this.productId == 2) ? 2 : this.productId , Name: ""};
    let response = await this._lookupService.GetVehicleCompanyDetails1(request);
      if (response?.isSuccess) {
        this.makeList = response.result.map((m:any)=> {return {name:m, id:m, checked:false}});
      }
  }

  async loadModelList()
  {
    this.modelList=[];
    let request = {Type : (this.productId == 0 || this.productId == 2) ? 2 : this.productId ,CompanyName: this.selectedMakes};
    let response = await this._lookupService.GetVehicleModelDetailsByMakes(request);
      if (response?.isSuccess) {
        this.modelList = response.result.map((m:any)=> {return {name:m.text, id:m.value,name1:m.text1, checked:false}});
      }
  }

  async loadVariantList()
  {
    this.VariantList=[];
    let request = {Type : (this.productId == 0 || this.productId == 2) ? 2 : this.productId, CompanyName: this.selectedMakes, ModelName: this.selectedModels.map((m:Item)=>{return m.id}).toString()};
    let response = await this._lookupService.GetVehicleVariantDetailsByModels(request);
      if (response?.isSuccess) {
        this.VariantList = response.result.map((m:any)=> {return {name:m.text, id:m.value,name1:m.text1,name2:m.text2, checked:false}});
      }
  }

  async loadFuelList()
  {
    this.fuelTypeList=[];
    let request = {Type : (this.productId == 0 || this.productId == 2) ? 2 : this.productId ,CompanyName: this.selectedMakes, ModelName: this.selectedModels.map((m:Item)=>{return m.id}).toString(), VariantName:this.selectedVariant.map((m:Item)=>{return m.id}).toString()};
    let response = await this._lookupService.GetVehicleFuelDetailsByModelsVariants(request);
      if (response?.isSuccess) {
        this.fuelTypeList = response.result.map((m:any)=> {return {name:m.text, id:m.value, checked:false}});
      }
  }

  onSelectInsurer(id:number,name:string)
  {
    this.insurerId = id;
    this.selectedInsurerName = name;
  }

  onSelectProduct(id:number,name:string)
  {
    debugger;
    this.productId = id;
    this.selectedProductName = name;
    this.loadMakeList();
    switch(id){
      case 1 : 
      this.policyTypeList=[{id:"Comprehensive", name:"Comprehensive"},{id:"SAOD", name:"SAOD"},{id:"SATP", name:"SATP"}];
      this.fuelTypeList = [{id:"CNG", name:"CNG"},{id:"Diesel", name:"Diesel"},{id:"Electric", name:"Electric"},{id:"Petrol", name:"Petrol"}];
      this.ccList = [{id:"1000", name:"Upto 1000cc"},{id:"1001-1500", name:"1000cc-1500cc"},{id:"1501", name:"More Than 1500cc"}];
      this.vehicleTypeList = [{id:"HEV", name:"HEV"},{id:"Non-HEV", name:"Non-HEV"}];
      this.selectedCC = "";
      this.selectedPolicyType = "";
      this.selectedFuel = "";
      this.selectedVehicleType = "";
      break;
      case 0 : 
      case 2 : 
      this.policyTypeList=[{id:"Comprehensive", name:"Comprehensive"},{id:"SAOD", name:"SAOD"},{id:"SATP", name:"SATP"}];
      this.fuelTypeList = [{id:"Electric", name:"Electric"},{id:"Petrol", name:"Petrol"}];4
      this.ccList = [{id:"75", name:"Up to 75cc"},{id:"76-150", name:"76cc-150cc"},{id:"151-350", name:"151cc-350cc"},{id:"351", name:"More Than 350cc"}];
      this.selectedCC = "";
      this.selectedPolicyType = "";      
      this.selectedFuel = "";
      break;
      case 3 : 
      this.policyTypeList=[{id:"Comprehensive", name:"Comprehensive"},{id:"SATP", name:"SATP"}];
      this.fuelTypeList = [{id:"CNG", name:"CNG"},{id:"Diesel", name:"Diesel"},{id:"Electric", name:"Electric"},{id:"Petrol", name:"Petrol"}];
      this.ccList = [{id:"1000", name:"Upto 1000cc"},{id:"1001-1500", name:"1000cc-1500cc"},{id:"1501", name:"More Than 1500cc"}];
      this.selectedCC = "";
      this.selectedPolicyType = "";
      this.selectedVehicleType = "";
      this.selectedFuel = "";
      break;
    }
  }

  changeTab(tab:number)
  {
    this.activeTab = tab;
  }

  async saveNext(tab:number,isPrevious:boolean = false)
  {    
    if(tab == 1)
    {
      this.activeTab = tab; 
    }
    else if(tab == 2)
    {
      if(isPrevious == true)
      {
        this.activeTab = tab;
      }
      else
      {
        this.submittedInsurerForm = true;
        if(this.insurerId > 0)
        {
          this.activeTab = tab;
        }   
      }      
    }
    else if(tab == 3)
    {
      if(isPrevious == true)
      {
        this.activeTab = tab;
      }
      else
      {
        this.submittedProductForm = true;
        if(this.selectedRTOStates.length > 0 && this.selectedRTOCities.length > 0 &&  //required for all
          this.selectedFuel.length > 0 && this.selectedAge.length > 0 && this.selectedPolicyType.length > 0)//required for all
          {
            if(this.productId < 3 && this.selectedCC.length > 0 )
            {
              this.activeTab = tab;
              //this.prepareSummaryData();
            } 
            if(this.productId == 3 && this.selectedCommVehicleCategory == 'PCV' && this.selectedCC.length > 0 && this.selectedCommVehiclePCVehicle.length > 0 && this.selectedCommVehiclePCVehicle.indexOf('Bus') <0)
            {
              this.activeTab = tab;
              //this.prepareSummaryData();
            }

            if(this.productId == 3 && this.selectedCommVehicleCategory == 'PCV' && this.selectedCC.length > 0 && this.selectedCommVehiclePCVehicle.length > 0 && this.selectedCommVehiclePCVehicle.indexOf('Bus') != -1 && this.selectedCommVehiclePassCap.length > 0)
            {
              this.activeTab = tab;
              //this.prepareSummaryData();
            }
              
            if(this.productId == 3 && ((this.selectedCommVehicleCategory == 'GCV' && this.selectedCommVehicleGCVehicle.length > 0) || (this.selectedCommVehicleCategory == 'MISC' && this.selectedCommVehicleMISCVehicle.length > 0 )) && this.selectedCommVehicleWeight.length > 0)
            {
              this.activeTab = tab;
              //this.prepareSummaryData();
            }
          } 
      }
    }
    else if(tab == 4)
    {
      this.activeTab = tab; 
      this.prepareSummaryData();    
    }
  }

  prepareSummaryData(){
    this.selectedStateCityRTO = this.selectedRTOCities.map((m:Item)=>{return m.name}).join(", ");
      this.selectedMakeModel = this.selectedModels.map((m:Item)=>{return m.name}).join(", ");
      this.selectedMakeModelVariant = this.selectedVariant.map((m:Item)=>{return m.name}).join(", ");
      
      this.requestObjet=[];
      switch(this.productId){
        case 0:
        case 2: this.twoWheelerDataMap(); break;
        case 1: this.fourWheelerDataMap(); break;
        case 3: this.commVehicleDataMap(); break;
      }
  }

  onStateSelect(data:Item[]){
    this.selectedRTOStates = data.map((m:Item)=>{return m.id}).toString();
    this.loadRTOCitiesByState(this.selectedRTOStates);
  }

  onCitySelect(data:Item[]){
    this.selectedRTOCities = data;//.map((m:Item)=>{return m.id}).toString();
  }

  onMakeSelect(data:Item[]){
    this.selectedMakes = data.map((m:Item)=>{return m.id}).toString();
    this.loadModelList();
  }

  onModelSelect(data:Item[]){
    this.selectedModels = data;
    this.loadVariantList();
  }

  onVariantSelect(data:Item[]){
    this.selectedVariant = data;
    this.loadFuelList();
  }

  onFuelSelect(data:Item[]){
    this.selectedFuel = data.map((m:Item)=>{return m.id}).toString();
  }

  onPassCapSelect(data:Item[]){
    this.selectedCommVehiclePassCap = data.map((m:Item)=>{return m.id}).toString();
  }

  onPCVSelect(data:Item[]){
    this.selectedCommVehiclePCVehicle = data.map((m:Item)=>{return m.id}).toString();
  }

  onGCVSelect(data:Item[]){
    this.selectedCommVehicleGCVehicle = data.map((m:Item)=>{return m.id}).toString();
  }

  onMISCSelect(data:Item[]){
    this.selectedCommVehicleMISCVehicle = data.map((m:Item)=>{return m.id}).toString();
  }
  
  onVehicleWeightSelect(data:Item[]){
    this.selectedCommVehicleWeight = data.map((m:Item)=>{return m.id}).toString();
  }

  onPolicyTypeSelect(data:Item[]){
    this.selectedPolicyType = data.map((m:Item)=>{return m.id}).toString();
  }

  onVehicleAgeSelect(data:Item[]){
    this.selectedAge = data.map((m:Item)=>{return m.id}).toString();
  }

  onCCSelect(data:Item[]){
    this.selectedCC = data.map((m:Item)=>{return m.id}).toString();
  }

  onvehicleTypeSelect(data:Item[]){
    this.selectedVehicleType = data.map((m:Item)=>{return m.id}).toString();
  }

  onYearSelect(){
    debugger;
    let month1 = 0;
    this.selectedMonth=1;
    this.monthList =[];
    if(this.selectedYear == this.currentYear){ 
      month1 = this.todayDate.getMonth();
      this.selectedMonth = month1+1;
    }     

    for(let month= month1; month <12; month++)
    {
      this.monthList.push({id:(month+1).toString(),name:this.monthNameArray[month]});
    }
  }

  onPolicyTypeChange(){
    //alert(this.selectedPolicyType);
  }

  onCommVehicleCategoryChange(){

  }

  getVehicleType()
  {
    let vehicleType="";
    switch(this.productId){
      case 0 : vehicleType = "Scooter"; break; 
      case 1 : vehicleType = "Pvt Car"; break; 
      case 2 : vehicleType = "MotorBike"; break; 
      case 3 : 
      switch(this.selectedCommVehicleCategory)
      {
        case "PCV" : vehicleType = "Passenger Carrying"; break;
        case "GCV" : vehicleType = "Goods Carrying"; break;
        case "MISC" : vehicleType = "Miscellaneous"; break;
      }
      break; 
    }
    return vehicleType;
  }

  async saveCommissionData()
  {
    let obj = {Mapping_Data:JSON.stringify(this.requestObjet)};
    debugger;
    const res = await this.adminService.SaveVehicleCommissionMapping(obj);    
    if (res != null && res.result) {
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Mapping successfully.',
        showConfirmButton: false,
        timer: 3000,
      });
      //this.router.navigate(['/distributors']);
    }
  }

twoWheelerDataMap(){
    let makeArray = this.selectedMakes.split(',');
    let modelArray = this.selectedModels;
    let variantArray = this.selectedVariant;
    let fuelTypeArray = this.selectedFuel.split(',');
    let ccArray = this.selectedCC.split(',');
    let policyTypeArray = this.selectedPolicyType.split(',');
    let stateArray = this.selectedRTOStates.split(',');
    let citiesArray = this.selectedRTOCities;
    let ageArray = this.selectedAge.split(',');  
    // this.selectedAge,this.selectedMonth,this.selectedYear,this.productId,this.insurerId,
    // this.commissionType,this.commissionValue4
    let make1 = "Honda";
    let Model1 = this.productId == 0 ? "Active" : "Shine";
    let Variant1 = this.productId == 0 ?  "3G" : "125";

    for(let st1=0;st1<stateArray.length;st1++){
      debugger;
      let state12 =  stateArray[st1];
      let ddd = this.selectedRTOCities.filter((f:Item)=>{ return f.name1?.toUpperCase() == state12.toUpperCase()});
      for(let ct=0;ct<this.selectedRTOCities.filter((f:Item)=>{ return f.name1?.toUpperCase() == state12.toUpperCase()}).length;ct++){

      }
    }
    
    for(let f=0;f<fuelTypeArray.length;f++){
      let fuelType1= fuelTypeArray[f];      
        for(let pt=0;pt<policyTypeArray.length;pt++){
          let policyType1 = policyTypeArray[pt];
          for(let st=0;st<stateArray.length;st++){
            let state1 =  stateArray[st];
            citiesArray = this.selectedRTOCities.filter((f:Item)=>{ return f.name1?.toUpperCase() == state1.toUpperCase()});
            for(let ct=0;ct<citiesArray.length;ct++){
              let city1 = citiesArray[ct].id??"";
              let rto1 = citiesArray[ct].name2??"";
              for(let cc=0;cc<ccArray.length;cc++){
                let cc1 = ccArray[cc];
                for(let ag=0;ag<ageArray.length;ag++){
                  let age1 = ageArray[ag];
                  if(makeArray.length > 0)
                  {
                    for(let m=0;m<makeArray.length;m++){
                      make1 = makeArray[m];  
                      modelArray = this.selectedModels.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase()});  
                      if(modelArray.length > 0)
                      { 
                        for(let md=0;md<modelArray.length;md++){
                          Model1 = modelArray[md].id??"";  
                          variantArray = this.selectedVariant.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase() && f.name2?.toUpperCase() == Model1.toUpperCase()});  
                          if(variantArray.length > 0)
                          {
                            for(let vr=0;vr<variantArray.length;vr++){
                              Variant1 = variantArray[vr].id??"";  
                              this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1);
                              }
                          }
                          else
                          {
                            this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1);
                          }
                        }
                      }
                      else
                      {
                        this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1);
                      }
                    }
                  }
                  else
                  {
                    this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1);
                  }
              }
            } 
          } 
        } 
      } 
    }
}

fourWheelerDataMap(){
  let makeArray = this.selectedMakes.split(',');
  let modelArray = this.selectedModels;
  let variantArray = this.selectedVariant;
  let fuelTypeArray = this.selectedFuel.split(',');
  let ccArray = this.selectedCC.split(',');
  let policyTypeArray = this.selectedPolicyType.split(',');
  let stateArray = this.selectedRTOStates.split(',');
  let citiesArray = this.selectedRTOCities;  
  let ageArray = this.selectedAge.split(','); 
  let vehicleTypeArray = this.selectedVehicleType.split(','); 
  // this.selectedAge,this.selectedMonth,this.selectedYear,this.productId,this.insurerId,
  // this.commissionType,this.commissionValue4
  let make1 = "Mahindra";
  let Model1 = "XUV 300";
  let Variant1 = "W8";
  
  for(let f=0;f<fuelTypeArray.length;f++){
    let fuelType1= fuelTypeArray[f];      
      for(let pt=0;pt<policyTypeArray.length;pt++){
        let policyType1 = policyTypeArray[pt];
        for(let st=0;st<stateArray.length;st++){
          let state1 =  stateArray[st];
          citiesArray = this.selectedRTOCities.filter((f:Item)=>{ return f.name1?.toUpperCase() == state1.toUpperCase()});
          for(let ct=0;ct<citiesArray.length;ct++){
            let city1 = citiesArray[ct].id??"";
            let rto1 = citiesArray[ct].name2??"";
            for(let cc=0;cc<ccArray.length;cc++){
              let cc1 = ccArray[cc];
              for(let ag=0;ag<ageArray.length;ag++){
                let age1 = ageArray[ag];
                for(let vt=0;vt<vehicleTypeArray.length;vt++){
                  let vehicleType1 = vehicleTypeArray[vt];
                  if(makeArray.length > 0)
                  {
                    for(let m=0;m<makeArray.length;m++)
                    {
                      make1 = makeArray[m];
                      modelArray = this.selectedModels.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase()});  
                      if(modelArray.length > 0)
                      { 
                        for(let md=0;md<modelArray.length;md++){
                          Model1 = modelArray[md].id??"";  
                          variantArray = this.selectedVariant.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase() && f.name2?.toUpperCase() == Model1.toUpperCase()});  
                          if(variantArray.length > 0)
                          {
                            for(let vr=0;vr<variantArray.length;vr++){
                              Variant1 = variantArray[vr].id??"";  
                              this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,vehicleType1);
                              }
                          }
                          else
                          {
                            this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,vehicleType1);
                          }
                        }
                      }
                      else
                      {
                        this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,vehicleType1);
                      }
                    }
                  }
                  else
                  {
                    this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,vehicleType1);
                  }
              }
            }
          } 
        } 
      } 
    } 
  }
}

commVehicleDataMap(){
  debugger;
  let makeArray = this.selectedMakes.split(',');
  let modelArray = this.selectedModels;
  let variantArray = this.selectedVariant;
  let fuelTypeArray = this.selectedFuel.split(',');
  let policyTypeArray = this.selectedPolicyType.split(',');
  let stateArray = this.selectedRTOStates.split(',');
  let citiesArray = this.selectedRTOCities;  
  let ageArray = this.selectedAge.split(','); 
  let passengerCapArray = this.selectedCommVehiclePassCap.split(','); // PCV & Bus
  let ccArray = this.selectedCC.split(','); // PCV
  let pcvArray = this.selectedCommVehiclePCVehicle.split(',');
  let gcvArray = this.selectedCommVehicleGCVehicle.split(',');
  let miscArray = this.selectedCommVehicleMISCVehicle.split(',');
  let gvwArray = this.selectedCommVehicleWeight.split(','); //MISC & GCV

  let make1 = "Mahindra";
  let Model1 = "XUV 300";
  let Variant1 = "W8";
  let cc1 = "0";
  for(let f=0;f<fuelTypeArray.length;f++){
    let fuelType1= fuelTypeArray[f];      
      for(let pt=0;pt<policyTypeArray.length;pt++){
        let policyType1 = policyTypeArray[pt];
        for(let st=0;st<stateArray.length;st++){
          let state1 =  stateArray[st];
          citiesArray = this.selectedRTOCities.filter((f:Item)=>{ return f.name1?.toUpperCase() == state1.toUpperCase()});
          for(let ct=0;ct<citiesArray.length;ct++){
            let city1 = citiesArray[ct].id??"";
            let rto1 = citiesArray[ct].name2??"";
            for(let ag=0;ag<ageArray.length;ag++){
              let age1 = ageArray[ag];
              if(this.selectedCommVehicleCategory == 'PCV')
              {
                for(let pcv=0;pcv<pcvArray.length;pcv++){
                  let pcv1 = pcvArray[pcv];
                  for(let cc=0;cc<ccArray.length;cc++){
                    cc1 = ccArray[cc];
                    if(pcv1.toUpperCase() =='BUS')
                    {
                      for(let pc=0;pc<passengerCapArray.length;pc++){
                        let passengerCap1 = passengerCapArray[pc];
                        if(makeArray.length > 0)
                        {
                          for(let m=0;m<makeArray.length;m++)
                          {
                            make1 = makeArray[m];
                            modelArray = this.selectedModels.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase()});  
                            if(modelArray.length > 0)
                            { 
                              for(let md=0;md<modelArray.length;md++){
                                Model1 = modelArray[md].id??"";  
                                variantArray = this.selectedVariant.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase() && f.name2?.toUpperCase() == Model1.toUpperCase()});  
                                if(variantArray.length > 0)
                                {
                                  for(let vr=0;vr<variantArray.length;vr++){
                                    Variant1 = variantArray[vr].id??"";  
                                    this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",pcv1,passengerCap1);
                                    }
                                }
                                else
                                {
                                  this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",pcv1,passengerCap1);
                                }
                              }
                            }
                            else
                            {
                              this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",pcv1,passengerCap1);
                            }
                          }
                        }
                        else
                        {
                          this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",pcv1,passengerCap1);
                        }
                      }
                    }
                    else 
                    {
                      if(makeArray.length > 0)
                      {
                        for(let m=0;m<makeArray.length;m++)
                        {
                          make1 = makeArray[m];
                          modelArray = this.selectedModels.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase()});  
                          if(modelArray.length > 0)
                          { 
                            for(let md=0;md<modelArray.length;md++){
                              Model1 = modelArray[md].id??"";  
                              variantArray = this.selectedVariant.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase() && f.name2?.toUpperCase() == Model1.toUpperCase()});  
                              if(variantArray.length > 0)
                              {
                                for(let vr=0;vr<variantArray.length;vr++){
                                  Variant1 = variantArray[vr].id??"";  
                                  this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",pcv1,"");
                                  }
                              }
                              else
                              {
                                this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",pcv1,"");
                              }
                            }
                          }
                          else
                          {
                            this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",pcv1,"");
                          }
                        }
                      }
                      else
                      {
                        this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",pcv1,"");
                      }
                    }
                  }
                }
              }
              else if(this.selectedCommVehicleCategory == 'GCV')
              {
                for(let gcv=0;gcv<gcvArray.length;gcv++){
                  let gcv1 = gcvArray[gcv];
                  for(let gvw=0;gvw<gvwArray.length;gvw++){
                    let gvw1 = gvwArray[gvw];
                    if(makeArray.length > 0)
                    {
                      for(let m=0;m<makeArray.length;m++)
                      {
                        make1 = makeArray[m];
                        modelArray = this.selectedModels.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase()});  
                        if(modelArray.length > 0)
                        { 
                          for(let md=0;md<modelArray.length;md++){
                            Model1 = modelArray[md].id??"";  
                            variantArray = this.selectedVariant.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase() && f.name2?.toUpperCase() == Model1.toUpperCase()});  
                            if(variantArray.length > 0)
                            {
                              for(let vr=0;vr<variantArray.length;vr++){
                                Variant1 = variantArray[vr].id??"";  
                                this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",gcv1,"",gvw1);
                                }
                            }
                            else
                            {
                              this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",gcv1,"",gvw1);
                            }
                          }
                        }
                        else
                        {
                          this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",gcv1,"",gvw1);
                        }
                      }
                    }
                    else
                    {
                      this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",gcv1,"",gvw1);
                    }
                  }
                }
              } 
              else if(this.selectedCommVehicleCategory == 'MISC')
              {
                for(let misc=0;misc<miscArray.length;misc++){
                  let misc1 = miscArray[misc];
                  for(let gvw=0;gvw<gvwArray.length;gvw++){
                    let gvw1 = gvwArray[gvw];
                    if(makeArray.length > 0)
                    {
                      for(let m=0;m<makeArray.length;m++)
                      {
                        make1 = makeArray[m];
                        modelArray = this.selectedModels.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase()});  
                        if(modelArray.length > 0)
                        { 
                          for(let md=0;md<modelArray.length;md++){
                            Model1 = modelArray[md].id??"";  
                            variantArray = this.selectedVariant.filter((f:Item)=>{ return f.name1?.toUpperCase() == make1.toUpperCase() && f.name2?.toUpperCase() == Model1.toUpperCase()});  
                            if(variantArray.length > 0)
                            {
                              for(let vr=0;vr<variantArray.length;vr++){
                                Variant1 = variantArray[vr].id??"";  
                                this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",misc1,"",gvw1);
                                }
                            }
                            else
                            {
                              this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",misc1,"",gvw1);
                            }
                          }
                        }
                        else
                        {
                          this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",misc1,"",gvw1);
                        }
                      }
                    }
                    else
                    {
                      this.fillRequestObject(make1,Model1,Variant1,city1,state1,cc1,policyType1,fuelType1,age1,rto1,"",misc1,"",gvw1);
                    }
                  }
                }
              }
            }
          } 
        } 
      } 
    }
  }

  fillRequestObject(make:string,model:string,variant:string,rTOCity:string,
    rTOState:string,cc:string,policyType:string,fuelType:string,age:string,rto:string,vehicleType1:string="",bodyType:string ="",seatingCapacity:string="",gvw:string=""){
    debugger;
      this.requestObjet.push({
      Id:0,
      InsuranceCompanyId:this.insurerId,
      Make:make,
      Model:model,
      Variant:variant,
      Zone:"",
      RTO:rto,
      RTOCity:rTOCity?.replace(rto+'_',''),
      RTOState:rTOState,
      Age:age,
      CC:cc,
      VehicleType:this.getVehicleType(),
      PolicyType:policyType,                
      IDV:"0",
      FuelType:fuelType,
      CommissionType:this.commissionType,
      CommissionValue:this.commissionValue.toString(),
      DistributorLevelCommissionType:this.DistributorLevelCommissionType,
      DistributorLevelCommissionValue:this.DistributorLevelCommissionValue, 
      AgentLevel1CommissionType: this.AgentLevel1CommissionType,
      AgentLevel1CommissionValue:this.AgentLevel1CommissionValue,
      AgentLevel2CommissionType: this.AgentLevel2CommissionType,
      AgentLevel2CommissionValue:this.AgentLevel2CommissionValue,
      AgentLevel3CommissionType:this.AgentLevel3CommissionType,
      AgentLevel3CommissionValue:this.AgentLevel3CommissionValue,               
      Status:true,
      Month:this.selectedMonth,
      Year:this.selectedYear,
      Body_Type : bodyType,
      Seating_Capacity : seatingCapacity,
      Grosss_Vehicle_Weight : gvw,
      Vehicle_Type : vehicleType1,
      VehicleTypeId : this.productId
    });
  }

  toggleChanged(data:any){
    debugger;
    switch(data.data){
      case "COM" : this.commissionType = data.status == false ? "PER" : "AMT"; break;
      case "DIS" : this.DistributorLevelCommissionType = data.status == false ? "PER" : "AMT"; break;
      case "AG1" : this.AgentLevel1CommissionType = data.status == false ? "PER" : "AMT"; break;
      case "AG2" : this.AgentLevel2CommissionType = data.status == false ? "PER" : "AMT"; break;
      case "AG3" : this.AgentLevel3CommissionType = data.status == false ? "PER" : "AMT"; break;
    }

  }
}

export class CommissionMappingModel{
  Make?: string;
  Model?: string;
  Variant?: string;
  Zone?: string;
  RTOState?:string;
  RTOCity?: string;
  RTO?: string;
  Age?: string;
  CC?: string;
  VehicleType?: string;
  PolicyType?: string;
  FuelType?: string;
  IDV?: string;
  CommissionType?: string;
  CommissionValue?: string;  
  InsuranceCompanyId?: number;
  Month?: number;
  Year?: number;
  Id?: number;
	AgentLevel1CommissionType?:string;
	AgentLevel1CommissionValue?:string;
  AgentLevel2CommissionType?:string;
	AgentLevel2CommissionValue?:string;
  AgentLevel3CommissionType?:string;
	AgentLevel3CommissionValue?:string;
	DistributorLevelCommissionType?:string;
	DistributorLevelCommissionValue?:string;
	Status?:boolean;
  Body_Type?:string;
  Seating_Capacity?:string;
  Grosss_Vehicle_Weight?:string;
  Vehicle_Type?:string;
  VehicleTypeId?:number;
}