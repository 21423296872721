import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'; 
import { Item } from 'src/app/models/SelectItems';
import * as XLSX from "xlsx";



@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css'],
})

export class TableViewComponent implements OnInit {
  _graphdata: any[]=[];
  @Input() graphHeader: any[]=[];
  @Input() visibleColumnData : Item[] =[]; 
  @Input() visibleColumnsId : any[] =[]; 
  @Input() tableBg: string = 'var(--white)'
  @Input() widthTable: string = '100%';
  @Input() title: string = '';
  @Input() openBal: string = '';
  @Input() buttonTitle: string = '';
  @Input() buttonClick: any;
  @Input() viewEvent: any;
  @Output() i: any;
  @Input() tableBackground: string = "var(--grey3)";
  @Input() textColor: string = "var(--white)";
  @Input() showTableRow: boolean = true;
  @Input() showPaginator: boolean = true;
  @Input() showButtons: boolean = false;
  @Input() showIconButtonEnd: boolean = false;
  @Input() showIconButtonStart: boolean = false;
  @Output() onViewClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCreateBtnClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onColumnVisibilityChange: EventEmitter<any> = new EventEmitter<any>();
  searchText:string="";
  tableColumns: any[] = [];
  @Input() totalCredit: string = '';
  @Input() totalDebit: string = '';
  @Input() closingBalance: string = '';
  @Input() canEdit: Boolean = false;
  @Input() canView: Boolean = false;
  @Input() canDelete: Boolean = false;
  @Input() canCreate: Boolean = false;
  @Input() canDowanload: Boolean = false;
  @Input() showFilters: Boolean = true;
  @Input() showExcelExport: Boolean = true;
  @Input() className: string = '';
  @Input() exportFileName:string = '';
  filteredData: any[] = [];


  headers:any;
  @Input('graphdata')
  set graphdata(graphdata: any[]) {
    this._graphdata = graphdata;
    this.filteredData = [...this._graphdata];
    this.pageIndex = 1;
  }

  public getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  sortedIndex:number=0;
  sortOrder:string='asc';
  paginatedData :any[] = [];
  constructor() {
    this.pageIndex = 1;
    this.pageSize = this.pageSizeOptions[0];
  }

  // Add these properties for pagination

  pageSizeOptions: number[] = [5, 10, 25]; // Define the page size options

  pageSize = this.pageSizeOptions[0]; // Set default page size

  pageIndex = 1; // Current page index

  // Function to handle page changes

  onPageChange(event: any) {
    //this.pageSize = event;// event.pageSize;
    this.pageIndex = event;
  }
  
  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.pageIndex = 1;
  }

  handleClickView(i: any) {
    this.onViewClick.emit(i);
  }

  // Function to return a sliced array of data based on the current page index and page size

  // Function to return a sliced array of data based on the current page index and page size

  // getPaginatedData() {
  //   const startIndex = this.pageIndex * this.pageSize;
  //   const endIndex = startIndex + this.pageSize;
  //   console.log(this.graphdata);
  //   this.paginatedData = this.graphdata.slice(startIndex, endIndex);
  //   return this.graphdata.slice(startIndex, endIndex);    
  // }

  getColumnData() {
    if (this.graphHeader.length == 0) {

      this.tableColumns = [];

    } else {

      this.tableColumns = this.graphHeader;
      for (let key in this.graphHeader) {
        debugger;
        this.tableColumns.push({
          key: this.graphHeader[0].key,
          type: this.graphHeader[0].type,
        });
      }
    }
    debugger;
    return this.graphHeader;
  }

  convertKeysToTitle(key: string): string {

    let title = key.replace('_', ' ');

    title = title.charAt(0).toUpperCase() + title.slice(1);

    return title;

  }

  handleClick(action: string, rowData: any, rowData1: any, rowData2: any) {
    console.log(`Clicked "${action}" on row data:`, rowData);
    this.onActionClick.emit({action:action,id:rowData,id1:rowData1,id2:rowData2});
  }

  ngOnInit(): void {
    // if (this.graphHeader.length == 0) {

    //   this.tableColumns = [];

    // } else {

    //   for (let key in this.graphHeader) {

    //     this.tableColumns.push({

    //       key: this.graphHeader[0].key,

    //       type: this.graphHeader[0].type,

    //     });
    //   }
    // }
    // if (this.graphHeader.length == 0) {

    //   this.tableColumns = [];

    // } else {

    //   this.tableColumns = this.graphHeader;
    // }
  }
  getColumns(graphHeader:any)
  {
     let dd =  graphHeader.map((m:any,index:number)=>{return {id:index?.toString(),name:m.key}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
     return dd;

    // if (this.graphHeader.length == 0) {

    //   this.tableColumns = [];

    // } else {

    //   this.tableColumns = this.graphHeader;
    //   for (let key in this.graphHeader) {
    //     this.tableColumns.push({
    //       id: key?.toString(),
    //       name: this.graphHeader[0].key,
    //     });
    //   }
    // }
    // return this.tableColumns;
  }

  onItemChange(data:Item[]){
    this.visibleColumnsId = data.filter(f=>f.checked == true && f.id !== null).map(m=>{return m.id}) ?? [];  
    this.onColumnVisibilityChange.emit(this.visibleColumnsId);
  } 

  sort(index:number)
  {
    if(this.sortedIndex == index)
    {
      this.sortOrder = this.sortOrder == 'desc' ? 'asc' : 'desc';
    }
    else
    {
      this.sortOrder ='asc';
    }
    this.sortedIndex = index;
    this.filteredData.sort( (a, b) => {
      if(this.sortOrder == 'desc')
      {
        if (a[index]?.toString().toLowerCase() > b[index]?.toString().toLowerCase()) {
          return -1;
        }
        if (b[index]?.toString().toLowerCase() > a[index]?.toString().toLowerCase()) {
            return 1;
        }          
      }
      else
      {
        if (a[index]?.toString().toLowerCase() < b[index]?.toString().toLowerCase()) {
          return -1;
        }
        if (b[index]?.toString().toLowerCase() < a[index]?.toString().toLowerCase()) {
            return 1;
        }  
      }
      return index;
    })
  }

  getTotalPages()
  {
    return Math.ceil(this.filteredData.length/this.pageSize);
  }

  get search(): string {
    return this.searchText;
 }

set search(searchText: string) {
    this.searchText = searchText;

    const search = this.searchText.toLowerCase();
    if (!search) {
        this.filteredData = [...this._graphdata];
        return;
    }
    this.filteredData = this._graphdata.filter((i:any) => i?.toString().toLowerCase().indexOf(search) !== -1);
}
  
createBtnClick(){
  this.onCreateBtnClick.emit("btnClick");
}

exportToExcel() {

  let header:any = this.graphHeader.filter((f:any,index:number) => this.visibleColumnsId.includes(index?.toString())).map((m:any)=> {return m.key});

  let body = this.filteredData.map((m:any)=> {
    return this.visibleColumnsId.map(col => m[col])
  });

  let excelData = body.map((_:any, row:any) =>
    header.reduce((json:any, key:any, col:any) => ({
      ...json,
      [key]: body[row][col]
    }), {}));
  console.log(excelData);

  const fileName = (this.exportFileName == null || this.exportFileName == undefined || this.exportFileName == '' ? "export" : this.exportFileName) + ".xlsx";
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "sheet1");
  XLSX.writeFile(wb, fileName);
}
}
