import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../models/ApiResponse';
import { AppConfig } from '../shared/AppConfig';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookUpService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async GetVehicleCompanyDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleCompanyDetails`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetVehicleModelDetails(vehiclecompanyId: number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleModelDetails` + '?vehiclecompanyId=' + vehiclecompanyId , {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetVehicleVariantDetails(vehiclemodelId: number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleVariantDetails` + '?vehiclemodelId=' + vehiclemodelId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async stateDetails(countryId: number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/StateDetails` + '?countryId=' + countryId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async pincodeMasterDetails(pinCode: string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/PinCodeDetails` + '?pinCode=' + pinCode, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async countryDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/CountryDetails`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async SaveLeadsDetail(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/SaveLeadsDetail`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async SaveCareerDetail(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/SaveCareerDetail`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetInsurancePartnersList() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetInsurancePartners1`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async getStatesListByCountry(country:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetStatesByCountry?country=`+country, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async getDistrictsListByState(state:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetDistrictsByState?state=`+state, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async getPincodeByDistrict(district:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetPincodeByDistrict?district=`+district, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetRTOStatesList() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetRTOStates`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetRTOCitiesByStateList(data:any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetRTOCitiesByState`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetVehicleCompanyDetails1(data:any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleCompanyDetails`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleModelDetails1(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleModelDetails`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleVariantDetails1(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleVariantDetails`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleFuelDetails1(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleFuelDetails`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleModelDetailsByMakes(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleModelDetailsByMakes`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleVariantDetailsByModels(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleVariantDetailsByModels`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleFuelDetailsByModelsVariants(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleFuelDetailsByModelsVariants`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetLanguageDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/LanguageDetails`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
}
