import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { checkIfInSameTree } from 'src/app/functions/check_url_if_in_same_tree';
import { SidebarState } from '../sidebar/state/sidebar.state';
import { selectMenu } from '../sidebar/state/sidebar.actions';

@Component({
  selector: 'app-simple-menu-button',
  templateUrl: './simple-menu-button.component.html',
  styleUrls: ['./simple-menu-button.component.css'],
})
export class SimpleMenuButtonComponent implements OnInit {
  isHighlighted: boolean = false;
  valueSubscription: any;
  @Input() selected: boolean = false;
  @Input() title: string = '';
  @Input() icon_name: string = '';
  @Input() url: string = '';
  @Input() menuId: number = 0;

  constructor(
    private store: Store<{ sidebar: SidebarState }>,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (checkIfInSameTree(this.url)) {
      this.store.dispatch(selectMenu({ selectedMenu: this.title }));
    }

    this.valueSubscription = this.store.select('sidebar').subscribe((state) => {
      this.isHighlighted = state.selectedMenu === this.title;
    });
  }

  ngOnDestroy(): void {
    this.valueSubscription.unsubscribe();
  }

  selectMenu() {
    this.store.dispatch(selectMenu({ selectedMenu: this.title }));
    if (this.url) 
    {
      this.router.navigateByUrl(this.url,{ state: { id:this.menuId }});
      //this.router.navigate([this.url]);
    }
  }
}
