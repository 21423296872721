import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginLogsComponent } from 'src/app/components/login-logs/login-logs.component';
import { SecurityLogsModel } from 'src/app/models/CommonModel';
import { ResellerDetails, DocumentDetails } from 'src/app/models/Distributor';
import { AutoCompleteItem, Item } from 'src/app/models/SelectItems';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { LookUpService } from 'src/app/services/lookup.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-reseller-profile',
  templateUrl: './reseller-profile.component.html',
  styleUrls: ['./reseller-profile.component.css']
})
export class ResellerProfileComponent  implements AfterViewInit{
  activeTab:number=1;
  activeTabItem:number=1;
  resellerForm: FormGroup;
  companyForm: FormGroup;
  // securityLogsForm:FormGroup;
  documentsUploadForm:DocumentDetails[]=[];
  submittedDisForm : boolean = false;
  submittedDocForm : boolean = false;
  submittedCompForm : boolean = false;
  GenderList:any[]=[{text:"Select Gender",value:""},{text:"Female",value:"Female"},{text:"Male",value:"Male"},{text:"Other",value:"Other"}]
  stateList :AutoCompleteItem[] =[];
  districtList:AutoCompleteItem[]=[];
  pincodeList:AutoCompleteItem[]=[];
  cityList:AutoCompleteItem[]=[];
  state:string='';
  city:string='';
  pincode:string='';
  district:string='';
  detail:string="";
  panDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:1,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }
  gstinDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:7,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }

  @ViewChild(LoginLogsComponent) loginLogsComponent!: LoginLogsComponent;
  constructor(private fb: FormBuilder, private _lookupService: LookUpService,private adminService: AdminService,private router:Router
    ,private activatedRoute:ActivatedRoute,private generalService: GeneralService) {
      // let obj = this.router.getCurrentNavigation()?.extras?.state;
      // if(obj)
      // {
      //   this.getFormData(parseInt(obj['id']));
      // }
      this.getFormData(0);
    this.resellerForm = this.fb.group({
      "resellerFirstName": ['', [Validators.required]],
      "resellerLastName": ['', [Validators.required]],
      "resellerEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "resellerMobile": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "resellerCountryCode": ['91'],
      "resellerStatus": ['1', [Validators.required]],
      "resellerId": ['0'],
      "resellerDOB": ['', [Validators.required]],
      "resellerGender": ['', [Validators.required]],
      "userId": ['0'],
      "smsEnabled":[true],
      "emailEnabled":[true],
      "notificationEnabled":[true]      
    });

    this.companyForm = this.fb.group({
      "companyId": ['0'],
      "companyName": ['', [Validators.required]],
      "companyPancard": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "companyGSTIN": ['', [Validators.required, Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')]],
      "companyLicenseNo": ['', [Validators.required]],
      "companyMobileNo": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "companyCountryCode": ['91', [Validators.required]],
      "companyEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "companyStreetAddress": ['', [Validators.required]],      
      "companyPincode": ['', [Validators.required,Validators.pattern('[0-9]{6}')]],
      "companyStateId": [''],
      "companyState": ['', [Validators.required]],
      "companyCityId": [''],
      "companyCity": ['', [Validators.required]],
      "companyStatus": ['1', [Validators.required]],
      "userId": ['0']
    });

    // this.securityLogsForm =this.fb.group({
    //   "id": [''],
    //   "userId": [''],
    //   "stateId": [''],
    //   "cityId": [''],
    //   "deviceType": [''],
    //   "deviceName": [''],
    //   "iPAddress": [''],
    //   "result": [''],
    //   "reason": [''],
    //   "loginAtStr": [''],
    //   "logoutAtStr": [''],
    //   "latitude": [''],
    //   "longitude": [''],
    //   "locality": [''],
    //   "city": [''],
    //   "district": [''],
    //   "state": [''],
    //   "country": [''],
    //   "mobileNumber": ['']
    // });
    // this.documentsUploadForm = this.fb.group({

    // })
    this.getStatesByCountry('India');
  }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loginLogsComponent?.getLoginLogs(0,this.generalService.getUniqueuserid());//'5DD67B51BDE34AF58F1A1966E6EE5723'); //this.generalService.getUniqueuserid());
  }

  get resellerFormControl() {
    return this.resellerForm.controls;
  }
  get companyDetailsFormControl() {
    return this.companyForm.controls;
  }
  // get uploadDocumentsFormControl() {
  //   return this.documentsUploadForm.controls;
  // }
  changeTab(tab:number)
  {
    this.activeTab = tab;
  }

  changeTabItem(tab:number){
    this.activeTabItem = tab;
  }

  async saveNext(tab:number,isPrevious:boolean = false)
  {    
    if(tab == 1)
    {
      this.activeTab = tab; 
    }
    else if(tab == 2)
    {
      if(isPrevious == true)
      {
        this.activeTab = tab;
      }
      else
      {
        this.submittedDisForm = true;
        if(this.resellerForm.valid)
        {
          this.activeTab = tab;
        }   
      }      
    }
    else if(tab == 3)
    {
      if(isPrevious == true)
      {
        this.activeTab = tab;
      }
      else
      {
        this.submittedCompForm = true;
        if(this.companyForm.valid)
        {
          this.activeTab = tab;
        }   
    }
    }
    else if(tab == 4)
    {
      this.submittedDocForm = true;
      if(this.documentsUploadForm.length == 2)
      {
        this.activeTab = tab;
      }
    }
  }

  async saveResellerData(updateFor:string)
  {
    debugger;
      this.submittedCompForm = updateFor == "COMP";
      this.submittedDisForm= updateFor == "PROF";
      this.submittedDocForm= updateFor == "DOCU";
      if(updateFor == "COMP" && !this.companyForm.valid){
        return;
      }
      if(updateFor == "PROF" && !this.resellerForm.valid){
        return;
      }
      if(updateFor == "DOCU" && this.documentsUploadForm.length < 2){
        return;
      }
      let resellerDetails:ResellerDetails =this.resellerForm.value;
      resellerDetails.companyDetails = this.companyForm.value;
      resellerDetails.userDocuments = this.documentsUploadForm;
      resellerDetails.updateFor = updateFor;
      const res = await this.adminService.ResellerInfo(resellerDetails);    
      if (res != null && res.result && res.result.code == 1) {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: res.result.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }    
      else
      {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: res.result.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } 
  }

  async getStatesByCountry(country:string)
  {
    let response = await this._lookupService.getStatesListByCountry(country); 
    if (response?.isSuccess) {
      this.stateList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
    }
    console.log(this.stateList);
  }

  async getDistrictsByState(state:string)
  {
    let response = await this._lookupService.getDistrictsListByState(state); 
    if (response?.isSuccess) {
      this.districtList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
    }
  }
  async getPincodesByDistrict(district:string)
  {
    let response = await this._lookupService.getPincodeByDistrict(district); 
    if (response?.isSuccess) {
      this.pincodeList = response.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id}});
      this.cityList = response.result.map((item:any)=> {return {text:item.cityVillageArea,value:item.cityVillageArea, text1:item.pincode, id:item.id}});
    }
  }

  onStateSelection(data:AutoCompleteItem){
    this.getDistrictsByState(data.text);
    this.companyForm.get('companyState')?.setValue(data.text);
    //console.log(this.companyForm.value);
   }
 
   onDistrictSelection(data:AutoCompleteItem){
    //  this.companyForm.get('city')?.setValue(data.text);
      this.getPincodesByDistrict(data.text);
    //  console.log(this.companyForm.value); 
   }
 
    onPincodeSelection(data:AutoCompleteItem)
    {
     this.companyForm.get('companyPincode')?.setValue(data.text);
     //console.log(this.companyForm.value); 
   }
 
    onCitySelection(data:AutoCompleteItem)
    {
     this.companyForm.get('companyCity')?.setValue(data.text);
     // console.log(this.userForm.value);
    }
 
    onStateBlur(data:any)
    {
       if(data == ""){
         this.companyForm.get('companyState')?.setValue(data);
       }
    }
 
    onPincodeBlur(data:any)
    {
      this.companyForm.get('companyPincode')?.setValue(data);
    }
 
    onCityBlur(data:any)
    {
     if(data == ""){
       this.companyForm.get('companyCity')?.setValue(data);
     }
    }
 
    onDistrictBlur(data:any)
    {
    //  if(data == ""){
    //    this.companyForm.get('city')?.setValue(data);
    //  }
    }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
  }

  alphaNumericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }

  async SetFileType(baseUpload64FileData:string) {
    let format=''; 
    if (baseUpload64FileData.includes(`application/pdf`)) {
      format = 'pdf';
    }
    else if (baseUpload64FileData.includes(`image`)) {
      format = 'image';
    }
    else if (baseUpload64FileData.includes(`video`)) {
      format = 'video';
    }
    return format;
  }

  async onSelectFile(file: any) {
    debugger;
    let fileToUpload = file.target.files.item(0);
    // if (this.fileToUpload?.type.includes('image') && this.fileToUpload!.size > 500000) {
    //   this.baseUpload64FileData = '';
    //   this.documentForm.get('documentBase64')?.setValue('');
    //   swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'File size should not be greather than 500kb',
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
    // else if (this.fileToUpload?.type.includes('pdf') && this.fileToUpload!.size > 5000000) {
    //   this.baseUpload64FileData = '';
    //   this.documentForm.get('documentBase64')?.setValue('');
    //   swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'File size should not be greather than 5MB',
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
    // else if (this.fileToUpload?.type.includes('video') && this.fileToUpload!.size > 50000000) {
    //   this.baseUpload64FileData = '';
    //   this.documentForm.get('documentBase64')?.setValue('');
    //   swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'File size should not be greather than 50MB',
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
    // else {
      let reader = new FileReader();
      reader.onload = async (event: any) => {
        let baseUpload64FileData = reader.result!.toString();
        //this.documentForm.get('documentBase64')?.setValue(baseUpload64FileData);
        let fileType = await this.SetFileType(baseUpload64FileData);
      }
      reader.readAsDataURL(fileToUpload);
    //}
  }

  pancardSelected(docObj1:DocumentDetails)
  {
    debugger;
      this.panDocObj =docObj1;
      if(this.panDocObj.documentBase64!="" && this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId == 1}).length == 0)
      {
        this.documentsUploadForm.push(docObj1);
      }
      else
      {
        this.documentsUploadForm= this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId != 1});
        this.documentsUploadForm.push(docObj1)
      }
  }

  gstinSelected(docObj1:DocumentDetails)
  {
      this.gstinDocObj = docObj1;
      if(this.gstinDocObj.documentBase64!="" && this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId == 7}).length == 0)
      {
        this.documentsUploadForm.push(docObj1);
      }
      else
      {
        this.documentsUploadForm= this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId != 7});
        this.documentsUploadForm.push(docObj1)
      }
  }

  deleteFile(docTypeId:number)
  {
    this.documentsUploadForm= this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId != docTypeId});
  }

  async getFormData(id:number)
  {

    const res = await this.adminService.GetResellerById(id,'5DD67B51BDE34AF58F1A1966E6EE5723');//this.generalService.getUniqueuserid());  
      if (res != null && res.result) {

        this.resellerForm.patchValue(res.result);
        this.companyForm.patchValue(res.result.companyDetails);
        this.documentsUploadForm = res.result.userDocuments;
        this.state = res.result.companyDetails?.companyState??"";
        this.city =  res.result.companyDetails?.companyCity??"";
        this.pincode =  res.result.companyDetails?.companyPincode??"";
        // this.onStateSelection(data:AutoCompleteItem) 
        // this.onDistrictSelection(data:AutoCompleteItem) 
        // this.onPincodeSelection(data:AutoCompleteItem)    
        // this.onCitySelection(data:AutoCompleteItem)

        for(let i=0;i<this.documentsUploadForm.length;i++)
        {
          if(this.documentsUploadForm[i].documentTypeId == 1)
          {
            this.panDocObj = this.documentsUploadForm[i];
          }
          else if(this.documentsUploadForm[i].documentTypeId == 7)
          {
            this.gstinDocObj = this.documentsUploadForm[i];
          }
        }        
      }    
      else
      {
        // swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Something went wrong',
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
      } 
  }
  toggleChanged(data:any){
    if(data.data == "SMS")
    {
      this.resellerForm.get("smsEnabled")?.setValue(data.status);
      this.saveResellerData("SMS");
    }
    else if(data.data == "EMAIL")
    {
      this.resellerForm.get("emailEnabled")?.setValue(data.status);
      this.saveResellerData("EMAIL");
    }
    else if(data.data == "PUSH")
    {
      this.resellerForm.get("notificationEnabled")?.setValue(data.status);
      this.saveResellerData("PUSH");
    }
  }

  isPANCardUploaded():boolean{
    return (this.panDocObj.documentBase64 != "" && this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId == 1}).length > 0)
  }

  isGSTNDocUploaded():boolean{
    return (this.gstinDocObj.documentBase64!="" && this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId == 7}).length > 0)
  }
}
