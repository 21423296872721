export function alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
}

export function alphabetWithSpaces(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
}

export function alphaNumericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
}

export function alphaNumericWithSpaces(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)  || charCode == 32)) {
      return true;
    }
    return false;
}

export function numericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57)) {
      return true;
    }
    return false;
}