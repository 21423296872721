<app-mainscreen>
    <app-simple-page-heading [breadcrumbs]="['Dashboard > Agent']" title="Agent" ></app-simple-page-heading>
    <div style="height: 15px;" *ngIf="userType != '2' && userType != '3'">    
    </div>
    <div class="box_container mt-4 pt-2 rounded-bottom1-0" *ngIf="userType == '2' || userType =='3'">
      <div class="box-header mb-4 w-100 pl-2">
          <label class="m-0 box_title_22 pl-1"> Agents </label>
      </div>
      <div class="row w-100">
          <div class="col-md-3 position-relative" *ngIf="userType =='2'">
            <select id="distributorId" name="distributorId" placeholder=" " class="form-control input_" [(ngModel)]="distributorId" (change)="bindResellerDDL()">
              <option *ngFor="let dis of distributorList" [value]="dis.id">
                  {{dis.name}}
              </option>
              </select>
              <label for="distributorId" class="label_">Distributor</label>
          </div>
          <div class="col-md-3 position-relative" *ngIf="userType =='2' || userType =='3'">
            <select id="resellerId" name="resellerId" placeholder=" " class="form-control input_" [(ngModel)]="resellerId">
              <option *ngFor="let dis of resellerList" [value]="dis.id">
                  {{dis.name}}
              </option>
              </select>
              <label for="distributorId" class="label_">Reseller</label>
          </div>
          <div class="col-md-1">
            <app-custom-button text="Filter" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="filterAgentData()">                    
            </app-custom-button>
        </div>
      </div>
    </div>
    <app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title="{{userType == '2' || userType == '3' ? '..' : 'Agents' }}"
    tableBackground="var(--grey3)"
    buttonTitle="Create Agent"
   [showIconButtonEnd]="true"
    [showPaginator]="true"  
    (onActionClick)="onActionClick($event)"
    (onCreateBtnClick)="onCreateBtnClick($event)"
    [canCreate]="canCreate"
    [canView]="canView"
    [canDelete]="canDelete"
    [canEdit]="canEdit"
    className="{{userType == '2' || userType == '3' ? 'rounded-top1-0' : ''}}"
  ></app-table-view>
  <app-stepper-dialog></app-stepper-dialog>
  </app-mainscreen>