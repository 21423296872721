<button (click)="onClicked()" [disabled]="disabled" class="btn_class {{className}}"
    [style.cursor]="disabled ? 'not-allowed' : 'pointer'"    
    [style.height]="height" [style.width]="width != null && width" (mouseenter)=" handleMouseEnter()"
    (mouseleave)="handleMouseLeave()" >
    <span class="material-symbols-outlined mr-2" [style.font-size]="fontSizeIcon" *ngIf="leadingIconName != null && leadingIconName != ''">
        {{leadingIconName}}
    </span>
    <img [src]="leadingImageUrl || imageUrl" *ngIf="leadingImageUrl || imageUrl"
        style="height: calc(100% - 5px); margin-block: 2.5px; margin-right: 10px;">
    <span [style.font-size]="fontSize">{{text}}</span>
</button>

<!-- [style.color]="disabled ? 'var(--white)' : textColor ? textColor : 'var(--white)'"
    [style.background-color]="disabled ?  (backgroundColor ? backgroundColor : 'var(--grey4)') : hovered ? (hoveredBackgroundColor ? hoveredBackgroundColor : 'var(--main2)') : (backgroundColor ? backgroundColor : 'var(--main1)')" -->