<div class="user-dashboard">
  <div class="container-fluid text-center">
    <div class="card card-user shadow">
      <div>
        <h3>
          <b class="float-start text-primary mt-4 ms-4">Enter Reseller Details</b>
        </h3>
      </div>
      <div class="card-body">
        <form [formGroup]="resellerForm" class="needs-validation" novalidate="" autocomplete="off">
          <div class="row">
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">First Name : </label>
                <input type="text" name="firstName" placeholder="First Name " class="form-control" formControlName="resellerFirstName">
              </div>
            </div>

            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Last Name : </label>
                <input type="text" name="lastName" placeholder="Last Name " class="form-control" formControlName="resellerLastName">
              </div>
            </div>

            <div class="col-md-4 pr-1">
              <label class="float-start">Mobile : </label>
              <ngx-intl-tel-input class="bg-transparent"
                                  [enableAutoCountrySelect]="true"
                                  [enablePlaceholder]="true"
                                  [searchCountryFlag]="false"
                                  [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="false"
                                  [selectedCountryISO]="CountryISO.India"
                                  [phoneValidation]="true"
                                  [separateDialCode]="separateDialCode"
                                  name="phone"
                                  formControlName="resellerMobile"
                                  [inputId]="'phone_number'"
                                  (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

              </ngx-intl-tel-input>
            </div>

          </div><br>

          <div class="row">
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Email : </label>
                <input type="text" name="email" placeholder="Email" class="form-control" formControlName="resellerEmail">
              </div>
            </div>
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Distributor : </label>
                <select class="form-select" #distributor
                        aria-label="Default select example" name="distributor" formControlName="distributorId">
                  <option value="" selected>Select distributor</option>
                  <option *ngFor="let distributor of distributorData" [value]="distributor.id">
                    {{distributor.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Referral Code : </label>
                <input type="text" name="referralCode" placeholder="Referral Code " minlength="6" maxlength="10" class="form-control" formControlName="referralCode">
              </div>
            </div>
          </div>
          <br>
          <div class="float-end text-center">
            <button class="btn btn-light" (click)="clearData()">Clear</button>
            <button class="btn btn-primary"  [disabled]="resellerForm.invalid" (click)="resellerInfo()">Save</button> &nbsp;
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-4 pr-1">
                <div class="form-group">
                  <label class="float-start">Commision Type : </label>
                  <select class="form-select" #distributor
                          aria-label="Default select example" name="distributor" formControlName="commissionType">
                    <option value="" selected>Select commisionType</option>
                    <option [value]="1">  Percentage </option>
                    <option [value]="2">  Flat Fee </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 pr-1">
                <div class="form-group">
                  <label class="float-start">commision Value : </label>
                  <input type="text" DigitsOnly name="commissionValue" placeholder="commission Value " class="form-control" formControlName="commissionValue">
                </div>
              </div>

              <div class="col-md-4 pr-1">
                <label class="float-start">commissionFrequency : </label>
                  <div class="form-group mb-5">
                      <div class="form-check form-check-custom me-10">
                        <input class="form-check-input h-20px w-20px" name="commissionFrequency"  type="radio" value="1" formControlName="commissionFrequency" id="commissionFrequency1" />
                        <label class="form-check-label">
                          Onetime
                        </label>
                      </div>
                      <div class="form-check form-check-custom me-10">
                        <input class="form-check-input h-20px w-20px" name="commissionFrequency"  type="radio" value="2" formControlName="commissionFrequency" id="commissionFrequency2" />
                        <label class="form-check-label" >
                          Per Policy
                        </label>
                      </div>
                      <div class="form-check form-check-custom me-10">
                        <input class="form-check-input h-20px w-20px" name="commissionFrequency"  type="radio" value="3" formControlName="commissionFrequency" id="commissionFrequency3" />
                        <label class="form-check-label" >
                          Monthly
                        </label>
                      </div>
                    
                  </div>
                
              </div>

            </div>
            <div class="row">
              <div formArrayName="commissionFee">
                <div *ngFor="let fee of commissionFee.controls; let i=index">
                  <div [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-4 pr-1">
                        <div class="form-group">
                          <label class="float-start">Fee Name : </label>
                          <input type="text" name="feeName" placeholder="fee Name " class="form-control" formControlName="feeName">
                        </div>
                      </div>
                      <div class="col-md-4 pr-1">
                        <div class="form-group">
                          <label class="float-start">Max Value: </label>
                          <input type="text" name="maxValue" placeholder="max Value " class="form-control" formControlName="maxValue">
                        </div>
                      </div>

                      <button class="btn btn-primary" (click)="addCommisionFee()">Add</button>
                      <button class="btn btn-light" (click)="removeCommisionFee(i)">Remove</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
  <br>
  <!-- Table -->

  <div class="container-fluid text-center">
    <div class="card card-user shadow">
      <div>
        <h3>
          <b class="float-start text-primary mt-4 ms-4">Reseller Information</b>
        </h3>
      </div>
      <div class="card-body table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-hover table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th> Reseller Name</th>
              <th> Reseller Email</th>
              <th> Reseller Mobie</th>
              <th> Distributor Name</th>
              <th> Reseller Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of resellerData; let i = index">
              <td>
                {{row.resellerFirstName}} {{row.resellerLastName}}
              </td>
              <td>
                {{row.resellerEmail}}
              </td>
              <td>
                {{row.resellerMobile}}
              </td>
              <td>
                {{row.distributorName?row.distributorName:'-'}}
              </td>
              <td>
                <span *ngIf="row.resellerStatus == 1" class="badge badge-light-success">Active</span>
                <span *ngIf="row.resellerStatus == 0" class="badge badge-light-danger">Inactive</span>
              </td>
              <td>
                <button type="button" class="btn btn-default" aria-label="Left Align" (click)="resellerEdit(row.resellerId)">
                  <span class="fa fas fa-edit" style="color:green" aria-hidden="true"></span>
                </button>


              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
