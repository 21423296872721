<app-mainscreen>
    <div class="view_box_container rounded-bottom1-0">
        <div class="">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="view_div">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <ul>
                                        <li>
                                            <img src="../../../../assets/images/user.png" />
                                        </li>
                                        <li>
                                            <p><b>{{userFormControl['firstName'].value}} {{userFormControl['lastName'].value}}</b></p>
                                            <p>User ID : {{id}} </p>
                                        </li>
                                        <li><span>Active</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <label><img src="../../../../assets/images/document.png" /> &nbsp; Contract Status : </label> <span class="status">{{userFormControl['userStatus'].value == '1' ? 'Active' : 'Inactive'}}</span> <br>
                                    <label><img src="../../../../assets/images/watch_later.png" /> &nbsp;Last Login : </label> <span>1hr Ago</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="view_buttons">
                        <!-- <button class="edit_button"><img src="../../../../assets/images/message.png" />&nbsp; Lorem </button> -->
                        <app-custom-button className="mt-3" text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="backBtClick()">Edit</app-custom-button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab_div bottom-shadow">
        <ul class="align-items-center p-3 list-item-container-view ">
            <li class="list-item" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                User Details
            </li> 
            <!-- <li class="list-item" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                Documents
            </li>  -->
            <li class="list-item" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                Other Details
            </li>         
          </ul>
    </div>
    <div class="tab_content">
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>User Information</h4></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> cake </span> &nbsp; Date of Birth</p><span class="label_13_grey2">{{userFormControl['dob'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> transgender </span> &nbsp; Gender</p><span class="label_13_grey2">{{userFormControl['gender'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> call </span> &nbsp; Contact Number</p><span class="label_13_grey2">{{userFormControl['phoneNumber'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> mail </span> &nbsp; Email ID</p><span class="label_13_grey2">{{userFormControl['userEmail'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Perission Details</h4></li>
                                </ul>
                            </div>
                            <div  class="col-12 mt-3">
                                <div class="px-4">
                                    <select id="role" name="role" placeholder=" " class="form-control input_ w-25 tp" [disabled]="true">
                                        <option [value]="userFormControl['userRole'].value">{{userFormControl['userRole'].value}}</option>
                                    </select>
                                    <label for="role" class="label_">Role</label>
                                </div>  
                            </div> 
                            <div class="col-6">
                                <app-tree-table [selectedIds]="selectedIds" [nodes]="nodes" [disableAll]="true"></app-tree-table>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>User Information</h4></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> cake </span> &nbsp; Date of Birth</p><span class="label_13_grey2">{{resellerFormControl['resellerDOB'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> transgender </span> &nbsp; Gender</p><span class="label_13_grey2">{{resellerFormControl['resellerGender'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> call </span> &nbsp; Contact Number</p><span class="label_13_grey2">{{resellerFormControl['resellerMobile'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> mail </span> &nbsp; Email ID</p><span class="label_13_grey2">{{resellerFormControl['resellerEmail'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <ul>
                            <li><h4>Documents</h4></li>
                            <li *ngFor="let docObj of documentsUploadForm">
                                <img class="img_preview mr-3" src="{{docObj.documentBase64}}" title="{{docObj.documentName}}">
                            </li>                        
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <!-- <ul>
                            <li><h4>User Information</h4></li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Date of Birth</span><br>05/05/1993</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Gender</span><br>transgender</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Contact Number</span><br>9705224263</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Email ID</span><br>xyz@gmail.com</li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <!-- <ul>
                            <li><h4>Other Information</h4></li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Date of Birth</span><br>05/05/1993</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Gender</span><br>transgender</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Contact Number</span><br>9705224263</li>
                            <li><span><img src="../../../../assets/images/cake.png" /> &nbsp; Email ID</span><br>xyz@gmail.com</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    </app-mainscreen>