import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DistributorDetails, DocumentDetails } from 'src/app/models/Distributor';
import { InsuranceStatusModel } from 'src/app/models/InsuranceDetailsModel';
import { AutoCompleteItem } from 'src/app/models/SelectItems';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { LookUpService } from 'src/app/services/lookup.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-insurance-provider-edit',
  templateUrl: './insurance-provider-edit.component.html',
  styleUrls: ['./insurance-provider-edit.component.css']
})
export class InsuranceProviderEditComponent {
  activeTab:number=1;
  insuranceForm: FormGroup;
  insuranceStatus: InsuranceStatusModel[] = [];
  submittedInsForm : boolean = false;
  doSwitch:boolean=false;
  constructor(private fb: FormBuilder, private _lookupService: LookUpService,private adminService: AdminService,private router:Router
    ,private activatedRoute:ActivatedRoute,private generalService: GeneralService) {
      let obj = this.router.getCurrentNavigation()?.extras?.state;
      debugger;
      if(obj)
      {
        this.getFormData(parseInt(obj['id']));
      }
      this.insuranceForm = this.fb.group({
        "insuranceId": [''],
        "insuranceName": ['', [Validators.required]],
        "contactNumber": ['', [Validators.required]],
        "whatsAppNumber": ['', [Validators.required]],
        "email": ['', [Validators.required]],
        "address": ['', [Validators.required]],
        "insuranceStatus": [''],
        "createdDate":['']
      });

  }
  ngOnInit(): void {
  }

  toggleChanged(data:any){
    data.data.comment = data.comment;
    data.data.status = data.status;
    this.InsuranceProductStatusUpdate(data.data);
  }

  get insuranceFormControl() {
    return this.insuranceForm.controls;
  }

  async InsuranceProductStatusUpdate(data:any)
  {
    debugger;
      const res = await this.adminService.InsuranceProductStatusUpdate(data);    
      if (res != null && res.result && res.result.code == 1) {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: res.result.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }    
      else
      {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: res.result.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } 
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
  }

  alphaNumericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }

  async getFormData(id:number)
  {
    const res = await this.adminService.GetInsuranceDetails(id); 
    debugger; 
      if (res != null && res.result) {
        this.insuranceForm.patchValue(res.result.insuranceDetailsModel);
        this.insuranceStatus = res.result.insuranceStatusList;
      }    
      else
      {
        // swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Something went wrong',
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
      } 
  }

  getSubTitleContent(status:boolean,productName:string){
    return "Are you sure you want to "+ (status == true ? "stop" : "start") +" showing quotes of "+productName.toLocaleLowerCase()+" to the customer?";
  }
}
