import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DocumentDetails } from 'src/app/models/Distributor';
import { Item } from 'src/app/models/item';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.css']
})
export class CustomerViewComponent {
  activeTab:number=1;
  customerForm: FormGroup;
  otherDetailsForm: FormGroup;
  id:number=0;
  graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];
  canView:boolean = false;
  canEdit:boolean = false;
  canDelete:boolean = false;
  canCreate:boolean = false;
  viewUrl:string="";
  editUrl:string="";
  createUrl:string="";
  deleteUrl:string="";
  constructor(private fb: FormBuilder, private adminService: AdminService,private router:Router){
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {
      this.id = parseInt(obj['id']);
      this.getFormData(this.id);
    }
    this.customerForm = this.fb.group({
      "uniqueUserId":  [''],
      "firstName":  [''],
      "lastName": [''],
      "phoneNumber":  [''],
      "countryCode":  [''],
      "userEmail":  [''],
      "userId": ['0'],
      "isLocked":  [''],
      "isActive":  [''],
      "dob": [''],
      "gender": [''],
      "userStatus": [''],
      "lastLogin":['']
    });

    this.otherDetailsForm = this.fb.group({
      "pincode": [''],
      "streetAddress": [''],
    });
  }
  get customerFormControl() {
    return this.customerForm.controls;
  }
  get otherDetailsFormControl() {
    return this.otherDetailsForm.controls;
  }
  ngOnInit(): void {
    
  }

 async  getFormData(id:number)
  {
    const res = await this.adminService.GetCustomerDetailsById(id);  
    if (res != null && res.result) {

      this.customerForm.patchValue(res.result);
      this.otherDetailsForm.patchValue(res.result);
      console.log(res.result);
    }    
    else
    {
      // swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: 'Something went wrong',
      //   showConfirmButton: false,
      //   timer: 3000,
      // });
    } 
    const res1 = await this.adminService.PolicyDetailsByUserId(this.customerFormControl['userId'].value??0);
    if (res1 != null && res1.result) {
      this.graphdata = res1.result.data;
      let index=3;
      let sort:string='asc';
      console.log(this.graphdata.sort( (a, b) => {
        if(sort == 'desc')
        {
          if (a[index].toString().toLowerCase() > b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() > a[index].toString().toLowerCase()) {
              return 1;
          }          
        }
        else
        {
          if (a[index].toString().toLowerCase() < b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() < a[index].toString().toLowerCase()) {
              return 1;
          }  
        }
        return index;
      }));
      this.graphHeader = res1.result.header;
      this.visibleColumnData =  res1.result.header.map((m:any,index:number)=>{return {id:index.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
      this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id.toString()});
    }
  }
  changeTab(tab:number)
  {
    this.activeTab = tab;
  }

  onActionClick(data:any)
  {
    if(data)
    {
      if(data.action=="View")
      {
        this.router.navigateByUrl('/policy-details',{ state: { id:data.id, userId:data.id2 }});
      }
      // else if(data.action=="Edit")
      // {
      //   this.router.navigateByUrl(this.editUrl,{ state: { id:data.id }});
      // } 
    }
  }
}
