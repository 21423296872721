import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-menu-button-single',
  templateUrl: './menu-button-single.component.html',
  styleUrls: ['./menu-button-single.component.css'],
})
export class MenuButtonSingleComponent implements OnInit {
  constructor(private router:Router,private generalService :GeneralService) {}

  ngOnInit(): void {}
  @Input() title: string = '';
  @Input() icon_name: string = '';
  @Input() color: string = '';

  logoutClick(){
    sessionStorage.clear();
    localStorage.clear();
    this.generalService.removeAllSession();
    this.router.navigate(['/']);
  }
}
