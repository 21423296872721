import { APP_INITIALIZER, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from './routes/user/login/login.component';
import AuthInterceptor from './authinterceptor/authinterceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavasidebarComponent } from './navasidebar/navasidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginService } from './services/login.service';
import { AuthGuard } from './authinterceptor/auth_guard';
import { AppConfig } from './shared/AppConfig';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MenuService } from './services/menu.service';
import { UserService } from './services/user.service';
import { DistributorComponent } from './distributor/distributor.component';
import { ResellerComponent } from './reseller-info/reseller-info.component';
import { AgentComponent } from './agent/agent.component';
import { SettingsComponent } from './settings/settings.component';
import { DistributorService } from './services/distributor.service';
import { DigitsOnly } from './shared/digitsOnly';
import { ComponentsModule } from './components/components.module';
import { DritributorNewComponent } from './dritributor-new/dritributor-new.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { CarouselComponent } from './routes/user/carousel/carousel.component';
import { CreateDistributorComponent } from './create-distributor/create-distributor.component';
import { ForgotpasswordComponent } from './routes/user/forgotpassword/forgotpassword.component';
import { DistributorListComponent } from './routes/distributor/distributor-list/distributor-list.component';
import { DistributorCreateComponent } from './routes/distributor/distributor-create/distributor-create.component';
import { DistributorEditComponent } from './routes/distributor/distributor-edit/distributor-edit.component';
import { DistributorViewComponent } from './routes/distributor/distributor-view/distributor-view.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ResellerListComponent } from './routes/reseller/reseller-list/reseller-list.component';
import { ResellerCreateComponent } from './routes/reseller/reseller-create/reseller-create.component';
import { ResellerEditComponent } from './routes/reseller/reseller-edit/reseller-edit.component';
import { ResellerViewComponent } from './routes/reseller/reseller-view/reseller-view.component';
import { AgentListComponent } from './routes/agent/agent-list/agent-list.component';
import { AgentCreateComponent } from './routes/agent/agent-create/agent-create.component';
import { AgentEditComponent } from './routes/agent/agent-edit/agent-edit.component';
import { AgentViewComponent } from './routes/agent/agent-view/agent-view.component';
import { LogsComponent } from './routes/logs/logs/logs.component';
import { CustomerViewComponent } from './routes/customer/customer-view/customer-view.component';
import { CustomerListComponent } from './routes/customer/customer-list/customer-list.component';
import { DashboardComponent } from './routes/dashboard/dashboard/dashboard.component';
import { NgChartsModule } from 'ng2-charts';
import { MenuListComponent } from './routes/menu/menu-list/menu-list.component';
import { MenuCreateComponent } from './routes/menu/menu-create/menu-create.component';
import { MenuEditComponent } from './routes/menu/menu-edit/menu-edit.component';
import { UserMenuListComponent } from './routes/menu/user-menu-list/user-menu-list.component';
import { UserMenuCreateComponent } from './routes/menu/user-menu-create/user-menu-create.component';
import { UserMenuEditComponent } from './routes/menu/user-menu-edit/user-menu-edit.component';
import { TreeTableComponent } from './components/tree-table/tree-table.component';
import { RolesListComponent } from './routes/roles/roles-list/roles-list.component';
import { RolesViewComponent } from './routes/roles/roles-view/roles-view.component';
import { RolesCreateComponent } from './routes/roles/roles-create/roles-create.component';
import { RolesEditComponent } from './routes/roles/roles-edit/roles-edit.component';
import { UsersListComponent } from './routes/users/users-list/users-list.component';
import { UsersViewComponent } from './routes/users/users-view/users-view.component';
import { UsersEditComponent } from './routes/users/users-edit/users-edit.component';
import { UsersCreateComponent } from './routes/users/users-create/users-create.component';
import { LeadsListComponent } from './leads/leads-list/leads-list.component';
import { ResellerDashboardComponent } from './routes/dashboard/reseller-dashboard/reseller-dashboard.component';
import { AdminDashboardComponent } from './routes/dashboard/admin-dashboard/admin-dashboard.component';
import { SalesListComponent } from './routes/sales/sales-list/sales-list.component';
import { PolicyDetailsComponent } from './routes/policies/policy-details/policy-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { CompanyAdminDashboardComponent } from './routes/dashboard/company-admin-dashboard/company-admin-dashboard.component';
import { AgentCommissionsComponent } from './routes/commission/agent-commissions/agent-commissions.component';
import { AgentCommissionCreateComponent } from './routes/commission/agent-commission-create/agent-commission-create.component';
import { AgentCommissionEditComponent } from './routes/commission/agent-commission-edit/agent-commission-edit.component';
import { MultiDropdownComponent } from './components/multi-dropdown/multi-dropdown.component';
import { InsuranceProvidersComponent } from './routes/insurance-providers/insurance-providers.component';
import { InsuranceProviderEditComponent } from './routes/insurance-provider-edit/insurance-provider-edit.component';
import { InsuranceProviderViewComponent } from './routes/insurance-provider-view/insurance-provider-view.component';
import { DistributorProfileComponent } from './routes/distributor/distributor-profile/distributor-profile.component';
import { ResellerProfileComponent } from './routes/reseller/reseller-profile/reseller-profile.component';
import { LoginLogsComponent } from './components/login-logs/login-logs.component';
import { PolicyLogListingComponent } from './routes/policyLogs/policy-log-listing/policy-log-listing.component';
import { PolicyLogViewComponent } from './routes/policyLogs/policy-log-view/policy-log-view.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SmsLogsComponent } from './routes/logs/sms-logs/sms-logs.component';
export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
     NavasidebarComponent,
    NavbarComponent,
    DistributorComponent,
    ResellerComponent,
    AgentComponent,
    SettingsComponent,
    DigitsOnly,
    DritributorNewComponent,
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    CarouselComponent,
    CreateDistributorComponent,
    ForgotpasswordComponent,
    DistributorListComponent,
    DistributorCreateComponent,
    DistributorEditComponent,
    DistributorViewComponent,
    AutocompleteComponent,
    FileUploadComponent,
    ResellerListComponent,
    ResellerCreateComponent,
    ResellerEditComponent,
    ResellerViewComponent,
    AgentListComponent,
    AgentCreateComponent,
    AgentEditComponent,
    AgentViewComponent,
    LogsComponent,
    CustomerViewComponent,
    CustomerListComponent,
    DashboardComponent,    
    MenuListComponent,
    MenuCreateComponent,
    MenuEditComponent,
    UserMenuListComponent,
    UserMenuCreateComponent,
    UserMenuEditComponent,
    TreeTableComponent,
    RolesListComponent,
    RolesViewComponent,
    RolesCreateComponent,
    RolesEditComponent,
    UsersListComponent,
    UsersViewComponent,
    UsersEditComponent,
    UsersCreateComponent,
    LeadsListComponent,
    ResellerDashboardComponent,
    AdminDashboardComponent,
    SalesListComponent,
    PolicyDetailsComponent,
    CompanyAdminDashboardComponent,
    AgentCommissionsComponent,
    AgentCommissionCreateComponent,
    AgentCommissionEditComponent,
    InsuranceProvidersComponent,
    InsuranceProviderEditComponent,
    InsuranceProviderViewComponent,
    DistributorProfileComponent,
    ResellerProfileComponent,
    LoginLogsComponent,
    PolicyLogListingComponent,
    PolicyLogViewComponent,
    SmsLogsComponent
    // MultiDropdownComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxIntlTelInputModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    BrowserAnimationsModule,
    NgChartsModule,
    MatFormFieldModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    ModalModule.forRoot(),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, AuthGuard, LoginService,
    AppConfig, MenuService, UserService, DistributorService,
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [AppConfig], multi: true
  }
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
