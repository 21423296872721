<app-mainscreen>
    <div class="view_box_container rounded-bottom1-0">
        <div class="">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="view_div">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <ul>
                                        <li>
                                            <img src="../../../../assets/images/user.png" />
                                        </li>
                                        <li>
                                            <p><b>{{userDetailsFormControl['firstName'].value}}</b></p>
                                            <p>{{userDetailsFormControl['phoneNumber'].value}}</p>
                                            <p>{{userDetailsFormControl['email'].value}} </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <label>Policy Status : </label> <span class="status">{{policyValueFormControl['policyStatusStr'].value}}</span> <br>
                                    <label>Policy Expiry Date : </label> <span>{{policyValueFormControl['endDate'].value | date}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="view_buttons">
                        <button class="edit_button"><img src="../../../../assets/images/message.png" />&nbsp; Lorem </button>
                        <app-custom-button className="mt-3" text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="backBtClick()">Edit</app-custom-button> 
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="tab_div bottom-shadow">
        <ul class="align-items-center p-3 list-item-container-view ">
            <li class="list-item" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                Owner's Details
            </li> 
            <li class="list-item" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                Vehicle's Details
            </li> 
            <li class="list-item" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                Previous Policy Details
            </li>         
          </ul>
    </div>
    <div class="tab_content">
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Policy's Details</h4></li>
                                    <li><p class="label_13_grey3 label_controls">Policy Number</p><span class="label_13_grey2">{{policyValueFormControl['policyNumber'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Policy Type</p><span class="label_13_grey2">{{vehicleDetailsFormControl['typeOfCover'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Policy Cover Type</p><span class="label_13_grey2">{{vehicleDetailsFormControl['typeOfCover'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Insurance Company</p><span class="label_13_grey2">{{policyValueFormControl['insuranceCompany'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Policy Purchased On</p><span class="label_13_grey2">{{policyValueFormControl['startDate'].value | date}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Owner's Details</h4></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pr-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> call </span> &nbsp; Contact Number</p><span class="label_13_grey2">{{proposerDetailsFormControl['phoneNumber'].value}}</span></li>                                
                                    <li><p class="label_13_grey3 label_controls">PAN Number</p><span class="label_13_grey2">{{proposerDetailsFormControl['panNumber'].value}}</span></li>        
                                    <li><p class="label_13_grey3 label_controls">Street Address</p><span class="label_13_grey2">{{proposerDetailsFormControl['streetAddress'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">State</p><span class="label_13_grey2">{{proposerDetailsFormControl['state'].value}}</span></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pl-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> mail </span> &nbsp; Email</p><span class="label_13_grey2">{{proposerDetailsFormControl['email'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> cake </span> &nbsp; Date of Birth</p><span class="label_13_grey2">{{proposerDetailsFormControl['dateOfBirth'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">PIN code</p><span class="label_13_grey2">{{proposerDetailsFormControl['pincode'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">City</p><span class="label_13_grey2">{{proposerDetailsFormControl['city'].value}}</span></li>
                                </ul>
                            </div>
                        </div>                        
                    </div>

                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Nominees's Details</h4></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pr-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls">Nominee Name</p><span class="label_13_grey2">{{nomineeDetailsFormControl['nomineeName'].value}}</span></li>                                
                                    <li><p class="label_13_grey3 label_controls">Age</p><span class="label_13_grey2">{{nomineeDetailsFormControl['nomineeAge'].value}}</span></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pl-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls">Relation</p><span class="label_13_grey2">{{nomineeDetailsFormControl['relationshipWithNominee'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Gender</p><span class="label_13_grey2">{{nomineeDetailsFormControl['gender'].value}}</span></li>
                                </ul>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Policy's Details</h4></li>
                                    <li><p class="label_13_grey3 label_controls">Policy Number</p><span class="label_13_grey2">{{policyValueFormControl['policyNumber'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Policy Type</p><span class="label_13_grey2">{{vehicleDetailsFormControl['typeOfCover'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Policy Cover Type</p><span class="label_13_grey2">{{vehicleDetailsFormControl['typeOfCover'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Insurance Company</p><span class="label_13_grey2">{{policyValueFormControl['insuranceCompany'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Policy Purchased On</p><span class="label_13_grey2">{{policyValueFormControl['startDate'].value | date}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Vehicle Details</h4></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pr-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls">Vehicle</p><span class="label_13_grey2">{{vehicleDetailsFormControl['make'].value}}</span></li>                                
                                    <li><p class="label_13_grey3 label_controls">RTO</p><span class="label_13_grey2">{{vehicleDetailsFormControl['rto'].value}}</span></li>        
                                    <li><p class="label_13_grey3 label_controls">Last Year Claim</p><span class="label_13_grey2">{{vehicleDetailsFormControl['claimsMadeInPreviousPolicy'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">NCB Last Year</p><span class="label_13_grey2">{{vehicleDetailsFormControl['ncbprevious'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Engine Number</p><span class="label_13_grey2">{{vehicleDetailsFormControl['engineNumber'].value}}</span></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pl-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls">Registration Number</p><span class="label_13_grey2">{{vehicleDetailsFormControl['registrationNumber'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Model</p><span class="label_13_grey2">{{vehicleDetailsFormControl['model'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Variant</p><span class="label_13_grey2">{{vehicleDetailsFormControl['variant'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Vehicle Registration Date</p><span class="label_13_grey2">{{vehicleDetailsFormControl['registrationDate'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Chasis Number</p><span class="label_13_grey2">{{vehicleDetailsFormControl['chasisNumber'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <ul>
                            <li><h4>Policy's Details</h4></li>
                            <li><p class="label_13_grey3 label_controls">Policy Number</p><span class="label_13_grey2">{{policyValueFormControl['policyNumber'].value}}</span></li>
                            <li><p class="label_13_grey3 label_controls">Policy Type</p><span class="label_13_grey2">{{vehicleDetailsFormControl['typeOfCover'].value}}</span></li>
                            <li><p class="label_13_grey3 label_controls">Policy Cover Type</p><span class="label_13_grey2">{{vehicleDetailsFormControl['typeOfCover'].value}}</span></li>
                            <li><p class="label_13_grey3 label_controls">Insurance Company</p><span class="label_13_grey2">{{policyValueFormControl['insuranceCompany'].value}}</span></li>
                            <li><p class="label_13_grey3 label_controls">Policy Purchased On</p><span class="label_13_grey2">{{policyValueFormControl['startDate'].value | date}}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Previous Policy Details</h4></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pr-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls">Previous Policy Company</p><span class="label_13_grey2">{{vehicleDetailsFormControl['previousPolicyCompany'].value}}</span></li>                                
                                    <li><p class="label_13_grey3 label_controls">Previous policy Number</p><span class="label_13_grey2">{{vehicleDetailsFormControl['previousPolicyNumber'].value}}</span></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pl-0">
                                <ul>
                                    <li><p class="label_13_grey3 label_controls">Previous Policy Type</p><span class="label_13_grey2">{{vehicleDetailsFormControl['previousPolicyType'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls">Previous Policy Expiry Date</p><span class="label_13_grey2">{{vehicleDetailsFormControl['previousPolicyExpiryDate'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </app-mainscreen>